<template>
    <div id="ticket-form">
        <Loader :active="loading"></Loader>
        <div class="columns">
            <div class="column is-6">
                <div class="level is-mobile">
                    <div class="level-left">
                        <div class="level-item">
                            <div>
                                <h1 class="title has-text-primary">Nueva Consulta</h1>
                                <nav class="breadcrumb" aria-label="breadcrumbs">
                                    <ul>
                                        <li><a href="#">Consultas</a></li>
                                        <li class="is-active"><a href="#" aria-current="page">Nueva Consulta</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column is-6">
                <div class="form-content">
                    <form @submit.prevent="create">
                        <b-field
                            label="Departamento*"
                            message="Seleccionar el departamento donde será dirigida la consulta.">
                            <b-select 
                                @input="affiliate"
                                v-model="departament"
                                placeholder="Seleccionar"
                                expanded
                                required
                                :disabled="departaments.disabled">
                                <option
                                    v-for="item in departaments.data"
                                    :value=" item.id "
                                    :key=" item.id ">
                                    {{ item.name }}
                                </option>
                            </b-select>
                        </b-field>
                        <!--Affiliate Group
                        <div v-if="isAffiliate">
                            <b-field
                                label="Cuil*">
                                <b-input 
                                    v-model="name"
                                    placeholder="Cuil"
                                    required
                                ></b-input>
                            </b-field>
                            <b-field
                                label="Email">
                                <b-input 
                                    v-model="email"
                                    type="email"
                                    placeholder="Email"
                                    required
                                ></b-input>
                            </b-field>
                            <b-field
                                label="Mensaje">
                                <b-input 
                                    v-model="message"
                                    maxlength="600" 
                                    type="textarea"
                                    required>
                                </b-input>
                            </b-field>
                            <b-field
                                class="file"> 
                                <b-upload 
                                    v-model="files" 
                                    multiple>
                                    <a class="button is-light">
                                        <b-icon 
                                            pack="fas" 
                                            icon="paperclip"
                                            size="is-small">
                                        </b-icon>
                                        <span class="is-capitalized has-text-weight-normal">Adjuntar archivos</span>
                                    </a>
                                </b-upload>
                            </b-field>
                            <div class="tags">
                                <span v-for="(file, index) in files" :key="index" class="tag">
                                    {{file.name}}
                                    <button class="delete is-small" type="button" @click="removeFile(index)"></button>
                                </span>
                            </div>
                        </div>
                        -->
                        
                        <div >
                            <b-field
                                label="Nombre y Apellido*">
                                <b-input 
                                    v-model="fullname"
                                    required
                                ></b-input>
                            </b-field>
                            <b-field
                                label="Email*">
                                <b-input 
                                    v-model="email"
                                    type="email"
                                   
                                ></b-input>
                            </b-field>
                            <b-field grouped label="Teléfono"> 
                                <b-input 
                                    style="width:60px;"
                                    v-model="characteristic_phone"
                                    type="text"
                                    pattern="[0-9]*"
                                    maxlength="4">
                                </b-input>

                                <b-input
                                    expanded
                                    v-model="phone"
                                    type="text"
                                    pattern="[0-9]*">
                                </b-input>    
                            </b-field> 
                              <b-field grouped label="Celular"> 
                                <b-input 
                                    style="width:60px;"
                                    v-model="characteristic_celphone"
                                    type="text"
                                    pattern="[0-9]*"
                                    maxlength="4">
                                </b-input>

                                <b-input
                                    expanded
                                    v-model="celphone"
                                    type="text"
                                    pattern="[0-9]*"
                                    required
                                    >
                                    
                                </b-input>    
                            </b-field>                        
                            <b-field
                                label="Provincia*">
                                <b-select 
                                    @input="getCities()"
                                    v-model="province"
                                    placeholder="Seleccionar"
                                    expanded
                                    required>
                                    <option
                                        v-for="(province, index) in provinces.data"
                                        :value="province"
                                        :key="index">
                                        {{ province }}
                                    </option>
                                </b-select>
                            </b-field>
                            <b-field
                                label="Ciudad*">
                                <b-select 
                                    v-model="city"
                                    placeholder="Seleccionar"
                                    expanded
                                    required
                                    :disabled="cities.disabled"
                                    :loading="cities.loading">
                                    <option
                                        v-for="(city, index) in cities.data"
                                        :value="city"
                                        :key="index">
                                        {{ city }}
                                    </option>
                                </b-select>
                            </b-field>
                            <b-field
                                label="Dirección">
                                <b-input 
                                    v-model="address"
                                ></b-input>
                            </b-field>
                            <b-field
                                label="CUIL/DNI">
                                <b-input 
                                    v-model="cuil"
                                    maxlength="11"
                                ></b-input>
                            </b-field>
                            <b-field
                                label="Procedencia"
                                message="Seleccionar la procedencia de la consulta.">
                                <b-select 
                                    v-model="origin"
                                    placeholder="Seleccionar"
                                    expanded
                                    required
                                    :disabled="origins.disabled">
                                    <option
                                        v-for="(item, index) in origins.data"
                                        :value=" item"
                                        :key="index">
                                        {{ item }}
                                    </option>
                                </b-select>
                            </b-field>
                            <b-field
                                label="Mensaje*">
                                <b-input
                                    v-model="message" 
                                    maxlength="600" 
                                    type="textarea"
                                    required>
                                </b-input>
                            </b-field>
                            <b-field
                                class="file"> 
                                <b-upload 
                                    v-model="files" 
                                    multiple>
                                    <a class="button is-light">
                                        <b-icon 
                                            pack="fas" 
                                            icon="paperclip"
                                            size="is-small">
                                        </b-icon>
                                        <span class="is-capitalized has-text-weight-normal">Adjuntar archivos</span>
                                    </a>
                                </b-upload>
                            </b-field>
                            <div class="tags">
                                <span v-for="(file, index) in files" :key="index" class="tag">
                                    {{file.name}}
                                    <button class="delete is-small" type="button" @click="removeFile(index)"></button>
                                </span>
                            </div>
                        </div>
                        <hr>
                        <b-button
                            native-type="submit" 
                            type="is-primary" 
                            expanded>
                            Guardar
                        </b-button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from './Loader.vue';
    import Places from './Places.vue';
    import {alertSuccess, alertFailed, provinces, cities} from "@/helpers";
    export default {
        name:'TicketForm',
        components:{
            Loader,
            Places
        },
        data(){
            return{
                departament:'',
                departaments: {
                    disabled : true,
                    data: []
                },
                origins: {
                    disabled: true,
                    data: []
                },
                provinces:{
                    disabled: false,
                    data: []
                },
                cities:{
                    disabled: true,
                    laoding: false,
                    data: []
                },
                fullname: '',
                email: '',
                characteristic_phone: '',
                characteristic_celphone: '',
                phone: '',
                celphone: '',
                province: '',
                city: '',
                address: '',
                origin: '',
                message: '',
                cuil: '',
                files: [],
                is_affiliate: false,
                loading: false
            }
        },
        methods:{
            async fetchDepartaments(){
                try {
                    this.departaments.data = [];
                    const query = await this.$repositories.departaments.list();
                    query.data.departaments.forEach(item => {
                        const items = {
                            id : item.id,
                            name : item.name
                        }
                        this.departaments.data.push(items);
                        this.departaments.disabled = false;
                    });
                } catch (error) {
                    console.log(error);
                }
            },
            async fetchOrigins(){
                try {
                    this.origins.data = [];
                    const query = await this.$repositories.tickets.origins();
                    this.origins.data = query.data.origins;
                    this.origins.disabled = false;
                } catch (error) {
                    console.log(error);
                }
            },
            async create(){
                try {
                    this.loading = true;
                    
                    const contact = await this.createContact();  
                    await this.createTicket(contact.data.contact.id);
                    
                    this.loading = false;
                    alertSuccess('Registro existoso!');
                    this.$router.push('/consultas');
                } catch (error) {
                    this.loading = false;
                    alertFailed('Ha ocurrido un error!');
                }
            },
            async createContact(){
                try {
                    const data = new FormData();
                    data.append('type', (!this.is_affiliate) ? 'afiliado' : 'potencial afiliado');
                    data.append('origin', 'crm-consultas');
                    data.append('fullname', this.fullname);
                    data.append('email', this.email);
                    data.append('phone', this.characteristic_phone+this.phone);
                    data.append('celphone', this.characteristic_celphone+this.celphone);
                    data.append('province', this.province);
                    data.append('city', this.city);
                    data.append('address', this.address);
                    data.append('cuil', this.cuil);
                    return await this.$repositories.contacts.create(data);

                } catch (error) {
                    console.log(error);
                }
            },
            async createTicket(contact){
                try {
                    const data = new FormData();
                    data.append('origin', this.origin) 
                    data.append('contact', contact);
                    data.append('departament', this.departament);
                    data.append('type', 'received')
                    data.append('content', this.message);
                    data.append('user_id', this.$store.getters["getSession"]["id"]);
                    
                    this.files.forEach(file => {
                        data.append('files[]', file);       
                    });
                    return await this.$repositories.tickets.create(data);

                } catch (error) {
                    console.log(error)
                }
            },
            getProvinces(){
                this.provinces.data = provinces();
            },
            getCities(){
                this.cities.data = cities(this.province);
                this.cities.disabled = false;
            },
            affiliate(){
                (this.departament == 3)
                ? this.is_affiliate = true
                : this.is_affiliate = false    
            },
            removeFile(index){
                this.files.splice(index, 1);
            }
        },
        computed:{
            isAffiliate(){
                return this.is_affiliate;
            }
        },
        created(){
            this.fetchDepartaments();
            this.fetchOrigins();
            this.getProvinces();
        }
    }
</script>

<style lang="scss" scoped>
    #ticket-form{
        height: 100vh;
        padding-top: 1.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        overflow-y: auto;
        form{
            input, select, textarea{
                border: none;
            }
            input, select, textarea{
                &.is-danger{
                    border: 1px solid $danger;
                }
            }
        }
        hr{
            background-color: #e8e8e8!important;
        }
    }
    .upload {
        position: relative !important; 
        opacity: 1;
    }
    select[disabled]{
      border: 1px solid #dbdbdb!important;
    }
</style>