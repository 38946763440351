<template>
  <div>
    <div id="agent-form">
    <Loader :active="loading"></Loader>
    <div class="columns">
      <div class="column is-6">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <div>
                <h1 class="title has-text-primary">
                  {{ $route.params.id ? "Editar Agente" : "Nuevo Agente" }}
                </h1>
                <nav class="breadcrumb" aria-label="breadcrumbs">
                  <ul>
                    <li>
                      <router-link :to="'/agentes'">Agentes</router-link>
                    </li>
                    <li class="is-active">
                      <a href="#" aria-current="page">{{
                        $route.params.id ? "Editar Agente" : "Nuevo Agente"
                      }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <div class="form-content">
          <form @submit.prevent="submit">
            <div class="column is-6">
              <Avatar
                :image="avatar ? avatar : $avatarDefault"
                @value="getAvatar"
              />
              <b-field label="Nombre*" >
                <b-input v-model="name" placeholder="Nombre" required :disabled="!$store.getters['getSession']['is_admin']"></b-input>
              </b-field>
              <b-field label="Apellido*">
                <b-input
                  v-model="lastname"
                  placeholder="Apellido"
                  required :disabled="!$store.getters['getSession']['is_admin']"
                ></b-input>
              </b-field>
              <b-field label="Fecha Nacimiento">
                <b-input
                  type="date"
                  v-model="birth_date"
                  placeholder="Fecha Nacimiento"
                  :disabled="!$store.getters['getSession']['is_admin']"
                ></b-input>
              </b-field>
              <b-field label="Fecha Ingreso">
                <b-input
                  type="date"
                  v-model="entry_date"
                  placeholder="Fecha Ingreso"
                  :disabled="!$store.getters['getSession']['is_admin']"
                ></b-input>
              </b-field>
              <b-field label="Departamento*">
                <b-select
                  v-model="departament"
                  placeholder="Seleccionar"
                  expanded
                  required
                  :disabled="!$store.getters['getSession']['is_admin']"
                >
                  <option
                    v-for="item in departaments.data"
                    :value="item.id"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Rol*">
                <b-select
                  v-model="role"
                  placeholder="Seleccionar"
                  expanded
                  required
                  :disabled="!$store.getters['getSession']['is_admin']"
                >
                  <option
                    v-for="item in roles.data"
                    :value="item.id"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </b-select>
              </b-field>
              <hr />
              <b-field label="Cargo">
                <b-input
                  v-model="responsability"
                  type="text"
                  placeholder="Cargo"
                  :disabled="!$store.getters['getSession']['is_admin']"
                ></b-input>
              </b-field>

              <b-field label="Cargo">
                <b-select
                  v-model="position_id"
                  placeholder="Seleccionar"
                  expanded
                  :disabled="!$store.getters['getSession']['is_admin']"
                >
                  <option
                    v-for="item in positions.data"
                    :value="item.id"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </b-select>
              </b-field>




              <b-field label="Delegación">
                <b-select
                  v-model="delegation_id"
                  placeholder="Seleccionar"
                  expanded
                  :disabled="!$store.getters['getSession']['is_admin']"
                >
                  <option
                    v-for="item in delegations.data"
                    :value="item.id"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field grouped label="Teléfono">
                <b-field expanded>
                  <b-input type="tel" v-model="phone" placeholder="Teléfono" :disabled="!$store.getters['getSession']['is_admin']"> 
                  </b-input>
                </b-field>
                <b-field>
                  <b-input
                    type="tel"
                    v-model="internal_phone"
                    placeholder="Interno"
                    :disabled="!$store.getters['getSession']['is_admin']"
                  >
                  </b-input>
                </b-field>
              </b-field>
              <b-field label="Celular">
                <b-input type="tel" v-model="celphone" placeholder="Celular" :disabled="!$store.getters['getSession']['is_admin']">
                </b-input>
              </b-field>
              <div class="media is-align-items-center">
                <div class="media-left">
                  <b-field v-if="showQR" label="Código QR">
                    <!-- <QR ref="canvas" :size="120" :value="qr.value" /> -->
                    <img width="120px" :src="`https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data=https://www.osfatun.com.ar/personal/${id}`">
                  </b-field>
                </div>
                <!-- <div class="media-content">
                  <b-tooltip
                    label="Descargar"
                    type="is-dark"
                    position="is-top"
                    :square="true"
                  >
                    <b-button
                      type="is-primary"
                      icon-pack="fas"
                      icon-left="download"
                      @click="downloadQR"
                    >
                    </b-button>
                  </b-tooltip>
                </div> -->
              </div>
              <hr />
              <b-field label="Email Laboral*">
                <b-input
                  v-model="email"
                  type="email"
                  placeholder="Email Laboral"
                  required
                  :disabled="!$store.getters['getSession']['is_admin']"
                ></b-input>
              </b-field>
              <b-field label="Email Personal">
                <b-input
                  v-model="personal_email"
                  type="email"
                  placeholder="Email Personal"
                  :disabled="!$store.getters['getSession']['is_admin']"
                ></b-input>
              </b-field>
              <b-field label="Contraseña*">
                <b-input
                  type="password"
                  v-model="password"
                  minlength="8"
                  icon-pack="fas"
                  placeholder="Contraseña"
                  password-reveal
                  :required="$route.params.id ? false : true"
                 autocomplete="new-password"
               v-no-autocomplete
         
                >
                </b-input>
              </b-field>

              
              <b-field>
                <b-switch v-model="admin" :disabled="!$store.getters['getSession']['is_admin']">
                  Cuenta administrador
                  <b-tooltip type="is-dark" position="is-right" multilined>
                    <b-icon pack="fas" icon="question-circle" size="is-small">
                    </b-icon>
                    <template v-slot:content>
                      <b>Cuenta administrador:</b><br />
                      Tendra privilegios por encima de las cuentas invitado.
                    </template>
                  </b-tooltip>
                </b-switch>
              </b-field>
              <b-field>
                <b-switch v-model="active" :disabled="!$store.getters['getSession']['is_admin']"> Activar cuenta </b-switch>
              </b-field>

            </div>
            <div class="column is-12">
              <SignatureProfile :id="id" :celphone="celphone" :delegation_address="delegation_address" :delegation_province="delegation_province" :delegation_city="delegation_city" :delegation_name="delegation_name"   :value="qr.value" :internal_phone="internal_phone" :image="avatar" :name="name" :lastname="lastname" :responsability="responsability" :email="email" :phone="phone" />
            </div>
            <div class="column is-6">
              <hr />
              <div class="buttons">
                <b-button native-type="submit" type="is-primary">
                  Guardar
                </b-button>
                <div v-if="this.$hasPermission('agentes.editar')">
                <b-button 
                  v-if="$route.params.id"
                  @click="destroy"
                  type="is-danger"
                >
                  Eliminar
                </b-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div>
    
  </div>
  </div>
</template>

<script>
import Loader from "./Loader.vue";
import Avatar from "./Avatar.vue";
import SignatureProfile from "./SignatureProfile.vue";
import { alertSuccess, alertInfo, alertFailed } from "@/helpers";
import QR from "./qr.vue";
export default {
   directives: {
    'no-autocomplete': {
      // Definir el hook `bind` para configurar el atributo autocomplete
      bind(el, binding) {
        el.setAttribute('autocomplete', 'off');
      },
    },
  },
  name: "AgentForm",
  components: {
    Loader,
    Avatar,
    QR,
    SignatureProfile
  },
  data() {
    return {
      departaments: {
        disabled: true,
        data: [],
      },
      positions: {
        disabled: true,
        data: [],
      },
      roles: {
        disabled: true,
        data: [],
      },
      delegations: {
        disabled: true,
        data: [],
      },
      qr: {
        value: "",
      },
      id: "",
      name: "",
      lastname: "",
      birth_date: "",
      entry_date: "",
      departament: "",
      delegation_name: "",
      delegation_city: "",
      delegation_province: "",
      delegation_address: "",
      role: "",
      email: "",
      personal_email: "",
      password: "",
      responsability: "",
      delegation_id: "",
      phone: "",
      internal_phone: "",
      celphone: "",
      position_id: null,
      avatar: null,
      active: false,
      admin: false,
      loading: false,
      file: "",
      submit: this.$route.params.id ? this.update : this.create,
    };
  },
  methods: {
    async fetchDepartaments() {
      try {
        this.departaments.data = [];
        const query = await this.$repositories.departaments.list();
        query.data.departaments.forEach((item) => {
          const items = {
            id: item.id,
            name: item.name,
          };
          this.departaments.data.push(items);
        });
      } catch (error) {
        console.log(error);
      }
    },

      async fetchPositions() {
      try {
        this.positions.data = [];
        const query = await this.$repositories.agents.positions();
        query.data.positions.forEach((item) => {
          const items = {
            id: item.id,
            name: item.name,
          };
          this.positions.data.push(items);
          
        });
      } catch (error) {
        console.log(error);
      }
    },
    
    async fetchRoles() {
      try {
        this.roles.data = [];
        const query = await this.$repositories.agents.roles();
        query.data.roles.forEach((item) => {
          const items = {
            id: item.id,
            name: item.name,
          };
          this.roles.data.push(items);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDelegations() {
      try {
        this.delegations.data = [];
        const query = await this.$repositories.delegations.list();
        query.data.delegations.forEach((item) => {
          const items = {
            id: item.id,
            name: item.name,
          };
          this.delegations.data.push(items);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAgent() {
      try {
        this.loading = true;
        const id = this.$route.params.id;
        const query = await this.$repositories.agents.show(id);
        const item = query.data.user;
        this.id = item.id;
        this.name = item.name;
        this.lastname = item.lastname;
        this.birth_date = item.birth_date;
        this.entry_date = item.entry_date;
        this.departament = item.departament_id;
        this.role = item.role_id;
        this.email = item.email;
        this.personal_email = item.personal_email;
        this.currentpassword = item.password;
        this.avatar = item.avatar;
        this.active = Boolean(item.is_active);
        this.admin = Boolean(item.is_admin);
        this.responsability = item.responsability;
        this.delegation_id = item.delegation_id;
        this.phone = item.phone;
        this.delegation_name= item.delegation_name,
        this.delegation_city= item.delegation_city,
        this.delegation_province= item.delegation_province,
        this.delegation_address= item.delegation_address,
        this.internal_phone = item.internal_phone;
        this.celphone = item.celphone;
        this.position_id = item.position_id;
        this.qr.value =
          "https://www.osfatun.com.ar/personal/" + this.$route.params.id;
        this.loading = false;
        //this.$store.commit('setSession');
      } catch (error) {
        console.log(error);
        alertFailed("Ha ocurrido un error!");
      }
    },
    async create() {
      try {
        console.log("action", "create");
        this.loading = true;
        const data = new FormData();
        data.append("name", this.name);
        data.append("lastname", this.lastname);
        data.append("birth_date", this.birth_date);
        data.append("entry_date", this.entry_date);
        data.append("email", this.email);
        data.append("personal_email", this.personal_email);
        data.append("password", this.password);
        data.append("file", this.file);
        data.append("active", this.active);
        data.append("admin", this.admin);
        data.append("departament", this.departament);
        data.append("role", this.role);
        data.append("delegation_id", this.delegation_id);
        data.append("responsability", this.responsability);
        data.append("phone", this.phone);
        data.append("internal_phone", this.internal_phone);
        data.append("celphone", this.celphone);
        data.append("position_id", this.position_id);
        await this.$repositories.users.create(data);

        this.$router.push("/agentes");
        alertSuccess("Registro exitoso!");
        this.loading = false;
      } catch (error) {
        this.loading = false;
        alertFailed("Ha ocurrido un error!");
      }
    },
    
    async update() {
      try {
        this.loading = true;
        const id = this.id;
        const data = new FormData();
        data.append("_method", "put");
        data.append("name", this.name);
        data.append("lastname", this.lastname);
        data.append("birth_date", this.birth_date);
        data.append("entry_date", this.entry_date);
        data.append("departament", this.departament);
        data.append("role", this.role);
        data.append("email", this.email);
        data.append("personal_email", this.personal_email);
        data.append("password", this.password);
        data.append("currentpassword", this.currentpassword);
        data.append("file", this.file);
        data.append("active", this.active);
        data.append("admin", this.admin);
        data.append("delegation_id", this.delegation_id);
        data.append("responsability", this.responsability);
        data.append("phone", this.phone);
        data.append("internal_phone", this.internal_phone);
        data.append("celphone", this.celphone);
        data.append("position_id", this.position_id);

        await this.$repositories.agents.update(id, data);
        await this.fetchAgent();
        /*
                    if(this.$store.getters.getSession.id == id){
                        this.$store.commit('setSession', query.data);
                    }
                    */
        alertSuccess("Registro existoso!");
      } catch (error) {
        this.loading = false;
        alertFailed("Ha ocurrido un error!");
      }
    },
    async destroy() {
      try {
        this.loading = true;
        const id = this.id;
        await this.$repositories.agents.delete(id);
        alertInfo("Se ha eliminado correctamente!");
        this.$router.push("/agentes");
      } catch (error) {
        this.loading = false;
        alertFailed("Ha ocurrido un error!");
      }
    },
    actionForm() {
      this.$route.params.id ? this.update : this.create;
    },
    getAvatar(value) {
      this.file = value;
    },
    // downloadQR() {
    //   const canvas = document.getElementsByTagName("canvas")[0];
    //   const image = canvas.toDataURL("image/png");
    //   let link = document.createElement("a");
    //   link.download = `${this.name}-${this.lastname}-qr.png`;
    //   link.href = image;
    //   link.click();
    // },
  },
  computed: {
    showQR() {
      return this.delegation_id;
    },
  },
  async created() {
    this.loading = true;
    await this.fetchDepartaments();
    await this.fetchRoles();
    await this.fetchDelegations();
    await this.fetchPositions()
    if (this.$route.params.id) {
      await this.fetchAgent();
    }
    this.loading = false;
  },
  watch: {
    "$route.params.id": async function () {
      if (this.$route.params.id) await this.fetchAgent();
    },
  },
};
</script>

<style lang="scss">
#agent-form {
  height: 100vh;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-y: auto;
  form {
    input,
    select,
    textarea {
      border: none;
    }
    input,
    select,
    textarea {
      &.is-danger {
        border: 1px solid $danger;
      }
    }
    select[disabled] {
      border: 1px solid #e5e5e5;
    }
  }
  hr {
    background-color: #e8e8e8 !important;
  }
}
.upload {
  position: absolute;
  top: 0;
  opacity: 0;
  transition: 0.2s;
}
</style>