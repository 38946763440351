<template>
  <section class="columns">
    <div class="column is-6">
      <div class="my_container" ref="copyPlaneVisual">
        <table
          border="0"
          style="
            font-family: 'Arial', sans-serif !important;
            font-size: 10px;
            font-weight: 300;
            background: url('https://zaro.com.ar/firmas/osfatun/fondo.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom right;
            max-width: 480px;
            min-width: 400px;
          "
        >
          <tbody>
            <tr>
              <td>
                <table border="0" style="margin-bottom:0;">
                  <tbody>
                    <tr>
                      <td style="text-align: center; padding-top: 21px">
                        
                          <img
                            width="80"
                            style="
                              height: 80px;
                              max-height: 80px;
                              width: 80px;
                              max-width: 80px;
                              border-radius: 100%;
                              object-fit: cover;
                              o-object-fit: cover;
                            "
                            :src="image"
                            alt="OSFATUN"
                          /> <br />
                        <span style="font-size: 12px; font-weight: bold"
                          >{{ name }}
                          <span style="color: #00758f">{{
                            lastname
                          }}</span></span
                        >
                      </td>
                      <td>
                        <table border="0" style="margin-bottom: -2px">
                          <tbody>
                            <tr>
                              <td>
                                <i style="color: #5d5d5d; font-size: 10px">{{
                                  responsability
                                }}</i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table border="0" cellspacing="0" cellpadding="4">
                          <tbody>
                            <tr>
                              <td
                                style="
                                  background: #00758f;
                                  text-align: center;
                                  width: 25px;
                                "
                              >
                                <img
                                  src="https://zaro.com.ar/firmas/osfatun/email.png"
                                  width="12"
                                />
                              </td>
                              <td>
                                <a
                                  style="
                                    color: #5d5d5d;
                                    font-weight: 700;
                                    text-decoration: none;
                                  "
                                  :href="`mailto:${email}`"
                                >
                                  {{ email }}
                                </a>
                              </td>
                            </tr>
                            <tr v-if="celphone !== ''">
                              <td
                                style="
                                  background: #00758f;
                                  text-align: center;
                                  width: 25px;
                                "
                              >
                                <img
                                  src="https://zaro.com.ar/firmas/osfatun/whatsapp.png"
                                  width="12"
                                />
                              </td>
                              <td>
                                <a
                                  :href="`http://wa.me/${celphone}`"
                                  style="
                                    color: #5d5d5d;
                                    font-weight: 700;
                                    text-decoration: none;
                                  "
                                >
                                  {{ celphone }}
                                </a>
                              </td>
                            </tr>
                            <tr v-if="phone !== ''">
                              <td
                                style="
                                  background: #00758f;
                                  text-align: center;
                                  width: 25px;
                                "
                              >
                                <img
                                  src="https://zaro.com.ar/firmas/osfatun/celular.png"
                                  width="10"
                                />
                              </td>
                              <td>
                                <a
                                  :href="`tel:${phone}`"
                                  style="
                                    color: #5d5d5d;
                                    font-weight: 700;
                                    text-decoration: none;
                                  "
                                  >{{ phone }} Int. {{ internal_phone }}</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td
                                style="
                                  background: #00758f;
                                  text-align: center;
                                  width: 25px;
                                "
                              >
                                <img
                                  src="https://zaro.com.ar/firmas/osfatun/domicilio.png"
                                  width="10"
                                />
                              </td>
                              <td>
                                <span
                                  style="
                                    color: #5d5d5d;
                                    font-weight: 700;
                                    text-decoration: none;
                                  "
                                >
                                  {{ delegation_address }}
                                </span>
                                <br />
                                <span style="font-size: 7px">
                                  {{ delegation_city }},
                                  {{ delegation_province }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style="padding-top: 21px">
                        <img
                          width="90px"
                          style="max-width: 90px"
                          :src="`https://api.qrserver.com/v1/create-qr-code/?size=90x90&data=https://www.osfatun.com.ar/personal/${id}`"
                        />
                        <!-- <QR :size="90" ref="canvas" :value="value" /> -->
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table border="0" style="width: 450px">
                  <tbody>
                    <tr>
                      <td>
                        <table border="0">
                          <tbody>
                            <tr>
                              <td style="padding-top: 5px">
                                <a href="https://osfatun.com.ar">
                                  <img
                                    style="width: 120px; height: auto"
                                    src="https://zaro.com.ar/firmas/osfatun/logo.png"
                                    width="140"
                                  />
                                </a>
                              </td>
                              <td>
                                <table
                                  border="0"
                                  cellpadding="3"
                                >
                                  <tbody>
                                    <tr>
                                      <td style="padding:0;">
                                        <a href="https://osfatun.com.ar">
                                          <img
                                            src="https://zaro.com.ar/firmas/osfatun/web.png"
                                          />
                                        </a>
                                      </td>
                                      <td style="padding:0;">
                                        <a
                                          href="https://osfatun.com.ar"
                                          style="
                                            text-decoration: none;
                                            color: #5d5d5d;
                                            font-size: 9px;
                                          "
                                        >
                                          <i>www.osfatun.com.ar</i>
                                        </a>
                                      </td>
                                      <td style="padding:0;">
                                        <a
                                          href="https://facebook.com/osfatunoficial"
                                        >
                                          <img
                                            src="https://zaro.com.ar/firmas/osfatun/facebook.png"
                                          />
                                        </a>
                                      </td>
                                      <td style="padding:0;">
                                        <a
                                          href="https://instagram.com/osfatunoficial"
                                        >
                                          <img
                                            src="https://zaro.com.ar/firmas/osfatun/instagram.png"
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding:0;">
                                        <a href="tel:08006665173">
                                          <img
                                            src="https://zaro.com.ar/firmas/osfatun/call.png"
                                          />
                                        </a>
                                      </td>
                                      <td style="padding:0;">
                                        <i style="color: #5d5d5d"
                                          >0800 666 5173</i
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style="padding: 0 0 20px 10px;width:100%;">
                        <img src="https://zaro.com.ar/firmas/osfatun/osfatun-eco.png" width=35 style="float: left; margin-right: 5px;" />
                        <p style="line-height: 3.6;">
                          Sólo imprimir en caso que sea necesario.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <section style="display: none">
        <article id="profile">
          <img src="/img/profile/firma-osfatun-base.jpg" alt="firma" />
          <p class="name">
            {{ name }}<span>{{ lastname }}</span>
          </p>
          <p class="role">{{ responsability }}</p>
          <ul>
            <li>{{ email }}</li>
            <li>{{ celphone }}</li>
            <li>{{ phone }}</li>
            <li>{{ delegation_address }}</li>
          </ul>
        </article>
      </section>
    </div>
    <div class="column is-6 has-text-left">
      <b-button @click="clipboardCopyCodeMode" type="is-primary">
        Copiar código
      </b-button>
      &nbsp;&nbsp;<br />
      <b-button @click="clipboardCopyVisualMode" type="is-primary">
        Copiar visual
      </b-button>
      <section style="display: none">
        &nbsp;&nbsp;<br />
        <b-button @click="handleDownload" type="is-primary">
          Descargar perfil
        </b-button>
      </section>
    </div>
  </section>
</template>

<script>
import html2canvas from "html2canvas";
import QR from "./qr.vue";
export default {
  name: "SignatureProfile",
  props: {
    id: {
      type: String,
    },
    image: {
      type: String,
    },
    name: {
      type: String,
    },
    lastname: {
      type: String,
    },
    responsability: {
      type: String,
    },
    email: {
      type: String,
    },
    celphone: {
      type: String,
    },
    phone: {
      type: String,
    },
    internal_phone: {
      type: String,
    },
    value: {
      type: String,
    },
    delegation_name: {
      type: String,
    },
    delegation_city: {
      type: String,
    },
    delegation_province: {
      type: String,
    },
    delegation_address: {
      type: String,
    },
  },
  components: {
    QR,
  },
  methods: {
    clipboardCopyCodeMode() {
      let content = this.$refs.copyPlaneVisual;
      navigator.clipboard.writeText(content.innerHTML);
    },
    clipboardCopyVisualMode() {
      let content = this.$refs.copyPlaneVisual;
      if (document.selection) {
        let range = document.body.createTextRange();
        range.moveToElementText(content);
        range.select();
      } else if (window.getSelection) {
        let range = document.createRange();
        range.selectNode(content);
        window.getSelection().empty();
        window.getSelection().addRange(range);
      }
      document.execCommand("Copy");
    },
    handleDownload() {
      // profile
      html2canvas(document.getElementById("profile"))
      .then((canvas) => {
        const urlQR = `https://api.qrserver.com/v1/create-qr-code/?size=90x90&data=https://www.osfatun.com.ar/personal/${this.id}`;
        let context = canvas.getContext("2d");

        // add profile image
        let profileImage = new Image();
            profileImage.src = this.image;
            profileImage.onload = function () {
              context.drawImage(profileImage, 35, 30);
            };
        
        // add qr image
        let qrImage = new Image();
            qrImage.src = urlQR;
            qrImage.onload = function () {
              context.drawImage(qrImage, 350, 25);
            };

        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/jpg");
        link.download = "Firma.jpg";
        link.click();
      });
    },
  },
};
</script>

<style lang="scss">
.tablesignature {
  font-family: "Arial", sans-serif;
  font-size: 8px;
  font-weight: 300;
  background: url("https://zaro.com.ar/firmas/osfatun/fondo.png");
  background-size: 622px;
  background-repeat: no-repeat;
}
.my_container {
  width: 600px;
}
.img_pos {
  position: relative;
  left: 5px;
}
#profile {
  display: block;
  width: 430px;
  position: relative;
}
#profile ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 27px;
  right: 110px;
}
#profile p.name {
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 90px;
  left: 10px;
  margin: 0;
}
#profile p.name span {
  color: #00758f;
}

#profile p.role {
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 135px;
  color: darkgray;
  margin: 0;
}
</style>
