<template>
  <div id="sales-page">
    <Loader :active="loading" />
    <div class="columns mx-4">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-left">
            <h1 class="title has-text-primary">Ventas</h1>
          </div>
        </div>
      </div>
      <div class="column is-half">
        <div class="columns h-fit-content is-justify-content-flex-end">
          <div class="buttons level-right">
            <div class="m-1">
              <b-button
                icon-pack="fas"
                icon-left="file-excel"
                @click="generateExcelReport"
                type="is-primary"
                outlined
                v-if="my_role == 1"
                >Informe</b-button
              >
            </div>
            <div class="m-1">
              <b-button
                @click="$store.commit('setNewSale', true)"
                type="is-primary"
              >
                Crear Nueva
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns mx-4">
      <div class="column">
        <div class="level">
          <b-field
            v-if="my_role == 1 && (my_departament == 1 || my_departament == 6 || my_departament == 3 || my_departament == 28)"
          >
            <b-switch v-model="assigned" @input="switchSales()">
              Asignadas a un agente
            </b-switch>
          </b-field>
        </div>
      </div>

      <div class="column is-justify-content-flex-end">
        <div class="level-right">
          <div
            class="control has-icons-left"
            v-if="
              !loading && departaments.length > 0 && assigned && my_role == 1
            "
          >
            <div class="select is-normal">
              <select v-model="origin_selected" @change="showAgents()">
                <option disabled selected :value="null">
                  Filtrar por origen...
                </option>
                <option v-for="origin in origins" :key="origin" :value="origin">
                  {{ origin }}
                </option>
              </select>
            </div>

            <div class="icon is-small is-left">
              <b-icon
                pack="fas"
                icon="location-arrow"
                size="is-small"
                type="is-primary"
              >
              </b-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-justify-content-flex-end">
        <div class="level-right">
          <div
            class="control has-icons-left"
            v-if="
              !loading && departaments.length > 0 && assigned && my_role == 1
            "
          >
            <div class="select is-normal">
              <select v-model="departament_selected" @change="showAgents()">
                <option disabled selected :value="null">
                  Filtrar por departamento...
                </option>
                <option
                  v-for="departament in departaments"
                  :key="departament.id"
                  :value="departament.id"
                >
                  {{ departament.name }}
                </option>
              </select>
            </div>
            <div class="icon is-small is-left">
              <b-icon
                pack="fas"
                icon="network-wired"
                size="is-small"
                type="is-primary"
              >
              </b-icon>
            </div>
          </div>
        </div>
      </div>
      <div
        class="column is-justify-content-flex-end"
        v-if="departament_selected && !loading && agents && my_role == 1"
      >
        <div class="level-right">
          <div class="control has-icons-left">
            <div class="select is-normal">
              <select v-model="agent_selected">
                <option disabled selected :value="null">
                  Filtrar por agente...
                </option>
                <option
                  v-for="agent in agents"
                  :key="agent.id"
                  :value="agent.id"
                >
                  {{ agent.lastname }}, {{ agent.name }}
                </option>
              </select>
            </div>
            <div class="icon is-small is-left">
              <b-icon
                pack="fas"
                icon="user-tie"
                size="is-small"
                type="is-primary"
              >
              </b-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-1 ml-3 is-justify-content-flex-end">
        <div
          class="field is-grouped is-justify-content-flex-end"
          v-if="!loading && departaments.length > 0 && assigned"
        >
          <b-button
            type="is-primary"
            @click="filterLeads()"
            class="mr-1"
            :disabled="!departament_selected && !origin_selected"
          >
            <b-icon pack="fas" icon="filter" size="is-small"></b-icon>
          </b-button>

          <b-button type="is-primary" outlined @click="resetFilters()">
            <b-icon pack="fas" icon="undo" size="is-small" type="is-primary">
            </b-icon>
          </b-button>
        </div>
      </div>
    </div>
    <div class="columns mx-4">
      <div class="column is-one-quarter">
        <b-field>
          <b-input
            v-model="searchValue"
            placeholder="Nombre de contacto"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-one-fifth">
        <div class="field is-grouped">
          <b-button type="is-primary" @click="searching()" class="mr-1">
            <b-icon pack="fas" icon="search" size="is-small"> </b-icon>
          </b-button>

          <b-button type="is-primary" outlined @click="resetSearch()">
            <b-icon pack="fas" icon="undo" size="is-small" type="is-primary">
            </b-icon>
          </b-button>
        </div>
      </div>
      <div
        class="column is-flex is-align-items-center is-justify-content-flex-end"
      >
        <!-- 
                    date between
                -->
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <b-field class="is-align-items-center">
                <label class="m-2">Desde</label>
                <b-datepicker
                  v-model="date_from"
                  :show-week-number="true"
                  :max="date_to"
                  locale="es"
                  placeholder="Desde..."
                  trap-focus
                  position="is-bottom-left"
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-field>
                <label class="m-2">Hasta</label>
                <b-datepicker
                  v-model="date_to"
                  :show-week-number="true"
                  :min="date_from"
                  locale="es"
                  placeholder="Hasta..."
                  trap-focus
                  position="is-bottom-left"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="level-item">
              <b-button
                @click="fetchSales"
                type="is-primary"
                icon-pack="fas"
                icon-left="filter"
              ></b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Board :columns="columns"></Board>
    <Modal />
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Board from "@/components/Sales-board.vue";
import Search from "@/components/Search.vue";
import Modal from "@/components/Sale-modal.vue";
import moment from "moment";

export default {
  name: "SalesPage",
  components: {
    Board,
    Search,
    Modal,
    Loader,
  },
  data() {
    return {
      my_role: "",
      my_departament: "",
      assigned: false,
      agents: [],
      agent_selected: null,
      departaments: [],
      departament_selected: null,
      origin_selected: null,
      origins: [
        "cotizador",
        "crm-consultas",
        "crm-ventas",
        "afiliciaciononline",
        "programa-referidos",
        "crm-consultas-web",
        "crm-consultas-whatsapp",
        "crm-consultas-messenger",
        "crm-consultas-instagram",
        "crm-consultas-email",
        "crm-consultas-delegacion",
        "crm-consultas-call center",
        "crm-consultas-referido",
      ],
      date_from: null,
      date_to: null,
      columns_origin: [
        { title: "Lead", cards: [] },
        { title: "Prospecto", cards: [] },
        { title: "Auditor", cards: [] },
        { title: "Calificado", cards: [] },
        { title: "Cierre", cards: [] },
        { title: "Carga", cards: [] },
        { title: "Digital", cards: [] },
        { title: "Físico", cards: [] },
        { title: "Alta", cards: [] },
      ],
      loading: false,
      searchValue: "",
      columns: [
        {
          title: "Lead",
          cards: [],
        },
        {
          title: "Prospecto",
          cards: [],
        },
        {
          title: "Auditor",
          cards: [],
        },
        {
          title: "Calificado",
          cards: [],
        },
        {
          title: "Cierre",
          cards: [],
        },
        {
          title: "Carga",
          cards: [],
        },
        {
          title: "Digital",
          cards: [],
        },
        {
          title: "Físico",
          cards: [],
        },
        {
          title: "Alta",
          cards: [],
        },
      ],
    };
  },
  methods: {
    async generateExcelReport() {
      if (this.my_role == 1) {
        this.loading = true;
        try {
          let response = await this.$repositories.sales.downloadReport(
            moment(this.date_from).format("YYYY-MM-DD"),
            moment(this.date_to)
              .set({ hour: 23, minute: 59, second: 59 })
              .format("YYYY-MM-DD HH:mm:ss")
          );
          console.log(response);
        } catch (error) {
          console.log("Catch error: ", error);
        } finally {
          this.loading = false;
        }
      }
    },
    switchSales() {
      if (
        this.my_role == 1 &&
        (this.my_departament == 1 || this.my_departament == 6  || this.my_departament == 3  || this.my_departament == 28)
      ) {
        this.loading = true;
        try {
          for (let index = 0; index < this.columns_origin.length; index++) {
            this.columns[index].cards = this.columns_origin[index].cards.filter(
              (card) => {
                if (this.assigned) {
                  return card.agent_id != "";
                } else {
                  return card.agent_id == "";
                }
              }
            );
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      } else {
        for (let index = 0; index < this.columns.length; index++) {
          this.columns[index].cards = this.columns_origin[index].cards;
        }
      }
    },
    resetFilters() {
      this.departament_selected = null;
      this.agent_selected = null;
      this.resetSearch();
    },
   filterLeads() {
  for (let index = 0; index < this.columns_origin.length; index++) {
    const items = this.columns_origin[index].cards.filter((lead) => {
      let result = true;

      // Verificar si el campo origin coincide con origin_selected, solo si origin_selected no es null
      if (this.origin_selected !== null && lead.origin !== this.origin_selected) {
        result = false;
      }

      if (this.assigned) {
        // Verificar si el campo agent_departament coincide con departament_selected, solo si departament_selected no es null
        if (this.departament_selected !== null && lead.agent_departament !== this.departament_selected) {
          result = false;
        }

        // Verificar si el campo agent_id coincide con agent_selected, solo si agent_selected no es null
        if (this.agent_selected !== null && lead.agent_id !== this.agent_selected) {
          result = false;
        }
      } else {
        result = lead.agent_id === "";
      }

      return result;
    });
    this.columns[index].cards = items;
  }
},

    resetSearch() {
      this.searchValue = "";
      this.switchSales();
    },
    searching() {

      const condition_role =
        this.my_role == 1 &&
        !(this.my_departament == 1 || this.my_departament == 6 || this.my_departament == 3 || this.my_departament == 28);
      try {
        if (this.searchValue != "") {
          for (let i = 0; i < this.columns_origin.length; i++) {
            console.log(this.columns_origin[i].cards);
            this.columns[i].cards = this.columns_origin[i].cards.filter(
              (item) =>
                item.contact_fullname &&
                this.normalizeString(item.contact_fullname)
                  .toLowerCase()
                  .includes(
                    this.normalizeString(this.searchValue).toLowerCase()
                  ) &&
                (condition_role
                  ? item.agent_id != ""
                  : this.assigned
                  ? item.agent_id != ""
                  : item.agent_id == "")
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    normalizeString(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    showAgents() {
      this.agent_selected = null;
      this.fetchAgents();
    },
    async fetchDepartaments() {
      this.loading = true;
      try {
        const response = await this.$repositories.departaments.list();
        this.departaments = response.data.departaments
          .filter((departament) => departament.is_marketer)
          .sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchAgents() {
      this.loading = true;
      try {
        if (this.departament_selected) {
          const response = await this.$repositories.agents.departament(
            this.departament_selected
          );
          this.agents = response.data.users.sort((a, b) =>
            a.lastname.localeCompare(b.lastname)
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchSales() {
      this.loading = true;
      try {
        this.columns.forEach((column) => {
          column.cards = [];
        });
        this.columns_origin.forEach((column) => {
          column.cards = [];
        });

        const query = await this.$repositories.sales.index(
          this.search,
          moment(this.date_from).format("YYYY-MM-DD"),
          moment(this.date_to)
            .set({ hour: 23, minute: 59, second: 59 })
            .format("YYYY-MM-DD HH:mm:ss")
        );
        query.data.sales.forEach((item) => {
          const STAGE_PREV = {
            lead: "lead",
            prospecto: "lead",
            auditor: "prospecto",
            calificado: "auditor",
            cierre: "calificado",
            carga: "cierre",
            "presentacion digital": "carga",
            "presentacion fisica": "presentacion digital",
            alta: "presentacion fisica",
          };
          const STAGE_NEXT = {
            lead: "prospecto",
            prospecto: "auditor",
            auditor: "calificado",
            calificado: "cierre",
            cierre: "carga",
            carga: "presentacion digital",
            "presentacion digital": "presentacion fisica",
            "presentacion fisica": "alta",
            alta: "alta",
          };
          let color = "success";
          //    if (item.id == 6329)
          //     debugger

          const time_created_at = new Date(item.created_at).getTime();
          let diftime_created_at =
            (new Date().getTime() - time_created_at) / 1000 / 60 / 60;
          if (item.user) {
            //is asigned

            if (item.updated_at_stage) {
              const diftime =
                (new Date().getTime() -
                  new Date(item.updated_at_stage).getTime()) /
                1000 /
                60 /
                60;
              if (diftime > 24) {
                if (diftime > 48) {
                  color = "danger";
                } else {
                  color = "warning";
                }
              } else {
                color = "success";
              }
            } else {
              const diftime =
                (new Date().getTime() - new Date(item.updated_at).getTime()) /
                1000 /
                60 /
                60;
              if (diftime > 24) {
                if (diftime > 48) {
                  color = "danger";
                } else {
                  color = "warning";
                }
              } else {
                color = "success";
              }
            }
          } else {
            if (diftime_created_at > 48) {
              color = "danger";
            } else if (diftime_created_at > 24) {
              color = "warning";
            } else {
              color = "success";
            }
          }

          // if(item.user){
          //     const a = new Date(item.updated_at).getTime()
          //     let diftime = (new Date().getTime() - a) / 1000 / 60 / 60
          //     if(item.comment){
          //         if(diftime > 24){
          //             if(diftime > 48){
          //                 color  = 'danger'
          //             }else{
          //                 color= 'warning'
          //             }
          //         }else{
          //             color = 'success'
          //         }
          //     }else{
          //         if(item.updated_at_stage){
          //             diftime = (new Date().getTime() - new Date(item.updated_at_stage).getTime()) / 1000 / 60 / 60
          //             if(diftime > 24){
          //                 if(diftime > 48){
          //                     color  = 'danger'
          //                 }else{
          //                     color= 'warning'
          //                 }
          //             }else{
          //                 color = 'success'
          //             }
          //         }else{
          //             if (diftime<48){
          //                 color = 'success'

          //             }else {
          //                 color = 'danger'
          //             }
          //         }
          //     }
          // }
          const ITEMS = {
            id: item.id,
            stage: item.stage,
            origin: item.inherited_origin ? item.inherited_origin : item.origin,
            ticket_id: item.ticket_id,
            status: item.status,
            stage_prev: STAGE_PREV[item.stage],
            stage_next: STAGE_NEXT[item.stage],
            contact_fullname: item.contact.fullname,
            province: item.contact.province,
            phone: item.contact.phone,
            agent_id: item.user != null ? item.user.id : "",
            agent_name: item.user != null ? item.user.name : "",
            agent_lastname: item.user != null ? item.user.lastname : "",
            agent_departament:
              item.user != null ? item.user.departament_id : "",
            agent_avatar: item.user != null ? item.user.avatar : "",
            date: item.created_at,
            color,
          };
          const STAGE = {
            lead: 0,
            prospecto: 1,
            auditor: 2,
            calificado: 3,
            cierre: 4,
            carga: 5,
            "presentacion digital": 6,
            "presentacion fisica": 7,
            alta: 8,
          };
          // if(item.origin != 'afiliciaciononline'){
          //    this.columns[STAGE[item.stage]].cards.push(ITEMS);
          //    this.columns_origin[STAGE[item.stage]].cards.push(ITEMS);
          //}else{
          // if([3, 6, 10, 20, 21].includes(this.my_departament)){
          this.columns[STAGE[item.stage]].cards.push(ITEMS);
          this.columns_origin[STAGE[item.stage]].cards.push(ITEMS);
          //}
          //}
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.switchSales();
        this.loading = false;
      }
    },
  },

  created() {
    const userPermissions = this.$store.getters["getSession"]
      .departament_marketer;
    //console.log('Permisos del usuario:', userPermissions);
    if (userPermissions == true) {
      this.date_from = moment().subtract(3, "months").toDate();
    } else {
      this.date_from = moment().subtract(1, "months").toDate();
    }

    this.date_to = moment().toDate();
    this.fetchSales();
    this.fetchDepartaments();
    this.my_role = this.$store.getters.getSession.role_id;
    this.my_departament = this.$store.getters.getSession.departament_id;
    this.$EventBus.$on("SaleUpdate", () => {
      this.fetchSales();
    });
  },
};
</script>

<style lang="scss" scoped>
#sales-page {
  position: relative;
  height: 100vh;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  .columns:last-child {
    height: calc(100vh - 250px);
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.h-fit-content {
  height: fit-content !important;
}
.b-datepicker-content {
  right: 0;
  left: auto;
}
</style>
