export default (axios, baseURL) => {
    return {
        index(page, search, departament, origin, startDate, endDate) {
            return axios.get(`${baseURL}/api/contacts`, {
                params: {
                    search,
                    departament,
                    origin,
                    page,
                    start_date: startDate,
                    end_date: endDate
                }
            });
        },

        getAll( search, departament, origin, startDate, endDate) {
            return axios.get(`${baseURL}/api/contacts/all`, {
                params: {
                    search,
                    departament,
                    origin,

                    start_date: startDate,
                    end_date: endDate
                }
            });
        },

       
        show(id) {
            return axios.get(`${baseURL}/api/contacts/${id}`);
        },
        create(data) {
            return axios.post(`${baseURL}/api/contacts`, data);
        },
        update(id, data) {
            return axios.post(`${baseURL}/api/contacts/${id}`, data);
        },
        tickets(id) {
            return axios.get(`${baseURL}/api/contacts/${id}/tickets`);
        },
        getFiles(id) {
            return axios.get(`${baseURL}/api/contacts/${id}/files`);
        },
        getSignatures(id) {
            return axios.get(`${baseURL}/api/landing/signatures/contact/${id}`);
        },

        importFromExcel(data) {
            return axios.post(`${baseURL}/api/import-contacts`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
    }
}
