<template>
    <div id="new-departament-page">
        <DepartamentForm/>
    </div>
</template>

<script>
    import DepartamentForm from '@/components/Departament-form.vue';
    export default {
        name:'DepartamentFormPage',
        components:{
            DepartamentForm
        }
    }
</script>