import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import VueTimeago from "vue-timeago";
import VueChatScroll from "vue-chat-scroll";
import FontAwesome from "@fortawesome/fontawesome-free/js/all";
import Repositories from "@/repositories/index.js";
import Cookies from 'js-cookie';


import VueQRCodeComponent from "vue-qr-generator";
Vue.component("qr-code", VueQRCodeComponent);

//import Echo from "laravel-echo";
Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultIconPrev: 'chevron-left',
  defaultIconNext: 'chevron-right'	
});
Vue.use(VueChatScroll);
Vue.use({
  install(Vue) {
    Object.defineProperty(Vue.prototype, "$repositories", { value: Repositories });
  },
});
Vue.use(VueTimeago, {
  name: 'Timeago', 
  locale: 'es'
})

Vue.filter('truncate', function (value, limit) {
  if (value.length > limit) {
      value = value.substring(0, (limit - 3)) + '...';
  }
  return value
});

Vue.config.productionTip = false;
Vue.prototype.$appPath = `${process.env.VUE_APP_ROOT}/`;
Vue.prototype.$apiPath = `${process.env.VUE_APP_ROOT_API}`;
Vue.prototype.$filePath = `${process.env.VUE_APP_ROOT_API}/storage/`;
Vue.prototype.$avatarDefault = 'https://bulma.io/images/placeholders/128x128.png';
Vue.prototype.$EventBus = new Vue();

Vue.prototype.$hasPermission = function(permissionToCheck) {
  const userPermissions = this.$store.getters["getSession"]["permissions"];
  return userPermissions && userPermissions.includes(permissionToCheck);
};



new Vue({
  router,
  store,
  Buefy,
  FontAwesome,
  render: (h) => h(App),
}).$mount("#app");


Vue.mixin({
  beforeMount() {
    //localStorage.clear();
    const isLoginPage = this.$route.path === '/login';
    
    if (!Cookies.get('token') && !isLoginPage)  {
      this.$router.push('/login');
    }
  },
});







