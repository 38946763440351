<template>
  <div id="plans-page">
    <div class="columns my-4">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-left">
            <h1 class="title has-text-primary">Cuotas y mínimos - Cotizador</h1>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="tabs">
        <router-link
          to="/capita"
          class="nav-link"
          active-class="is-active"
          exact
        >
          Cápita mínina opciones
        </router-link>
        <router-link
          to="/monotributo"
          class="nav-link"
          active-class="is-active"
          exact
        >
          ACM Monotributistas
        </router-link>
         <router-link
          to="/adherentes"
          class="nav-link"
          active-class="is-active"
          exact
        >
          Adherentes
        </router-link>
        
       
       
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: "PlansPage",
  components: {},
};
</script>

<style lang="scss" scoped>
#plans-page {
  position: relative;
  height: 100vh;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  .columns:last-child {
    height: calc(100vh - 250px);
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.h-fit-content {
  height: fit-content !important;
}

.tabs {
  display: flex;
  justify-content: left;
  align-items: left;
}

.nav-link {
  padding: 10px;
  margin-right: 10px;
  border-bottom: none;
}

.nav-link:hover,
.nav-link.is-active {
  background-color: #f5f7fa;
  border-color: transparent;
  color: #00758f;
  border-bottom: 3px solid #00758f;
}
</style>
