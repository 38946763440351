

<template>
    <div id="departaments-page" style="overflow-y: auto; max-height: 100vh; padding: 20px">
        <div class="columns">
            <div class="column">
                <div class="level is-mobile">
                    <div class="level-left">
                        <h1 class="title has-text-primary">Permisos departamento {{this.$route.params.name}}</h1>
                        
                    </div>
                   
                </div>
                 
            </div>
        </div>
     
               <DepartamentPermissions/>
         
        </div>
 
</template>

<script>
    import DepartamentPermissions from '@/components/DepartamentPermissions.vue';
    export default {
        name:'DepartamentFormPage',
        components:{
            DepartamentPermissions
        }
    }
</script>