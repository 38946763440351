<template>
    <router-link :to="`/ventas/id/${sale.id}`">
        <div class="card" :class="`border-${sale.color}`">
            <div class="card-content">
                <div class="level is-mobile">
                    <div class="level-left">
                        <div class="level-item">
                            <div>
                                <h4 class="title is-4 has-text-weight-bold has-text-dark is-uppercase">#{{ saleId(sale.id) }}</h4>
                                <div style="background-color: gray;color: black;padding: 0 10px; right: 10px;top: 15px; position: absolute" >{{ sale.status =="not attended" ? "No atendida" : sale.status =="in process" ? 'En proceso' : sale.status =="discarded" ? "Descartada" : "alta"}}</div>
                                <h5 class="title is-5 is-spaced has-text-primary is-uppercase is-namesales" :title="sale.contact_fullname">{{ sale.contact_fullname}}</h5>
                                <p class="has-text-grey mb-1">{{ sale.province}} / {{ sale.date.split('T')[0].split('-')[2] + "-" + sale.date.split('T')[0].split('-')[1] +"-"+ sale.date.split('T')[0].split('-')[0] }}</p>
                
                                <div class="is-flex">
                                    <div class="tags has-addons mb-0 mr-3">
                                        <span class="tag is-uppercase has-text-weight-bold">{{ sale.origin }}</span>
                                        <span class="tag">
                                            <b-icon
                                                pack="fas"
                                                icon="globe"
                                                size="is-medium">
                                            </b-icon>
                                        </span>
                                    </div>
                                </div>
                                <a v-if="sale.ticket_id" @click.prevent="$EventBus.$emit('saleTicketModal', sale.ticket_id)">
                                    ver consulta
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="card-footer-item  is-justify-content-flex-start">
                    <b-tooltip 
                        v-if="sale.agent_id != ''"
                        label="Atendido por:"
                        type="is-dark"
                        position="is-top"
                        :square=true>
                        <div class="media">
                            <div class="media-left mr-2">
                                <figure class="image is-24x24 m-0">
                                    <img class="is-rounded" :src="`${(sale.agent_avatar) ? 
                                        sale.agent_avatar : 
                                        $avatarDefault}`"
                                        style="width: 100%;
                                        height: 100%;
                                        object-fit: cover;">
                                </figure>
                            </div>
                            <div class="media-content">
                                <p>{{ `${sale.agent_name} ${sale.agent_lastname}` }}</p>
                            </div>
                        </div>
                    </b-tooltip>
                </div>
            </div>
        </div>
    </router-link>    
</template>

<script>    
    import {fillString} from "@/helpers";
    export default {
        name:'SaleCard',
        props: {
            sale: {
                type: Object,
                default: () => ({})
            }
        },
        methods:{
            saleId(value){
                return fillString(value, 4);
            }
        }
    }
</script>

<style lang="scss" scoped>
    a{
        display: block;       
        .card{
            span.icon{
                padding: .5rem;
                border-radius: 50%;
            }
            .card-content{
                padding: 1rem!important;
            }
        }
        .border-dark{
            border: 2px solid $dark;
        }
        .border-success{
            border: 2px solid $success;
        }
        .border-warning{
            border: 2px solid $warning;
        }
        .border-danger{
            border: 2px solid $danger;
        }  
    }
    .is-namesales {
        max-width: 245px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
    }
      
</style>
