export default (axios, baseURL) => {
    return {
        unread(){
            return axios.get(`${baseURL}/api/notifications/unread`);
        },
        markAsRead(id, data){
            return axios.post(`${baseURL}/api/notifications/${id}/read`,
                data
            );
        }
    }
}