<template>
    <div id="form-sale-page">
        <SaleForm/>
    </div>
</template>

<script>
    import SaleForm from '@/components/Sale-form.vue';
    export default {
        name:'SaleFormPage',
        components:{
            SaleForm
        }
    }
</script>