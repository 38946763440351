<template>
    <div id="login-page">
        <Login/>
    </div>
</template>

<script>
    import Login from '@/components/Login.vue';
    export default {
        name:'Login-page',
        components:{
            Login
        }
    }
</script>