<template>
  <div>
    <!-- Filtro de Fechas -->
    <div class="filters-container is-flex is-align-items-center">
      <div class="filter-item">
        <label class="label">Desde: </label>
        <b-input type="date" v-model="filters.startDate" size="is-small" />
      </div>
      
      <div class="filter-item">
        <label class="label">Hasta: </label>
        <b-input type="date" v-model="filters.endDate" size="is-small" />
      </div>
      
      <b-button @click="applyDateFilter" style="margin-top: 30px" type="is-primary" size="is-small">
        Filtrar
      </b-button>
    </div>

    <!-- Loading Spinner -->
    <b-loading :is-full-page="false" :active.sync="loading" />

    <!-- Tabla -->
    <table v-if="!loading">
      <thead>
        <tr>
          <th>Fecha</th>
          <th>Contenido</th>
          <th>ID</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="timeline in timelines" :key="timeline.id">
          <!-- Mostrar la fecha -->
          <td>{{ formatDate(timeline.created_at) }}</td>

          <!-- Mostrar el contenido -->
          <td>{{ timeline.content }}</td>

          <!-- Mostrar el ID, ya sea sale_id o ticket_id -->
          <td>
            <span v-if="timeline.sale_id">Venta ID: {{ timeline.sale_id }}</span>
            <span v-else-if="timeline.ticket_id">Ticket ID: {{ timeline.ticket_id }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Mensaje si no hay resultados -->
    <div v-if="!loading && timelines.length === 0" class="has-text-centered">
      No se encontraron resultados
    </div>

    <!-- Paginación -->
    <div class="pagination">
      <button @click="previousRange" :disabled="currentRangeStart === 1">Anterior</button>
      <span
        v-for="page in visiblePages"
        :key="page"
        @click="changePage(page)"
        :class="{ active: page === pagination.current_page }"
      >
        {{ page }}
      </span>
      <button @click="nextRange" :disabled="currentRangeEnd >= totalPages">Siguiente</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgentsTimeline",
  data() {
    return {
      loading: false,
      timelines: [],
      pagination: {
        total: 0,
        per_page: 10,
        current_page: 1
      },
      rangeSize: 5,
      currentRangeStart: 1,
      currentRangeEnd: 5,
      filters: {
        startDate: '',
        endDate: ''
      }
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.per_page);
    },
    visiblePages() {
      let pages = [];
      for (let i = this.currentRangeStart; i <= this.currentRangeEnd && i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    async fetchTimelines() {
      this.loading = true; 
      try {
        
        const response = await this.$repositories.agents.getTimelines(
          this.pagination.current_page,
          null, // userId (si es necesario, puedes agregarlo)
          this.filters.startDate,
          this.filters.endDate
        );
        this.loading = false; 
        this.timelines = response.data.data;
        this.pagination.total = response.data.total;
        this.pagination.per_page = response.data.per_page;
        this.pagination.current_page = response.data.current_page;
      } catch (error) {
        console.error("Error al obtener los timelines:", error);
      }
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.pagination.current_page = page;
        this.fetchTimelines();
        this.updateRange(page);
      }
    },
    applyDateFilter() {
      this.pagination.current_page = 1; // Reiniciar la página al aplicar el filtro
      this.fetchTimelines();
    },
    updateRange(page) {
      if (page < this.currentRangeStart) {
        this.currentRangeStart = Math.max(1, page - Math.floor(this.rangeSize / 2));
        this.currentRangeEnd = Math.min(this.totalPages, this.currentRangeStart + this.rangeSize - 1);
      } else if (page > this.currentRangeEnd) {
        this.currentRangeEnd = Math.min(this.totalPages, page + Math.floor(this.rangeSize / 2));
        this.currentRangeStart = Math.max(1, this.currentRangeEnd - this.rangeSize + 1);
      }
    },
    previousRange() {
      if (this.currentRangeStart > 1) {
        const newStart = Math.max(1, this.currentRangeStart - this.rangeSize);
        this.currentRangeEnd = newStart + this.rangeSize - 1;
        this.currentRangeStart = newStart;
        this.fetchTimelines();
      }
    },
    nextRange() {
      if (this.currentRangeEnd < this.totalPages) {
        const newEnd = Math.min(this.totalPages, this.currentRangeEnd + this.rangeSize);
        this.currentRangeStart = newEnd - this.rangeSize + 1;
        this.currentRangeEnd = newEnd;
        this.fetchTimelines();
      }
    },
    formatDate(date) {
      if (date.includes("T")) {
        return new Date(date).toLocaleDateString("es-ES", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
         minute: "2-digit"
        });
      }
      return date;
    }
  },
  mounted() {
    this.fetchTimelines();
  }
};
</script>



<style scoped>
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  margin: 0 10px;
  padding: 5px 10px;
}

.pagination span {
  margin: 0 5px;
  cursor: pointer;
  padding: 5px;
  border: 1px solid #ccc;
}

.pagination span.active {
  font-weight: bold;
  border-color: #000;
}
</style>
