<template>
    <div id="contacts-page">
        <div class="columns">
            <div class="column">
                <div class="level is-mobile">
                    <div class="level-left">
                        <h1 class="title has-text-primary">Contactos</h1>
                    </div>
                    <div class="level-right"></div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <Table :search="search"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Table from '@/components/Contacts-table.vue';
    export default {
        name:'ContactsPage',
        components:{
            Table
        },
        data(){
            return{
                search: ''
            }
        },
        methods:{
            getValue(value){
                this.search = value;
            },
        }
    }
</script>

<style lang="scss" scoped>
    #contacts-page{
        position: relative;
        height: 100vh;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
</style>