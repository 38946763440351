<template>
    <div class="avatar">
        <div class="media">
            <div class="media-left">
                <figure class="image is-128x128 mb-3">
                    <img class="is-rounded" :src="(imagePrev) ? imagePrev : image">
                </figure> 
            </div>
            <div class="media-content">
                <b-field class="file is-primary" :class="{'has-name': !!file}">
                    <b-upload v-model="file" @input="onFileChange" :disabled="!$store.getters['getSession']['is_admin']" class="file-label">
                        <span class="file-cta">
                            <b-icon class="file-icon" icon="upload"></b-icon>
                            <span class="file-label">Subir foto</span>
                        </span>
                        <span class="file-name" v-if="file">
                            {{ file.name }}
                        </span>
                    </b-upload>
                </b-field>
            </div>
        </div>
    </div>    
</template>

<script>
    export default {
        name: 'Avatar',
        props:{
            image:{
                type: String,
            }
        },
        data() {
            return {
                file: null,
                imagePrev: null
            }
        },
        methods:{
            onFileChange(){
                this.imagePrev = URL.createObjectURL(this.file);
                this.$emit('value', this.file);
            },
        },
    }
</script>

<style lang="scss" scoped>
    .avatar{
        .media{
            align-items: center;
            .upload{
                opacity: 1;
            }
            img{
                height: 100%;
                object-fit: cover;
            }
        }
    } 
</style>