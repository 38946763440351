<template>
    <b-loading 
        :is-full-page="full" 
        v-model="active">
    </b-loading>
</template>

<script>
    export default {
        name:'Loader',
        props:{
            active:{
                type: Boolean,
                default: false
            },
            full:{
                type: Boolean,
                default: true
            }
        }
    }
</script>