<template>
  <div id="tickets">
    <Loader :active="loading" :full="false"></Loader>
    <div class="columns">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-left">
            <h1 class="title has-text-primary">Consultas</h1>
          </div>
         
          <div class="level-right"   v-if="this.$hasPermission('consultas.crear')">
            <b-button
              tag="router-link"
              :to="'/consultas/nuevo'"
              type="is-primary"
            >
              Crear Nueva
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <form
      @submit="handleSearch"
      class="columns is-multiline is-mobile mb-5"
      style="height: auto"
    >
      <div class="column is-3-desktop">
        <b-field>
          <b-select class="customSelect" v-model="selectDataType">
            <option disabled value="">Buscar por</option>
            <option v-for="(i, k) in dataType" :value="i.type" :key="k">
              {{ i.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-6-desktop">
        <template v-if="selectDataType === 'nombre'">
          <input class="input" type="text" v-model="searchText" />
        </template>
        <template v-else>
          <input class="input" type="number" v-model="searchText" />
        </template>
      </div>
      <div class="column is-3-desktop">
        <b-button type="is-primary" @click="handleSearch"> Buscar </b-button>
      </div>
    </form>
    <!--
        <div class="columns">
            <div class="column">
                <b-tabs v-if="!loading & !empty" 
                    class="block"
                    v-model="filter.activeTab">
                    <b-tabs-item label="Todos"
                    v-model="filter.departament">
                    </b-tabs-item>
                    <b-tab-item v-for="departament in departaments.data"
                        :key="departament.id"
                        :value="departament.id"
                        :label="departament.name"
                        v-model="filter.departament">
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>
        -->
    <div
      class="columns"
      ref="columnsWrapper"
      :class="toggle ? 'is-block' : 'is-flex is-flex-wrap-wrap'"
    >
      <div
        class="column"
        v-for="ticket in tickets.data"
        :key="ticket.id"
        :class="toggle ? 'is-12' : 'is-6'"
      >
        <Card
          :id="ticket.id"
          :link="`/consultas/id/${ticket.id}`"
          :title="ticket.contact"
          :description="ticket.departament"
          :status="ticket.status"
          :priority="ticket.priority"
          :origin="ticket.origin"
          :is_urgent="ticket.is_urgent"
          :color="ticket.color"
        >
        </Card>
      </div>
    </div>
    <center v-if="empty">
      <b-icon pack="fas" icon="search" size="is-small" type="is-grey-light">
      </b-icon>
      No se encontraron resultados
    </center>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "./Loader.vue";
import Search from "./Search.vue";
import Card from "./Ticket-card.vue";
const COLOR = {
  low: "success",
  medium: "warning",
  high: "danger",
};
const STATUS = {
  "not attended": "No atendido",
  open: "Abierto",
  closed: "Cerrado",
};
const ORIGIN = {
  whatsapp: {
    type: "is-success",
    pack: "fab",
    icon: "whatsapp",
    name: "Whatsapp",
  },
  messenger: {
    type: "is-info",
    pack: "fab",
    icon: "facebook-messenger",
    name: "Messenger",
  },
  instagram: {
    type: "is-light",
    pack: "fab",
    icon: "instagram",
    name: "Instagram",
  },
  web: {
    type: "is-light",
    pack: "fas",
    icon: "globe",
    name: "Web",
  },
  crm: {
    type: "is-light",
    pack: "fas",
    icon: "globe",
    name: "Crm",
  },
  email: {
    type: "is-light",
    pack: "far",
    icon: "envelope",
    name: "Email",
  },
  delegacion: {
    type: "is-light",
    pack: "fas",
    icon: "globe",
    name: "Delegación",
  },
  "call center": {
    type: "is-light",
    pack: "fas",
    icon: "globe",
    name: "Call Center",
  },
  referido: {
    type: "is-light",
    pack: "fas",
    icon: "globe",
    name: "Referido",
  },
};
export default {
  name: "Tickets",
  components: {
    Loader,
    Search,
    Card,
  },
  data() {
    return {
      filter: {
        search: "",
        activeTab: "Todos",
        departament: "",
        user_id: "",
      },
      tickets: {
        data: [],
        total: 0,
        order: "desc",
        page: 1,
        lastPage: "",
        perPage: 10,
      },
      departaments: {
        data: [],
      },
      empty: false,
      loading: true,
      dataType: [
        { type: "id", name: "#" },
        { type: "nombre", name: "Nombre y apellido Afiliado" },
        { type: "cuil", name: "DNI/CUIL" },
      ],
      selectDataType: "nombre",
      searchText: "",
    };
  },
  methods: {
    async fetchTickets() {
       if(this.$hasPermission('consultas.miusuario')){
       this.filter.user_id = this.$store.getters["getSession"]["id"]
       }

        if(this.$hasPermission('consultas.midepartamento')){
       this.filter.departament = this.$store.getters["getSession"]["departament_id"]
       }
      try {
        this.loading = true;
        const query = await this.$repositories.tickets.index(
          this.tickets.page,
          this.searchText,
          this.selectDataType,
          this.filter.user_id,
          this.filter.departament
        );
        if (query.data.tickets.data.length > 0) {
          this.tickets.lastPage = query.data.tickets.last_page;
          
          query.data.tickets.data.forEach((item) => {
            const ITEMS = {
              id: item.id,
              contact: item.contact_name,
              departament: item.departament_name,
              origin: ORIGIN[item.origin],
              status: STATUS[item.status],
              priority: item.priority,
              is_urgent: item.is_urgent,
              color: item.status == "closed" ? "dark" : COLOR[item.priority],
            };
            this.tickets.data.push(ITEMS);
            console.log(ITEMS)
          });
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDepartaments() {
      try {
        const query = await this.$repositories.departaments.list();
        query.data.departaments.forEach((item) => {
          const items = {
            id: item.id,
            name: item.name,
            active: false,
          };
          this.departaments.data.push(items);
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleScrolled() {
      const wrapper = this.$refs.columnsWrapper;
      wrapper.onscroll = async () => {
        if (wrapper.offsetHeight + wrapper.scrollTop >= wrapper.scrollHeight) {
          if (this.tickets.page == this.tickets.lastPage) {
            return;
          }
          this.tickets.page = this.tickets.page + 1;
          this.loading = true;
          await this.fetchTickets();
        }
      };
    },
    getValue(value) {
      this.filter.search = value;
      this.tickets.data = [];
      this.fetchTickets();
    },
    handleSearch(e) {
      e.preventDefault();
      // console.log(this.selectDataType);
      if (this.selectDataType !== "" && this.searchText !== "") {
        this.tickets.data = [];
        this.fetchTickets();
      }
    },
  },
  computed: {
    ...mapState(["toggle"]),
  },
  created() {
    this.fetchDepartaments();
    this.fetchTickets();
    this.$EventBus.$on("updatedTicket", async () => {
      this.tickets.data = [];
      await this.fetchTickets();
    });
  },
  mounted() {
    this.handleScrolled();
  },
  destroy() {
    this.$EventBus.$off("updatedTicket");
  },
};
</script>

<style lang="scss">
#tickets {
  position: relative;
  height: 100vh;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  center {
    text-transform: uppercase;
    font-weight: 500;
    color: $grey-light;
  }
  .columns:last-child {
    height: calc(100vh - 240px);
    overflow-y: auto;
  }
  .customSelect {
    .select,
    select {
      width: 100%;
      display: block;
    }
  }
}
</style>