<template>
    <b-modal
        v-model="$store.getters.getTimeLine"
        has-modal-card
        :can-cancel="false"
        aria-modal
        aria-role="dialog"
        scroll="keep">        
        <div class="modal-card" style="width:350; min-height: 450px;">
            <header class="modal-card-head">
                <p class="modal-card-title is-uppercase has-text-weight-bold has-text-grey-light mb-0">Historial</p>
                <button
                    type="button"
                    class="delete"
                    @click="$store.commit('setTimeLine', false)"
                />
            </header>
            <div class="modal-card-body">
                <Loader :active="loading" :full="false"></Loader>
                <div class="menu" v-if="!empty">
                    <ul class="menu-list">
                        <li v-for="(item, index) in data" :key="index">
                            <div class="media">
                                <div class="media-left">
                                    <b-icon
                                        pack="fas"
                                        icon="list">
                                    </b-icon>
                                </div>
                                <div class="media-content">
                                    <p class="mb-0">{{ item.content }}</p>
                                    <small class="has-text-grey">
                                        {{ item.date }}
                                    </small>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>              
                <div v-else class="section">
                    <p class="has-text-centered">No hay registros existentes. </p>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
    import Loader from './Loader.vue';
    export default {
        name: 'Notifications',
        props:{
            loading:{
                type: Boolean,
                default: false
            },
            empty:{
                type: Boolean,
                default: true
            },
            data:{
                type: Array,
                default: [{}]
            }
        },
        components:{
            Loader
        }
    }
</script>
<style lang="scss" scoped>
    .modal-card{
        width: 350;
        min-height: 450px;
        .modal-card-body{
            position: relative;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            ul{
                li{
                    padding: .5rem 0;
                    border-bottom: 1px solid $light;
                    &:last-child{
                        border: none;
                    }
                }
            }   
        }
    }
</style>