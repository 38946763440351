<template>
    <div id="delegations-table">
        <div class="card">
            <div class="card-content">
                <b-field grouped>
                    <Search @value="getValue"/>
                </b-field>
                <b-table    
                    hoverable
                    paginated
                    pagination-simple
                    backend-pagination
                    @page-change="onPageChange"
                    :scrollable="true"
                    :sticky-header="true"
                    :total="total"
                    :per-page="perPage"
                    height="350"
                    :loading="loading"
                    :data="isEmpty ? [] : data">

                    <b-table-column field="name" label="Nombre" v-slot="props">
                        {{ props.row.name }}
                    </b-table-column>

                    <b-table-column field="type" label="Tipo" v-slot="props">
                        {{ props.row.type }}
                    </b-table-column>

                    <b-table-column field="province" label="Provincia" v-slot="props">
                        {{ props.row.province }}
                    </b-table-column>

                    <b-table-column field="phone" label="Teléfono" v-slot="props">
                        {{ props.row.phone }}
                    </b-table-column>

                    <b-table-column label="" centered v-slot="props" v-if="this.$hasPermission('delegaciones.editar')">
                        <b-button
                            tag="router-link"
                            :to="`/delegaciones/editar/${props.row.id}`" 
                            icon-pack="fas"
                            icon-left="pencil-alt">
                        </b-button>                        
                    </b-table-column>
                    
                    <template #empty>
                        <div class="has-text-centered">No se encontraron resultados</div>
                    </template>
                </b-table>
            </div>
        </div>
    </div>   
</template>

<script>
    import Search from './Search.vue';
    export default {
        name:'ContactsTable',
        data() {
            return {
                data: [],
                total: 0,
                order: 'desc',
                page: 1,
                perPage: 5,
                loading: false,
                isEmpty: false,
                search:'', 
            }
        },
        components:{
            Search
        },
        methods:{
            async fetchDelegations(){
                try {
                    this.loading = true;
                    const query = await this.$repositories.delegations.index(this.page, this.search);
                    this.data = [];
                    this.total = query.data.delegations.total; 
                    if(query.data.delegations.data.length > 0){
                        query.data.delegations.data.forEach(item => {
                            const ITEMS = {
                                id: item.id,
                                name: item.name,
                                type: item.type,
                                type: item.type,
                                city: item.city,
                                province: item.province,
                                address: item.address,
                                phone: item.phone,
                                whatsapp: item.whatsapp,
                                email: item.email,
                                horary_morning_start: item.horary_morning_start,
                                horary_morning_end: item.horary_morning_end,
                                horary_afternoon_start: item.horary_afternoon_start,
                                horary_afternoon_end: item.horary_afternoon_end,
                            }                     
                            this.data.push(ITEMS);
                            this.loading = false
                        });
                    }
                    this.loading = false;
                    
                } catch (error) {
                    this.loading = false
                    console.log(error);
                }
            },
            onPageChange(page) {
                this.page = page
                this.fetchDelegations()
            },
            toggle(row) {
                this.$refs.table.toggleDetails(row)
            },
            getValue(value){
                this.search = value;
                this.loading = true;
                this.fetchDelegations();
            },
        },
        created(){
            this.fetchDelegations();
        }
    }
</script>