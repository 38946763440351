export default (axios, baseURL) => {
    return{
        //
        create(data){
            
            return axios.post(`${baseURL}/api/users`, 
                data
            );
        },
    }
}