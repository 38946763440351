<template>
  <div class="my-div" style="max-height: 400px; overflow-y: auto">
    <section class="section">
      <div class="container">
        <!-- Campo de entrada para la vigencia del plan -->
        <div class="field">
          <label class="label">Vigencia del Plan</label>
          <div class="control">
            <b-input size="sm" type="text" v-model="vigenciaPlan" style="max-width: 700px"></b-input>
          </div>
        </div>

        <!-- Botón de enviar el formulario -->
        <b-button @click="submitForm" v-if="$hasPermission('cuotasyminimos.editar')">Enviar</b-button>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { alertSuccess, alertFailed, alertInfo } from "@/helpers";

export default {
  data() {
    return {
      vigenciaPlan: '' // Variable para almacenar la vigencia del plan
    };
  },
  methods: {
    // Método para enviar el formulario
    submitForm() {
      const formData = {
         key: 'vigencia_plan', // La clave (key) para la configuración general
        value: this.vigenciaPlan // El valor correspondiente a la vigencia del plan
      };



      // Enviar la solicitud al backend
      axios.post(process.env.VUE_APP_ROOT_API+"/api/general-settings", formData)
        .then(response => {
          // Manejar la respuesta si es necesario
          alertSuccess('Datos guardados exitosamente');
        })
        .catch(error => {
          // Manejar el error si ocurre
          alertFailed('Error al guardar los datos');
          console.error('Error al guardar los datos:', error);
        });
    },
  
   
    getData() {
   
      axios.get(process.env.VUE_APP_ROOT_API+"/api/general-settings/vigencia_plan")
        .then(response => {
          // Asignar la vigencia del plan a la variable de datos
          this.vigenciaPlan = response.data.value;
        })
        .catch(error => {
          console.error('Error al obtener la vigencia del plan:', error);
        });
    }
  },
  mounted() {
   
    this.getData();
  },
};
</script>


<style>
#label {
  padding-top: 2rem;
}
</style>
