<template>
  <aside id="sidebar">
    <!--Brand-->
    <router-link :to="'/'">
      <div class="p-1">
        <img src="/img/osfatunalt-30.svg" alt="Logo osfatun" width="70px" />
      </div>
    </router-link>
    <!--Menu List-->
    <ul>
      <li  v-if="hasPermission('consultas.ver')">
        <b-tooltip
          label="Consultas"
          type="is-dark"
          position="is-right"
          :square="true"
        >
          <router-link :to="'/consultas'">
            <b-icon
              pack="fas"
              icon="comments"
              size="is-medium"
              :type="$route.path == '/consultas' ? 'is-primary' : 'is-dark'"
            >
            </b-icon>
          </router-link>
        </b-tooltip>
      </li>
       <li  v-if="hasPermission('delegaciones.ver')">
        <b-tooltip
          label="Delegaciones"
          type="is-dark"
          position="is-right"
          :square="true"
        >
          <router-link :to="'/delegaciones'">
            <b-icon
              pack="fas"
              icon="map-marker-alt"
              size="is-medium"
              type="is-dark"
            >
            </b-icon>
          </router-link>
        </b-tooltip>
      </li>


    <li  v-if="hasPermission('ventas.ver')">
        <b-tooltip
          label="Ventas"
          type="is-dark"
          position="is-right"
          :square="true"
        >
          <router-link :to="'/ventas'">
            <b-icon
              pack="fas"
              icon="user-plus"
              size="is-medium"
              :type="$route.path == '/ventas' ? 'is-primary' : 'is-dark'"
            >
            </b-icon>
          </router-link>
        </b-tooltip>
      </li>

    <li  v-if="hasPermission('prestadores.ver')">
        <b-tooltip
          label="Prestadores"
          type="is-dark"
          position="is-right"
          :square="true"
        >
          <router-link :to="'/prestadores'">
            <b-icon pack="fas" icon="user-md" size="is-medium" type="is-dark">
            </b-icon>
          </router-link>
        </b-tooltip>
      </li>
      <li  v-if="hasPermission('contactos.ver')">
        <b-tooltip
          label="Contactos"
          type="is-dark"
          position="is-right"
          :square="true"
        >
          <router-link :to="'/contactos'">
            <b-icon pack="fas" icon="user" size="is-medium" type="is-dark">
            </b-icon>
          </router-link>
        </b-tooltip>
      </li>
      <li  v-if="hasPermission('agentes.ver')">
        <b-tooltip
          label="Agentes"
          type="is-dark"
          position="is-right"
          :square="true"
        >
          <router-link :to="'/agentes'">
            <b-icon
              pack="fas"
              icon="user-tie"
              size="is-medium"
              :type="$route.path == '/agentes' ? 'is-primary' : 'is-dark'"
            >
            </b-icon>
          </router-link>
        </b-tooltip>
      </li>
      <li  v-if="hasPermission('departamentos.ver')">
        <b-tooltip
          label="Departamentos"
          type="is-dark"
          position="is-right"
          :square="true"
        >
          <router-link :to="'/departamentos'">
            <b-icon
              pack="fas"
              icon="network-wired"
              size="is-medium"
              :type="$route.path == '/departamentos' ? 'is-primary' : 'is-dark'"
            >
            </b-icon>
          </router-link>
        </b-tooltip>
      </li>

      <li  v-if="hasPermission('cuotasyminimos.ver')">
        <b-tooltip
          label="Cuotas y minimos"
          type="is-dark"
          position="is-right"
          :square="true"
        >
          <router-link :to="'/capita'">
            <b-icon
              pack="fas"
              icon="fa-solid fa-calculator"
              size="is-medium"
              :type="$route.path == '/capitas' ? 'is-primary' : 'is-dark'"
            >
            </b-icon>
          </router-link>
        </b-tooltip>
      </li>

      <!--
      <li>
        <a
          role="button"
          @click.prevent="$store.commit('setNotifications', true)"
        >
          <b-icon
            class="notifications"
            :class="unread ? 'unread' : ''"
            pack="fas"
            icon="bell"
            size="is-medium"
            type="is-dark"
          >
          </b-icon>
        </a>
      </li>
      -->
    </ul>
    <!--User Account-->
    <b-dropdown append-to-body aria-role="menu" close-on-click>
      <template #trigger>
        <a role="button">
          <span>
            <figure class="image is-48x48">
              <img
                class="is-rounded"
                :src="`${
                  $store.getters.getSession.avatar
                    ? $store.getters.getSession.avatar
                    : $avatarDefault
                }`"
                style="width: 100%; height: 100%; object-fit: cover"
              />
            </figure>
          </span>
        </a>
      </template>
      <b-dropdown-item custom aria-role="menuitem" v-if="this.$hasPermission('agentes.editarmiperfil')">
        <router-link
          :to="`/agentes/editar/${$store.getters.getSession.id}`"
          class="is-uppercase has-text-weight-bold"
        >
          Mi Perfil
        </router-link>
      </b-dropdown-item>
      <hr class="dropdown-divider" />

      <b-dropdown-item custom aria-role="menuitem">
        <a
          href="#"
          @click.prevent="logout"
          class="is-uppercase has-text-weight-bold"
        >
          Cerrar Sesión
        </a>
      </b-dropdown-item>
    </b-dropdown>
    <!--Brand-->
    <router-link :to="'/'">
      <div class="p-1">
        <img
          src="/img/logo_zaro.png"
          alt="Logo zaro"
          style="height: 100%; object-fit: cover"
        />
      </div>
    </router-link>

  </aside>
</template>

<script>
export default {
  name: "Sidebar",

  methods: {
    hasPermission(permissionToCheck) {
      const userPermissions = this.$store.getters["getSession"]["permissions"];
      return userPermissions && userPermissions.includes(permissionToCheck);
    },

    logout() {
      localStorage.clear();
      sessionStorage.clear();
      this.$store.commit("setAuth", false);
      this.$store.commit("setSession", {});
      this.$router.push("/login");
    },
  },
  computed: {
    unread() {
      return this.$store.getters.getUnreadNotifications;
    },
  },
};
</script>

<style lang="scss" scoped>
aside {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 75px;
  height: 100%;
  background-color: $white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 9;

  ul {
    li {
      margin-bottom: 1.2rem;
      a:hover span {
        color: $primary !important;
      }
      .notifications {
        position: relative;
        &.unread {
          &::before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: $info;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }
  div {
    div {
      a {
        span {
          figure {
            img {
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
</style>
