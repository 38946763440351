<template>
    <b-modal
        v-model="newSale"
        has-modal-card
        :can-cancel="false">
        <Loader :active="loading"></Loader>
        
        <form @submit.prevent="create">
            <div class="modal-card" style="width:350">
                <header class="modal-card-head">
                    <p class="modal-card-title is-uppercase has-text-weight-bold has-text-grey-light">Crear Lead</p>
                    <button
                        type="button"
                        class="delete"
                        @click="$store.dispatch('setNewSale', false)"
                    />
                </header>
                <div class="modal-card-body">
                    <b-field label="Nombre y Apellido*">
                        <b-input v-model="form.fullname" required></b-input>
                    </b-field>

                    <b-field label="CUIL">
                        <b-input maxlength="11" v-model="form.cuil"></b-input>
                    </b-field>

                    <b-field label="Edad">
                        <b-input type="number" v-model="form.age"></b-input>
                    </b-field>

                    <b-field label="Nacionalidad">
                        <b-input v-model="form.nacionality"></b-input>
                    </b-field>

                    <b-field label="Nacimiento">
                        <b-input type="date" v-model="form.date_birth"></b-input>
                    </b-field>

                    <b-field label="Estado Civil">
                        <b-select expanded placeholder="Seleccionar" v-model="form.civil_status">
                            <option value="Casado">Casado</option>
                            <option value="Soltero">Soltero</option>
                            <option value="Divorciado">Divorciado</option>
                        </b-select>
                    </b-field>

                    <b-field
                        label="Provincia*">
                        <b-select 
                            @input="getCities()"
                            v-model="form.province"
                            placeholder="Seleccionar"
                            expanded
                            required>
                            <option
                                v-for="(province, index) in provinces.data"
                                :value="province"
                                :key="index">
                                {{ province }}
                            </option>
                        </b-select>
                    </b-field>
                    
                    <b-field
                        label="Ciudad*">
                        <b-select 
                            v-model="form.city"
                            placeholder="Seleccionar"
                            expanded
                            required
                            :disabled="cities.disabled"
                            :loading="cities.loading">
                            <option
                                v-for="(city, index) in cities.data"
                                :value="city"
                                :key="index">
                                {{ city }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field label="Dirección">
                        <b-input expanded v-model="form.address"></b-input>
                    </b-field>

                    <b-field label="Departamento/Unidad">
                        <b-input v-model="form.departament"></b-input>
                    </b-field>

                    <b-field grouped label="Teléfono fijo"> 
                        <b-input 
                            style="width:75px;"
                            v-model="form.characteristic_phone"
                            type="text"
                            pattern="[0-9]*"
                            maxlength="4">
                        </b-input>

                        <b-input
                            expanded
                            v-model="form.phone"
                            type="text"
                            pattern="[0-9]*">
                        </b-input>    
                    </b-field> 

                    <b-field grouped label="Celular*"> 
                        <b-field>
                            <p class="control">
                                <span class="button is-static">0</span>
                            </p>
                            <b-input 
                                style="width:75px;"
                                v-model="form.characteristic_celphone"
                                type="text"
                                pattern="[0-9]*"
                                maxlength="4"
                                required>
                            </b-input>
                        </b-field>
                        <b-field expanded>
                            <p class="control">
                                <span class="button is-static">15</span>
                            </p>
                            <b-input
                                expanded
                                v-model="form.celphone"
                                type="text"
                                pattern="[0-9]*"
                                required>
                            </b-input>
                        </b-field>    
                    </b-field> 

                    <b-field grouped label="Teléfono Laboral"> 
                        <b-input 
                            style="width:75px;"
                            v-model="form.characteristic_work_phone"
                            type="text"
                            pattern="[0-9]*"
                            maxlength="4">
                        </b-input>

                        <b-input
                            expanded
                            v-model="form.work_phone"
                            type="text"
                            pattern="[0-9]*">
                        </b-input>    
                    </b-field> 

                    <b-field label="Email*">
                        <b-input type="email" expanded v-model="form.email"></b-input>
                    </b-field>

                    <b-field label="Email Laboral">
                        <b-input type="email" v-model="form.work_email"></b-input>
                    </b-field>

                    <b-field label="Trabajo">
                        <b-select expanded placeholder="Seleccionar" v-model="form.work">
                            <option value="Relación de dependencia">Relación de dependencia</option>
                            <option value="Monotributo">Monotributo</option>
                            <option value="Particular">Particular (prepago)</option>
                        </b-select>
                    </b-field>
                    <b-field label="Agente" v-if="!showAgentField">
                        <multiselect
                        v-model="assign"
                        :multiple="false"
                        :options="agents.data"
                        group-values="users"
                        group-label="name"
                        :group-select="false"
                        select-label=""
                        deselect-label="Click para remover"
                        placeholder="Seleccionar"
                        track-by="name"
                        label="name"
                        >
                        <span slot="noResult">No se encontraron resultados.</span>
                        </multiselect>
                    </b-field>
                    <b-field
                        label="Procedencia"
                        message="Seleccionar la procedencia"
                        v-if="!showAgentField">
                        <b-select 
                            v-model="form.inherited_origin"
                            placeholder="Seleccionar"
                            expanded
                            :disabled="origins.disabled">
                            <option
                                v-for="(item, index) in origins"
                                :value=" item"
                                :key="index">
                                {{ item[14].toUpperCase()+item.substring(15) }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field label="Observación" expanded>
                      <b-input
                        type="textarea"
                        v-model="form.observation"
                      ></b-input>
                    </b-field>
                </div>

                <div class="modal-card-foot">
                    <b-button
                        native-type="submit"
                        type="is-primary"
                        expanded>
                        Guardar
                    </b-button>
                </div>
            </div>
        </form>
    </b-modal>
</template>

<script>
    import Multiselect from "vue-multiselect";
    import Loader from './Loader.vue';
    import { mapState, mapActions } from 'vuex';
    import {alertSuccess, alertFailed, provinces, cities} from "@/helpers";
    export default {
        name:'Modal',
        components:{
            Loader,
            Multiselect
        },
        data(){
            return{
                loading: false,
                provinces:{
                    disabled: false,
                    data: []
                },
                cities:{
                    disabled: true,
                    laoding: false,
                    data: []
                },
                assign:{
                    id: '',
                    name:''
                },
                agents: {
                    disabled: true,
                    data: [],
                },
                origins: [],
                form:{
                    type:'potencial afiliado',
                    origin: 'crm-ventas-referido',
                    fullname: '',
                    cuil:'',
                    age:'',
                    nacionality:'',
                    date_birth:'',
                    civil_status:'',
                    province:'',
                    city:'',
                    address:'',
                    departament:'',
                    characteristic_phone:'',
                    phone:'',
                    characteristic_celphone:'',
                    celphone:'',
                    characteristic_work_phone:'',
                    work_phone:'',
                    email:'',
                    work_email:'',
                    work:'',
                    observation: '',
                    inherited_origin: ''
                }, 
            }
        },
        computed:{
            ...mapState(['newSale'])  ,
            showAgentField() {
            return this.$store.getters["getSession"]["departament_id"] != '1';
         }             
        },
        methods:{
            ...mapActions(['setNewSale']),
            async create(){
                try {
                    this.loading = true;
                    const contact = await this.createContact();
                    await this.createLead(contact.data.contact.id);
                    this.loading = false;
                    this.$store.dispatch('setNewSale', false);
                    this.$EventBus.$emit('SaleUpdate');
                    alertSuccess('Registro exitoso!');
                } catch (error) {
                    console.log(error);
                    alertFailed('Ha ocurrido un error!');
                }
            },
            async createContact(){
                try {
                    const data = new FormData();
                    for(const [key, value] of Object.entries(this.form)){
                        data.append(key, value);
                    }
                    return await this.$repositories.contacts.create(data);

                } catch (error) {
                    console.log(error);
                }
            },
            async createLead(contact){
                try {
                    const data = new FormData();
                    data.append('inherited_origin', this.form.inherited_origin);
                    data.append('contact', contact);
                    data.append('observation', this.form.observation);
                    if (this.$store.getters["getSession"]["departament_id"]!= '1'){
                        data.append('origin','programa-referidos');
                        data.append('user_id', this.$store.getters["getSession"]["id"]);
                    }else{
                      data.append('origin', this.form.origin);
                      data.append('user_id', this.assign.id);  
                    }
                    
                    data.append('ticket', 0);
                    await this.$repositories.sales.create(data);
                } catch (error) {
                    this.loading = false;
                }
            },
            getProvinces(){
                this.provinces.data = provinces();
            },
            getCities(){
                this.cities.data = cities(this.form.province);
                this.cities.disabled = false;
            },

            async fetchOrigins(){
                try {
                    this.origins = [];
                    const query = await this.$repositories.sales.originsInherited();
                    this.origins = query.data.origins;
                } catch (error) {
                    console.log(error);
                }
            },
            async fetchWithSupervisor() {
                try {
                    const query = await this.$repositories.agents.withSupervisor();
                    if (query.data.users.length > 0) {
                        for (let i = 0; query.data.users.length > 0; i++) {
                            this.agents.data.push({
                                name: query.data.users[i]?.name,
                                users: [],
                            });
                            query.data.users[i].users.forEach((user) => {
                                this.agents.data[i].users.push({
                                    id: user.id,
                                    name:
                                    user.role_id == 1
                                        ? `Supervisor - ${user?.name} ${user?.lastname}`
                                        : `Vendedor - ${user?.name} ${user?.lastname}`,
                                });
                            });
                        }
                    }
                } catch (error) {
                    console.log("Catch error fetchWithSupervisor",error);
                }
            },
            async fetchWithMarketerSupervisor(id) {
                try {
                    const query = await this.$repositories.agents.withMarketerSupervisor(id);
                    if (query.data.users.length > 0) {
                        query.data.users.forEach((item) => {
                            this.agents.data.push(item);
                        });
                    }
                } catch (error) {
                    console.log("Catch Error fetchWithMarketerSupervisor: ",error);
                }
            }
        },
        
        created(){
            
            this.getProvinces();
            this.fetchOrigins();
            if (
                ((this.$store.getters.getSession.role_id == 1) ||
                    (this.$store.getters.getSession.role_id == 2)) &&
                        (this.$store.getters.getSession.departament_marketer == true)
            ) {
                this.fetchWithMarketerSupervisor(
                    this.$store.getters.getSession.departament_id
                );
            } else if (this.$store.getters.getSession.role_id == 1) {
                this.fetchWithSupervisor();
            }
            
        }
    }
</script>
