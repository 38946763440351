<template>    
    <div class="columns">
        <div class="column is-12 is-3-desktop"
            v-for="(column, index) in columns"
            :key="index">
            <div class="column-title">
                <h1 class="title has-text-grey-light is-uppercase">{{ column.title }}</h1>
                <b-icon
                    class="has-text-grey-lighter"
                    pack="fas"
                    icon="info-circle"
                    size="is-small">
                </b-icon>
            </div>
            <!--
            <Draggable
                class="column-content"
                :list="column.tickets"
                ghost-class="ghost-card"
                group="tickets"
                @sort="checkMove"
                @start="drag=true" 
                @end="drag=false"
                :animation="200">
            </Draggable>
            -->
            <div class="column-content">
                <div class="columns"
                    v-for="sale in column.cards"
                    :key="sale.id">
                    <div class="column">
                        <Card
                            :sale="sale">
                        </Card>
                        <button @click="removeSale(sale.id)" class="delete" aria-label="close"></button>
                        <b-button
                            v-if="index!=0 && index!=1 && index!=2 && index!=3"
                            @click="setStage(sale.id, sale.stage_prev)"
                            size="is-small"
                            class="is-circle left floating"
                            type="is-grey"
                            icon-pack="fas"
                            icon-left="chevron-left">
                        </b-button>
                        <b-button
                            v-if="index!=0 && index!=1 && index!=2 && index!=3"
                            @click="setStage(sale.id, sale.stage_next)"
                            size="is-small"
                            class="is-circle right floating"
                            type="is-grey"
                            icon-pack="fas"
                            icon-left="chevron-right">
                        </b-button>
                    </div>
                </div>
            </div>
            <b-button
                v-if="index==0"
                @click="$store.commit('setNewSale', true)"
                class="is-uppercase"
                icon-pack="fas"
                icon-left="plus" 
                type="is-light"
                expanded>
                Crear nueva
            </b-button>
        </div>
        <Modal/>
    </div>
</template>

<script>
    //import Draggable from 'vuedraggable';
    import Card from './Sale-card.vue';
    import Modal from './Sale-modal-ticket.vue';
    import {alertSuccess, alertFailed} from '@/helpers';
    export default {
        name: 'SalesBoard',
        components:{
            //Draggable,
            Card,
            Modal
        },
        props: {
            columns: {
                type: Array,
                default: () => ({})
            }
        },
        methods:{
            checkMove: function(evt){
                console.log(evt)
            },
            removeSale(id){
                this.$buefy.dialog.confirm({
                    title: 'Eliminar Lead',
                    message: '¿Esta seguro?',
                    cancelText: 'Cancelar',	
                    onConfirm: () => this.delete(id)
                })
            },
            async delete(id){
                try {
                    this.loading = true;
                    await this.$repositories.sales.delete(id);
                    this.$EventBus.$emit('SaleUpdate'); 
                    alertSuccess('Registro exitoso!');  
                } catch (error) {
                    if(error.response.status == 401){
                        alertFailed('No puede realizar esta acción!');
                    }
                }
            },
            async setStage(id, stage){
                try {
                    this.loading = true;
                    const data = new FormData();
                    data.append('stage', stage);
                    await this.$repositories.sales.stage(id, data);
                    this.$EventBus.$emit('SaleUpdate');   
                } catch (error) {
                    console.log(error);
                }
            },
        }
    }
</script>

<style lang="scss" scoped>  
    .columns{
        .column{
            position: relative;
            .column-title{
                display: flex;
                align-items: center;
                margin-bottom: 1rem;
                h1{
                    margin-bottom: 0;
                    margin-right: 1rem;
                }
            }  
            .column-content{
                min-height: 150px;
                max-height: calc(100vh - 250px);
                overflow-x: auto;
                background-color: $white;
                padding: 1rem;
                .card:not(:last-child){
                        margin-bottom: 1rem;
                }
                button{
                    &.delete{
                        position: absolute;
                        top: 0;
                        right: 0;
                        margin: .25rem;
                    }                    
                    &.floating{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        bottom: 0;
                        box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
                        opacity: 0;
                        transition: .2s;
                    }
                    &.is-circle{
                        border-radius: 50%!important;
                    }
                    &.left{
                        left: 0;
                    }
                    &.right{
                        right: 0;
                    }
                }  
            } 
            .column-content:hover{
                button{
                    opacity: 1;   
                }
            }
        }
    }
    .ghost-card {
        opacity: 0.5;
        background: $white;
        border: 1px solid $primary;
    }
</style>
