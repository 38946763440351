export default (axios, baseURL) => {
    return{
        index(page, searchText,selectDataType, user, departament){
            return axios.get(
              `${baseURL}/api/tickets?type=${selectDataType}&search=${searchText}&page=${page}&user=${user}&departament=${departament}`
            );
        },
        indexHome(){
            return axios.get(
              `${baseURL}/api/tickets_home`);
        },
        show(id){
            return axios.get(`${baseURL}/api/tickets/${id}`);
        },
        create(data){         
            return axios.post(`${baseURL}/api/tickets`, 
                data
            );
        },
        delegate(id, data){
            return axios.post(`${baseURL}/api/tickets/${id}/delegate`,
                data
            );
        },
        urgent(id, data){
            return axios.post(`${baseURL}/api/tickets/${id}/urgent`,
                data
            );
        },
        message(id, data){
            return axios.post(`${baseURL}/api/tickets/message/${id}`, 
                data
            );
        },
        open(id){
            return axios.post(`${baseURL}/api/tickets/open/${id}`);
        },
        close(id){
            return axios.post(`${baseURL}/api/tickets/close/${id}`);
        },
        origins(){
            return axios.get(`${baseURL}/api/tickets/origins/all`);
        },
        timeline(id){
            return axios.get(`${baseURL}/api/tickets/${id}/timeline`);
        },
        client(token){
            return axios.get(`${baseURL}/api/tickets/client/${token}`);
        },
        sendMessage(to, message) {
            const data = {
                to: to,
                message: message
            };
            return axios.post(`${baseURL}/api/send-message`, data);
        },
        sendMessageTemplate(to, name) {
            const data = {
                to: to,
                name: name
            };
            return axios.post(`${baseURL}/api/send-template`, data);
        }
    }
}