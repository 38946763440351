<template>
  <div id="contacts-table">
    <div class="card">
      <div class="card-content">
        <b-field grouped>
          <!-- Otros campos y filtros -->

          <input
             type="file"
            ref="fileInput"
             
           />

          <b-button   
            native-type="submit"
            type="is-primary"
            icon-pack="fas"
            class="ml-2 mr-2"
          :disabled="isSaving"
            @click="enviarArchivoCSV"
          >
          Importar Contactos
          </b-button>
        
        </b-field>
        <b-field grouped>
          <Search @value="getValue" />
          <p class="control">
            <b-field>
              <b-select
                v-model="contacts.origin"
                placeholder="Seleccionar procedencia"
                expanded
                @input="getOrigin"
              >
                <option value=""></option>
                <option
                  v-for="(item, index) in origins.data"
                  :value="item"
                  :key="index"
                >
                  {{ item }}
                </option>
              </b-select>
            </b-field>
          </p>
          <p class="control">
            <b-field>
              <b-select
                v-model="contacts.departament"
                placeholder="Seleccionar departamento"
                expanded
                :disabled="departaments.disabled"
                @input="getDepartament"
              >
                <option value=""></option>
                <option
                  v-for="(item, index) in departaments.data"
                  :value="item.id"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
          </p>
          <p class="control">
            <b-datepicker
              v-model="contacts.startDate"
              @input="getDates"
              placeholder="Fecha de inicio"
            ></b-datepicker>
          </p>
          <p class="control">
            <b-datepicker
              v-model="contacts.endDate"
              @input="getDates"
              placeholder="Fecha de fin"
            ></b-datepicker>
          </p>
          <p class="control">
            <b-button @click="exportToExcel" type="is-primary">
              Exportar a Excel
            </b-button>
          </p>
        </b-field>
        <b-table
          hoverable
          paginated
          pagination-simple
          backend-pagination
          @page-change="onPageChange"
          :scrollable="true"
          :sticky-header="true"
          :total="contacts.total"
          :per-page="contacts.perPage"
          height="350"
          :loading="contacts.loading"
          :data="contacts.isEmpty ? [] : contacts.data"
        >
          <b-table-column field="name" label="Nombre" v-slot="props">
            {{ props.row.fullname }}
          </b-table-column>
          <b-table-column field="address" label="Dirección" v-slot="props">
            {{ props.row.address }}
          </b-table-column>
          <b-table-column field="phone" label="Teléfono" v-slot="props">
            {{ props.row.phone }}
          </b-table-column>
          <b-table-column field="origin" label="Procedencia" v-slot="props">
            <span class="tag is-uppercase has-text-weight-bold">
              {{ props.row.origin }}
            </span>
          </b-table-column>
          <b-table-column field="date" label="Fecha" v-slot="props">
            <span class="tag is-uppercase has-text-weight-bold">
              {{ props.row.date }}
            </span>
          </b-table-column>
          <b-table-column
            label=""
            centered
            v-slot="props"
            v-if="this.$hasPermission('contactos.editar')"
          >
            <b-button
              tag="router-link"
              :to="`/contacts/editar/${props.row.id}`"
              icon-pack="fas"
              icon-left="pencil-alt"
            ></b-button>
          </b-table-column>
          <template #empty>
            <div class="has-text-centered">No se encontraron resultados</div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "./Search.vue";
import * as XLSX from "xlsx";
import { alertSuccess, alertFailed } from "@/helpers";

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export default {
  name: "ContactsTable",
  data() {
    return {
      isSaving: false,
      baseURL: process.env.VUE_APP_ROOT_API,
      contacts: {
        data: [],
        allData: [],
        total: 0,
        order: "desc",
        page: 1,
        perPage: 5,
        loading: false,
        isEmpty: false,
        search: "",
        origin: "",
        departament: "",
        startDate: null,
        endDate: null,
      },
      departaments: {
        disabled: true,
        data: [],
      },
      origins: {
        data: ["crm", "cotizador", "sorteo", "web"],
      },
    };
  },
  components: {
    Search,
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },

   enviarArchivoCSV() {
      const file = this.$refs.fileInput.files[0];
      if (!file) {
        alert('Por favor, seleccione un archivo.');
        return;
      }

      this.isSaving = true;

      const formData = new FormData();
      formData.append('file', file);

      
      fetch(`${this.baseURL}/api/import-contacts`, {
        method: 'POST',
        body: formData
      })
      
        .then(data => {
          console.log('Archivo enviado con éxito:', data);
          //this.isSaving = false;
          alertSuccess("Se Importaron los registros!");
          this.isSaving = false;
        })
        .catch(error => {
          console.error('Error al enviar el archivo:', error);
          this.isSaving = false;
          this.modalImpoetarCSV = false;
           alertFailed("Algo salio mal!");
           this.isSaving = false;
        });
    },

    async fetchContacts() {
      try {
        this.contacts.loading = true;
        const query = await this.$repositories.contacts.index(
          this.contacts.page,
          this.contacts.search,
          this.contacts.departament,
          this.contacts.origin,
          this.contacts.startDate,
          this.contacts.endDate
        );
        this.contacts.data = [];
        this.contacts.total = query.data.contacts.total;
        if (query.data.contacts.data.length > 0) {
          this.contacts.data = query.data.contacts.data.map((item) => ({
            id: item.id,
            fullname: item.fullname,
            address: item.address,
            phone: item.phone,
            origin: item.origin,
            date: formatDate(item.updated_at),
          }));
        }
        this.contacts.loading = false;
      } catch (error) {
        this.contacts.loading = false;
        console.log(error);
      }
    },
    async fetchDepartaments() {
      try {
        this.departaments.data = [];
        const query = await this.$repositories.departaments.list();
        this.departaments.data = query.data.departaments.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        this.departaments.disabled = false;
      } catch (error) {
        console.log(error);
      }
    },
    onPageChange(page) {
      this.contacts.page = page;
      this.fetchContacts();
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    getValue(value) {
      this.contacts.search = value;
      this.contacts.loading = true;
      this.fetchContacts();
    },
    getOrigin() {
      this.contacts.loading = true;
      this.fetchContacts();
    },
    getDepartament() {
      this.contacts.loading = true;
      this.fetchContacts();
    },
    getDates() {
      this.contacts.loading = true;
      this.fetchContacts();
    },
    async exportToExcel() {
      try {
        const response = await this.$repositories.contacts.getAll(
          this.contacts.search,
          this.contacts.departament,
          this.contacts.origin,
          this.contacts.startDate,
          this.contacts.endDate
        );

        const allData = response.data.contacts;
        const ws = XLSX.utils.json_to_sheet(
          allData.map((contact) => ({
            ID: contact.id,
            Tipo: contact.type,
            "Nombre Completo": contact.fullname,
            "Correo Electrónico": contact.email,
            "Características del Teléfono": contact.characteristic_phone,
            Teléfono: contact.phone,
            "Características del Celular": contact.characteristic_celphone,
            Celular: contact.celphone,
            Provincia: contact.province,
            Ciudad: contact.city,
            Dirección: contact.address,
            CUIL: contact.cuil,
            Edad: contact.age,
            Nacionalidad: contact.nacionality,
            "Fecha de Nacimiento": contact.date_birth,
            "Estado Civil": contact.civil_status,
            Departamento: contact.departament,
            "Características del Teléfono de Trabajo":
              contact.characteristic_work_phone,
            "Teléfono de Trabajo": contact.work_phone,
            "Correo Electrónico de Trabajo": contact.work_email,
            Trabajo: contact.work,
            "No Docente": contact.not_teacher,
            Peso: contact.weight,
            Altura: contact.height,
            IMC: contact.imc,
            FUM: contact.fum,
            Procedencia: contact.origin,
            "Cuenta Social": contact.social_account,
            Cuenta: contact.account,
            "Contactos Extra": contact.extra_contacts,
            Cobertura: contact.coverage,
            "Nombre de Usuario": contact.username,
            "Fecha de Actualización": formatDate(contact.updated_at),
          }))
        );

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Contactos");

        XLSX.writeFile(wb, "contactos.xlsx");
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.fetchContacts();
    this.fetchDepartaments();
  },
};
</script>
