<template>
  <div id="ticket">
    <Loader :active="loading" :full="false"></Loader>
    <div class="ticket-content" v-if="toggle & !loading">
      <div class="columns">
        <div class="column">
          <div class="level">
            <div class="level-left is-block">
              <div class="level-item is-justify-content-flex-start mb-2">
                <h4 class="title is-4 has-text-primary is-uppercase">
                  Consulta número
                </h4>
                <h4 class="title is-4 has-text-dark ml-3">
                  #{{ ticketId(ticket.id) }}
                </h4>
              </div>
              <div class="level">
                <div class="level-left">
                  <div class="level-item">
                    <b-tooltip
                      label="Procedencia"
                      type="is-dark"
                      position="is-top"
                      :square="true"
                    >
                      <div class="tags has-addons">
                        <span
                          class="tag is-uppercase has-text-weight-bold"
                          :type="ticket.origin.type"
                          >{{ ticket.origin.name }}</span
                        >
                        <span class="tag" :type="ticket.origin.type">
                          <b-icon
                            :pack="ticket.origin.pack"
                            :icon="ticket.origin.icon"
                            size="is-small"
                          >
                          </b-icon>
                        </span>
                      </div>
                    </b-tooltip>
                  </div>
                  <div class="level-item">
                    <span class="has-text-grey">|</span>
                  </div>

                  <div
                    v-for="(user, index) of ticket.delegated"
                    :key="index"
                    class="level-item"
                  >
                    <b-tooltip
                      :label="`Delegado a: ${user.name}`"
                      type="is-dark"
                      position="is-top"
                      :square="true"
                    >
                      <div class="media">
                        <div class="media-left mr-2">
                          <figure class="image is-24x24 m-0">
                            <img
                              class="is-rounded"
                              style="height: 100%"
                              :src="`${
                                user.avatar ? user.avatar : $avatarDefault
                              }`"
                            />
                          </figure>
                        </div>
                      </div>
                    </b-tooltip>
                  </div>
                </div>
              </div>
              <b-collapse
                :open="false"
                animation="slide"
                position="is-top"
                aria-id="contentIdForA11y1"
              >
                <template #trigger="props">
                  <div class="is-flex">
                    <h4 class="title is-4 has-text-primary mb-0 mr-3">
                      {{ ticket.contact.name }}
                    </h4>
                    <a class="has-text-grey" aria-controls="contentIdForA11y1">
                      {{ !props.open ? "ver más" : "ver menos" }}
                      <b-icon
                        pack="fas"
                        :icon="!props.open ? 'sort-down' : 'sort-up'"
                      ></b-icon>
                    </a>
                  </div>
                </template>
                <p class="is-grey">CUIL/DNI: {{ ticket.contact.dni }}</p>
                <p class="is-grey">Email: {{ ticket.contact.email }}</p>
                <p class="is-grey">Tel: {{ ticket.contact.phone }}</p>
                <p class="is-grey">
                  Localidad:
                  {{ `${ticket.contact.city}, ${ticket.contact.province}` }}
                </p>
              </b-collapse>
            </div>
            <div class="level-right is-block">
              <div class="buttons mb-0">
                <b-tooltip
                  v-if="ticket.statuses.status == 'Abierto'"
                  class="mr-2"
                  label="Urgente"
                  type="is-dark"
                  position="is-top"
                  :square="true"
                >
                  <b-button
                    :class="ticket.is_urgent ? 'active' : ''"
                    @click="!ticket.is_urgent ? urgentTicket() : ''"
                    type="is-danger"
                    icon-pack="fas"
                    icon-left="exclamation-circle"
                  >
                  </b-button>
                </b-tooltip>
                <b-tooltip
                  label="Historial"
                  type="is-dark"
                  position="is-top"
                  :square="true"
                >
                  <b-button
                    @click.prevent="fetchTimeLine()"
                    type="is-primary"
                    icon-pack="fas"
                    icon-left="question-circle"
                  >
                  </b-button>
                </b-tooltip>
              </div>

              <b-tag
                :type="`is-${ticket.color_status}`"
                class="is-uppercase has-text-weight-bold"
              >
                {{ ticket.statuses.status }}
              </b-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="columns" v-if="ticket.delegate.active">
        <div class="column">
          <!-- Delegate Section -->
          <section class="delegate" v-if="ticket.delegate.active">
            <div class="is-flex is-justify-content-space-between">
              <p class="title is-5 is-uppercase has-text-grey-light">
                Delegar consulta
              </p>
              <button
                type="button"
                class="delete"
                @click="
                  (ticket.delegate.active = false),
                    (departaments.data = []),
                    (agents.data = []),
                    (agents.active = false)
                "
              ></button>
            </div>

            <!-- Departaments -->
            <div v-if="departaments.active" class="departaments">
              <b-field
                label="Departamento*"
                message="Seleccionar el departamento donde será delegada la consulta."
              >
                  <multiselect
    v-model="ticket.delegate.departament"
    :options="departaments.data"
    :group-select="false"
    placeholder="Seleccionar"
    track-by="id"
    label="name"
    @select="onDepartamentSelect"
    selectLabel="Presione enter para seleccionar"
    selectedLabel="Seleccionado"
    deselectLabel="Presione Enter para deshabilitar"
  >
    <span slot="noResult">No se encontraron resultados.</span>
  </multiselect>
              </b-field>
            </div>

            <!-- Delegations -->
            <div v-if="delegations.active" class="departaments">
              <b-field
                label="Delegaciones*"
                message="Seleccionar la delegacion."
              >
                <multiselect
                  v-model="ticket.delegate.delegation"
                  :options="delegations.data"
                  :group-select="false"
                  placeholder="Seleccionar"
                  group-values="items"
                  group-label="type"
                  select-label=""
                  deselect-label="Click para remover"
                  track-by="id"
                  label="name"
                  @select="onDelegationSelect"
                  selectLabel="Presione enter para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel="Presione Enter para deshabilitar"
                >
                  <span slot="noResult">No se encontraron resultados.</span>
                </multiselect>
              </b-field>
            </div>

            <!-- Agents -->
            <Loader :active="agents.loading" :full="false"></Loader>
            <div v-if="agents.active" class="agents">
              <label class="label">Agentes*</label>
              <ul class="menu-list ml-0">
                <li v-for="agent in agents.data" :key="agent.id">
                  <b-field>
                    <b-checkbox
                      v-model="ticket.delegate.agents"
                      :native-value="agent.id"
                      :value="true"
                    >
                      {{ agent.name }} - {{ agent.responsability }}
                    </b-checkbox>
                  </b-field>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
      <div class="columns" v-else>
        <div class="column">
          <!-- Message Section -->
          <section class="messages">
            <ul v-chat-scroll>
              <li v-for="(message, index) in ticket.messages" :key="index">
                <!-- Message Received -->
                <div
                  v-if="message.type == 'received' && message.files.length > 0"
                >
                  <div
                    class="control mr-2 mb-2"
                    v-for="(file, index) in message.files"
                    :key="index"
                  >
                    <b-field>
                      <a :href="file.path" download>
                        <b-tag aria-close-label="Close tag">
                          <div class="media is-align-items-center">
                            <div class="media-left">
                              <b-icon
                                pack="fas"
                                :icon="
                                  file.type == 'image/jpg' ||
                                  file.type == 'image/png'
                                    ? 'image'
                                    : 'paperclip'
                                "
                              >
                              </b-icon>
                            </div>
                            <div class="media-content">
                              <p>{{ urlShort(file.path) }}</p>
                            </div>
                          </div>
                        </b-tag>
                      </a>
                    </b-field>
                  </div>
                </div>
                <div
                  v-if="message.type == 'received' && message.content != ''"
                  class="message-box"
                >
                  <!-- Mensaje del asociado -->

                  <b-message>
                    <div class="custom-message-content">
                      <pre
                        style="
                          white-space: pre-line;
                          font-family: GothamRounded, sans-serif;
                          color: black;
                          background-color: #f5f5f5;
                        "
                        >{{ message.content }}</pre
                      >
                      <small class="has-text-grey">{{ message.date }} hs</small>
                    </div>
                  </b-message>

                  <!--
                  <div class="avatar">
                    <figure class="image is-48x48 ml-3">
                      <img
                        class="is-rounded"
                        src="https://bulma.io/images/placeholders/48x48.png"
                      />
                    </figure>
                  </div>
                     -->
                </div>
                <!-- Message Sended -->
                <div
                  v-if="message.type == 'sended' && message.files.length > 0"
                >
                  <div
                    class="control mr-2 mb-2"
                    v-for="(file, index) in message.files"
                    :key="index"
                  >
                    <b-field>
                      <a :href="file.path" download>
                        <b-tag
                          style="margin-left: auto"
                          aria-close-label="Close tag"
                        >
                          <div class="media is-align-items-center">
                            <div class="media-left">
                              <b-icon
                                pack="fas"
                                :icon="
                                  file.type == 'image/jpg' ||
                                  file.type == 'image/png'
                                    ? 'image'
                                    : 'paperclip'
                                "
                              >
                              </b-icon>
                            </div>
                            <div class="media-content">
                              <p>{{ urlShort(file.path) }}</p>
                            </div>
                          </div>
                        </b-tag>
                      </a>
                    </b-field>
                  </div>
                </div>
                <div
                  v-if="message.type == 'sended' && message.content != ''"
                  class="message-box me"
                >
                  <div class="avatar">
                    <figure class="image is-48x48 mr-3">
                      <img
                        class="is-rounded"
                        src="/img/logo_osfatun_sidebar.png"
                      />
                    </figure>
                  </div>
                  <b-message>
                    <p class="has-text-white mb-3">{{ message.content }}</p>
                    <small class="has-text-white is-size-7"
                      >{{ message.date }} hs</small
                    ><br />
                    <small class="has-text-white is-size-7"
                      >Enviado por: {{ message.user }}
                    </small>
                  </b-message>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </div>
      <nav class="navbar">
        <div v-if="ticket.delegate.active">
          <div class="buttons is-centered">
            <b-button type="is-primary" expanded @click="delegateAgent">
              Guardar
            </b-button>
          </div>
        </div>
        <div v-else>
          <div
            class="field has-addons is-flex-wrap-wrap"
            v-if="ticket.answer.files.length > 0"
          >
            <div
              class="control mr-2 mb-2"
              v-for="(file, index) in ticket.answer.files"
              :key="index"
            >
              <b-field>
                <b-tag
                  closable
                  aria-close-label="Close tag"
                  @close="removeFile(index)"
                >
                  <div class="media is-align-items-center">
                    <div class="media-left">
                      <b-icon
                        pack="fas"
                        :icon="
                          file.type == 'image/jpg' || file.type == 'image/png'
                            ? 'image'
                            : 'paperclip'
                        "
                      >
                      </b-icon>
                    </div>
                    <div class="media-content">
                      <p>{{ file.name }}</p>
                    </div>
                  </div>
                </b-tag>
              </b-field>
            </div>
          </div>
          <div
            class="field has-addons"
            v-if="ticket.statuses.status == 'Abierto' && ticket.answer.active"
          >
            <div class="control is-expanded">
              <input
                class="input"
                v-model="ticket.answer.message"
                type="text"
                placeholder="Escribe tu mensaje..."
              />
            </div>
            <div class="control">
              <b-field class="file is-white">
                <b-upload
                  class="file-label"
                  style="opacity: 1"
                  multiple
                  v-model="ticket.answer.files"
                >
                  <span class="button">
                    <b-icon pack="fas" icon="paperclip"> </b-icon>
                  </span>
                </b-upload>
              </b-field>
            </div>
            <div class="control">
              <b-button
                @click="answerTicket"
                icon-pack="fas"
                icon-right="paper-plane"
              >
              </b-button>
            </div>
          </div>
          <div class="buttons is-centered">
            <b-button
              v-if="ticket.statuses.status == 'No atendido'"
              @click="openTicket"
              type="is-warning"
              outlined
            >
              Atender consulta
            </b-button>
            <b-button
              v-if="
                ticket.departament.name == 'Ventas' &&
                ticket.statuses.status == 'Abierto' &&
                !ticket.lead
              "
              @click="createLead"
              type="is-warning"
              outlined
            >
              Crear lead
            </b-button>
            <b-button
              v-if="ticket.statuses.status == 'Cerrado'"
              @click="openTicket"
              type="is-warning"
              outlined
            >
              Reabrir consulta
            </b-button>
            <b-button
              v-if="ticket.statuses.status == 'Abierto'"
              @click="closeTicket"
              type="is-primary"
            >
              Finalizar consulta
            </b-button>
            <b-button
              v-if="ticket.statuses.status == 'Abierto'"
              @click="fetchDepartaments(), (ticket.delegate.active = true)"
              type="is-light"
            >
              Delegar
            </b-button>
          </div>
        </div>
      </nav>
      <TimeLine
        :loading="timeline.loading"
        :empty="timeline.empty"
        :data="timeline.data"
      />
    </div>
  </div>
</template>

<script>
import TimeLine from "./Ticket-timeline.vue";
import { mapState } from "vuex";
import { alertSuccess, alertFailed, alertInfo, fillString } from "@/helpers";
import Loader from "./Loader.vue";
import Multiselect from "vue-multiselect";
export default {
  name: "Ticket",
  components: {
    TimeLine,
    Loader,
    Multiselect,
  },
  data() {
    return {
      loading: false,
      departaments: {
        data: [],
        active: true,
        disabled: false,
        loading: false,
      },
      delegations: {
        data: [],
        active: false,
        disabled: false,
        loading: false,
      },
      agents: {
        data: [],
        active: false,
        loading: false,
      },
      ticket: {
        id: null,
        statuses: {
          status: "",
        },
        delegated: [],
        agent: {
          id: null,
          name: "",
          lastname: "",
          avatar: null,
        },
        departament: {
          id: null,
          name: "",
        },
        contact: {
          id: null,
          name: "",
          lastname: "",
          email: "",
          phone: "",
          dni: "",
          city: "",
          province: "",
        },
        messages: [],
        files: [],
        color_status: "",
        answer: {
          active: true,
          message: "",
          files: [],
        },
        delegate: {
          active: false,
          departament: null,
          delegation: null,
          agents: [],
        },
        is_urgent: false,
        lead: false,
      },
      timeline: {
        loading: false,
        empty: false,
        data: [],
      },
    };
  },
  methods: {
    onDepartamentSelect(selectedDepartament) {
      console.log('departamento'+selectedDepartament.id)
      if (selectedDepartament.id == "10") {
        this.delegations.active = true;
      } else {
        this.delegations.active = false;
      }
      this.ticket.delegate.departament = selectedDepartament.id;
      this.ticket.delegate.agents = [];
      this.fetchAgents(selectedDepartament.id);
    },

    onDelegationSelect(selectedDelegation) {
      this.ticket.delegate.delegation = selectedDelegation.id;
      this.ticket.delegate.agents = [];
      this.fetchAgents(selectedDelegation.id);
    },

    async fetchTicket(id) {
      try {
        //this.loading = true;
        const query = await this.$repositories.tickets.show(id);
        const COLOR_STATUS = {
          "not attended": "dark",
          open: "success",
          closed: "danger",
        };
        const COLOR_PRIORITY = {
          low: "success",
          medium: "warning",
          high: "danger",
        };
        const STATUS = {
          "not attended": "No atendido",
          open: "Abierto",
          closed: "Cerrado",
        };
        const ORIGIN = {
          whatsapp: {
            type: "is-success",
            pack: "fab",
            icon: "whatsapp",
            name: "Whatsapp",
          },
          messenger: {
            type: "is-info",
            pack: "fab",
            icon: "facebook-messenger",
            name: "Messenger",
          },
          instagram: {
            type: "is-light",
            pack: "fab",
            icon: "instagram",
            name: "Instagram",
          },
          web: {
            type: "is-light",
            pack: "fas",
            icon: "globe",
            name: "Web",
          },
          crm: {
            type: "is-light",
            pack: "fas",
            icon: "globe",
            name: "Crm",
          },
          email: {
            type: "is-light",
            pack: "far",
            icon: "envelope",
            name: "Email",
          },
          delegacion: {
            type: "is-light",
            pack: "fas",
            icon: "globe",
            name: "Delegación",
          },
          "call center": {
            type: "is-light",
            pack: "fas",
            icon: "globe",
            name: "Call Center",
          },
          referido: {
            type: "is-light",
            pack: "fas",
            icon: "globe",
            name: "Referido",
          },
        };
        const ticket = query.data.ticket;
        this.ticket.id = ticket.id;
        this.ticket.statuses.status = STATUS[ticket.status];
        this.ticket.is_urgent = ticket.is_urgent;
        this.ticket.contact.id = ticket.contact.id;
        this.ticket.contact.name = ticket.contact.fullname;
        this.ticket.contact.email = ticket.contact.email;
        this.ticket.contact.phone = ticket.contact.phone;
        this.ticket.contact.dni = ticket.contact.cuil;
        this.ticket.contact.city = ticket.contact.city;
        this.ticket.contact.province = ticket.contact.province;
        this.ticket.departament.id = ticket.departament.id;
        this.ticket.departament.name = ticket.departament.name;
        this.ticket.agent.id = ticket.user ? ticket.user.id : undefined;
        this.ticket.agent.name = ticket.user ? ticket.user.name : "";
        this.ticket.agent.lastname = ticket.user ? ticket.user.lastname : "";
        this.ticket.agent.avatar = ticket.user ? ticket.user.avatar : null;
        this.ticket.origin = ORIGIN[ticket.origin];
        this.ticket.color_status = COLOR_STATUS[ticket.status];
        this.ticket.color_priority =
          ticket.status == "closed" ? "dark" : COLOR_PRIORITY[ticket.priority];
        this.ticket.lead = ticket.sale.length > 0 ? true : false;

        const messages = ticket.messages;
        console.log(messages);
        this.ticket.messages = [];
        messages.forEach((message) => {
          const cleanMessage = message.content
            .replace(/<br\s*[\/]?>/gi, "\n")
            .replace(/<b>/gi, "")
            .replace(/<\/b>/gi, "\n");
          const ITEMS = {
            content: cleanMessage,
            date: message.created_at,
            type: message.type,
            user: message.user_name,
            files: [],
          };
          if (message.file.length > 0) {
            const files = message.file;
            files.forEach((file) => {
              ITEMS.files.push(file);
            });
          }
          this.ticket.messages.push(ITEMS);
        });

        const delegated = ticket.delegated;
        this.ticket.delegated = [];
        if (delegated.length > 0) {
          delegated.forEach((users) => {
            const ITEMS = {
              avatar: users.user.avatar,
              name: users.user.name,
              lastname: users.user.lastname,
            };
            this.ticket.delegated.push(ITEMS);
            this.ticket.delegate.agents.push(users.user.id);
          });
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTimeLine() {
      try {
        this.timeline.loading = true;
        this.timeline.data = [];
        const id = this.$route.params.id;
        const query = await this.$repositories.tickets.timeline(id);
        if (query.data.timeline.length > 0) {
          query.data.timeline.forEach((item) => {
            const ITEMS = {
              id: item.id,
              content: item.content,
              user: item.user_name,
              date: item.created_at,
            };
            this.timeline.data.push(ITEMS);
          });
          this.timeline.empty = false;
        } else {
          this.timeline.empty = true;
        }
        this.timeline.loading = false;
        this.$store.commit("setTimeLine", true);
      } catch (error) {
        this.timeline.loading = false;
        this.timeline.empty = true;
      }
    },
    async fetchAgents(id) {
      try {
        this.agents.loading = true;
        this.agents.data = [];

        console.log(this.delegations.active);

        let query; // Declara la variable query fuera de los bloques if y else
        if (this.delegations.active) {
          query = await this.$repositories.agents.delegations(id);
        } else {
          query = await this.$repositories.agents.departament(id);
        }

        if (query.data.users.length === 0) {
          this.agents.active = false;
        } else {
          query.data.users.forEach((item) => {
            const ITEMS = {
              id: item.id,
              name: `${item.name} ${item.lastname}`,
              responsability: item.responsability,
            };
            this.agents.data.push(ITEMS);
          });
          this.agents.active = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.agents.loading = false;
      }
    },

    async fetchDepartaments() {
      try {
        this.departaments.loading = true;
        this.departaments.disabled = true;
        this.departaments.data = [];
        const query = await this.$repositories.departaments.list_not_marketer();
        query.data.departaments.forEach((item) => {
          const items = {
            id: item.id,
            name: item.name,
          };
          this.departaments.data.push(items);
          this.departaments.disabled = false;
          this.departaments.loading = false;
          console.log(this.departaments.data)
        });
      } catch (error) {
        console.log(error);
      }
    },

async fetchDelegations() {
  try {
    this.delegations.data = [];
    const query = await this.$repositories.delegations.list();

    const groups = {};
    query.data.delegations.forEach((item) => {
      if (item.id !== 114) { // Filtrar delegaciones con id 114
        const typeUpperCase = item.type.toUpperCase();
        if (!groups[typeUpperCase]) {
          groups[typeUpperCase] = [];
        }
        groups[typeUpperCase].push({
          id: item.id,
          name: item.name,
          type: typeUpperCase,
        });
      }
    });

    // Definir el orden deseado de los tipos
    const order = ["DELEGACION PROPIA", "DELEGACION TERCERIZADA", "CENTRO MÉDICO", "BOCA DE EXPENDIO"];

    // Ordenar los grupos según el orden deseado
    const sortedGroups = order.map((type) => ({
      type,
      items: groups[type] || [], 
    })).filter(group => group.items.length > 0); 

    this.delegations.data = sortedGroups;

    console.log(this.delegations.data);
  } catch (error) {
    console.log(error);
  }
},



    async delegateAgent() {
      try {
        if (this.ticket.delegate.agents.length <= 0) {
          alertFailed("Debe seleccionar un agente!");
          return;
        }

        this.loading = true;
        const id = this.ticket.id;

        // Extraer el id del departament seleccionado
         const departamentId = this.ticket.delegate.departament;

        console.log('departamento seleccionado '+ this.ticket.delegate.departament)
        if (!departamentId || isNaN(departamentId)) {
          alertFailed("ID de departamento no válido!");
          this.loading = false;
          return;
        }

        const data = new FormData();
        data.append("_method", "put");
        data.append("users", this.ticket.delegate.agents.join(",")); // Unir usuarios con comas
        data.append("departament", departamentId);

        console.log("Departament ID enviado:", departamentId); // Verificar valor

        await this.$repositories.tickets.delegate(id, data);
        this.ticket.delegate.active = false;
        this.ticket.delegate.departament = "";
        this.agents.data = [];
        this.agents.active = false;
        alertSuccess("Registro exitoso!");
        await this.fetchTicket(this.ticket.id);
        this.$EventBus.$emit("updatedTicket");
      } catch (error) {
        this.loading = false;
        alertFailed("Ha ocurrido un error!");
      }
    },

    async answerTicket() {
      try {
        if (
          this.ticket.answer.message != "" ||
          this.ticket.answer.files.length > 0
        ) {
          await this.sendMessage();
          await this.fetchTicket(this.ticket.id);
           
        }

      
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
      }
    },

    async urgentTicket() {
      try {
        this.loading = true;
        const ticket = this.ticket.id;
        const data = new FormData();
        data.append("_method", "put");
        await this.$repositories.tickets.urgent(ticket, data);
        this.ticket.is_urgent = true;
        alertSuccess("Se ha marcado como urgente!");
        this.fetchTicket(ticket);
        this.$EventBus.$emit("updatedTicket");
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },
    async openTicket() {
      try {
        this.loading = true;
        const ticket = this.ticket.id;
        await this.$repositories.tickets.open(ticket);
        alertSuccess("Se ha abierto la consulta!");
        this.fetchTicket(ticket);
        this.$EventBus.$emit("updatedTicket");
        await this.sendWhatsAppTemplate() 
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },
    async closeTicket() {
      try {
        this.loading = true;
        const ticket = this.ticket.id;
        await this.$repositories.tickets.close(ticket);
        alertInfo("Se ha cerrado la consulta!");
        this.fetchTicket(ticket);
        this.$EventBus.$emit("updatedTicket");
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },
    async sendMessage() {
      try {
        const id_user = this.$store.getters["getSession"]["id"];
        const user_name =
          this.$store.getters["getSession"]["name"] +
          " " +
          this.$store.getters["getSession"]["lastname"];
        const id = this.ticket.id;
        const data = new FormData();
        data.append("content", this.ticket.answer.message);
        data.append("type", "sended");
        data.append("user_id", id_user);
        data.append("user_name", user_name);
        this.ticket.answer.files.forEach((file) => {
          data.append("files[]", file);
        });
        await this.$repositories.tickets.message(id, data);
        //console.log('tipo de dato '+this.ticket.origin.name)
        if (this.ticket.origin.name == "Whatsapp") {
          await this.sendWhatsAppMessage();
        }
       
        this.ticket.answer.message = "";
        this.ticket.answer.files = [];
        alertSuccess("Mensaje enviado!");
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
     // console.log('numero '+this.ticket.contact.phone)
      //await this.sendWhatsAppMessage();
    },

  async sendWhatsAppMessage() {
    console.log('numero '+this.ticket.contact.phone)
      try {
        const response = await this.$repositories.tickets.sendMessage(this.ticket.contact.phone, this.ticket.answer.message);
        if (response.status === 200) {
          alertSuccess("Mensaje de WhatsApp enviado!");
        } else {
          alertFailed("Error al enviar mensaje de WhatsApp!");
        }
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
      }
    },

    async sendWhatsAppTemplate() {
    //console.log('numero '+this.ticket.contact.phone)
      const name = this.$store.getters["getSession"]["name"] + ' ' + this.$store.getters["getSession"]["lastname"]
      try {
        const response = await this.$repositories.tickets.sendMessageTemplate(this.ticket.contact.phone, name );
        if (response.status === 200) {
          alertSuccess("Mensaje de WhatsApp enviado!");
        } else {
          alertFailed("Error al enviar mensaje de WhatsApp!");
        }
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
      }
    },

    async createLead() {
      try {
        this.loading = true;
        const data = new FormData();
        data.append("contact", this.ticket.contact.id);
        data.append("ticket", this.ticket.id);
        data.append("ticket_status", this.ticket.statuses.id);
        data.append("user_id", 0);
        data.append("agent", 0);
        data.append(
          "inherited_origin",
          `crm-consultas-${this.ticket.origin.name.toLowerCase()}`
        );
        data.append("origin", "crm-consultas");
        data.append("stage", 1); // Lead
        await this.$repositories.sales.create(data);
        this.loading = false;
        this.$router.push("/ventas");
        alertSuccess("Registro exitoso!");
      } catch (error) {
        this.loading = false;
        alertFailed("Ha ocurrido un error!");
      }
    },
    ticketId(value) {
      return fillString(value, 4);
    },
    removeFile(index) {
      this.ticket.answer.files.splice(index, 1);
    },
    urlShort(value) {
      const short = value.split("/");
      return short[8] ? short[8] : short[7];
    },
  },
  watch: {
    "$route.params.id": async function () {
      if (this.$route.params.id) {
        await this.fetchTicket(this.$route.params.id);
        await this.fetchDepartaments();
        this.$store.commit("setToggle", true);
      } else {
        this.$store.commit("setToggle", false);
      }
    },
  },
  computed: {
    ...mapState(["toggle"]),
  },
  async created() {
    if (this.$route.params.id) {
      await this.fetchTicket(this.$route.params.id);
      await this.fetchDepartaments();
      await this.fetchDelegations();
      this.$store.commit("setToggle", true);
    } else {
      this.$store.commit("setToggle", false);
    }
  },

  mounted() {
   // this.fetchMessages(this.ticketId); // Inicializa la carga de mensajes
    this.messagePolling = setInterval(() => {
        this.fetchTicket(this.$route.params.id);
    }, 30000); // Polling cada 5 segundos
},
};
</script>

<style lang="scss" scoped>
#ticket {
  height: 100vh;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  .level {
    .level-right {
      text-align: center;
      button {
        border-radius: 50% !important;
        &.is-danger {
          opacity: 0.5;
        }
        &.active {
          opacity: 1 !important;
        }
      }
    }
  }
  .delegate {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 2px solid $light;
    .departaments {
      padding-bottom: 1rem;
    }
    .agents {
      padding-top: 1rem;
      border-top: 1px solid $light;
    }
    ul {
      max-height: 200px;
      overflow-y: auto;
    }
  }
  .messages {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 2px solid $light;
    ul {
      max-height: 350px;
      overflow-y: auto;
    }
    .message-box {
      display: flex;
      align-items: flex-end;
      margin-bottom: 1rem;
      .message {
        border-radius: 0 !important;
        ~ .message-body {
          border-radius: 0 !important;
          border-color: transparent !important;
        }
      }
      &.me {
        .message {
          background: rgb(0, 147, 191);
          background: linear-gradient(
            45deg,
            rgba(0, 147, 191, 1) 35%,
            rgba(0, 117, 143, 1) 100%
          );
          .message-body {
            color: $white;
          }
        }
      }
    }
  }
  .navbar {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 2rem;
    border-top: 1px solid $light;
    .field {
      &.has-addons {
        border-bottom: 1px solid $light;
        padding: 1rem;
        input {
          border: none;
        }
        .control {
          .buttons {
            margin-top: 0;
          }
        }
      }
    }
    .buttons {
      margin-top: 1rem;
      width: 100%;
    }
  }
}
</style>
