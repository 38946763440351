<template>
  <b-field label="Dirección">
    <b-autocomplete
      @select="setPlaces"
      v-model="address"
      :data="searchResults"
      clearable
      required
    >
      <template #empty>No se encontraron resultados</template>
    </b-autocomplete>
  </b-field>
</template>

<script>
//import {mapState, mapActions} from 'vuex';
export default {
    name: "Places",
    data() {
        return {
            searchResults: [],
            selected: null,
            address: '',
            lat: '',
            lng: '',
            location: null,
            geolocation: null,
        };
    },
    methods: {
        initPlaces() {
            this.location = new window.google.maps.places.AutocompleteService();
            this.geolocation = new window.google.maps.Geocoder();
        },
        displaySuggestions(predictions, status) {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                this.searchResults = [];
                return;
            }
            this.searchResults = predictions.map(
                (prediction) => prediction.description
            );
        },
        coordinates() {
            if (this.address != "") 
                location = this.address;
                this.geolocation.geocode({ location }, (res, status) => {
                if (status == "OK")
                    this.lng = res[0].geometry.location.lng();
                    this.lat = res[0].geometry.location.lat();
                });
        },
        setPlaces(){
            this.coordinates();
            /*
            const VALUES = {
                address: this.address,
                lat: this.lat,
                lng: this.lng 
            }
            this.$emit('value', VALUES);
            */
        }
        
    },
    watch: {
        address (value) {
            if (value) {
                this.location.getPlacePredictions({
                    input: this.address,
                    types: ['address']
                }, this.displaySuggestions)
            }
        }
    },
    mounted() {
        this.initPlaces();
    },
};
</script>
