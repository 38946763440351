<template>
    <b-modal
        v-model="$store.getters.getNotifications"
        has-modal-card
        :can-cancel="false"
        aria-modal
        aria-role="dialog">        
        <div class="modal-card" style="width:350">
            <header class="modal-card-head">
                <p class="modal-card-title is-uppercase has-text-weight-bold has-text-grey-light mb-0">Notificaciones</p>
                <button
                    type="button"
                    class="delete"
                    @click="$store.commit('setNotifications', false)"
                />
            </header>
            <div class="modal-card-body">
                <Loader :active="loading" :full="false"></Loader>
                <ul v-if="!empty" class="menu-list">
                    <li v-for="(item, index) in unread.data"
                        :key="index"
                        @click="markAsRead(item.id)">
                        <router-link :to="item.link">
                            <div class="media">
                                <div class="media-left">
                                    <b-icon
                                        pack="fas"
                                        :icon="(item.type == 'tickets') ? 'comments' : 'user-plus'">
                                    </b-icon>
                                </div>
                                <div class="media-content">
                                    <p class="mb-0">{{ item.message }}</p>
                                    <small class="has-text-grey">
                                        <timeago :datetime="item.date" :auto-update="60"></timeago>
                                    </small>
                                </div>
                                <div class="media-right">
                                    <b-tooltip 
                                        label="Marcar como leído"
                                        type="is-dark"
                                        position="is-left"
                                        :square=true>
                                        <b-button
                                            type="is-small"
                                            :class="'is-circle'"
                                            @click="markAsRead(item.id)"
                                            icon-pack="fas"
                                            icon-right="check">
                                        </b-button> 
                                    </b-tooltip>
                                </div>
                            </div>
                        </router-link>
                    </li>
                </ul>
                <div v-else class="section">
                    <p class="has-text-centered">No hay nuevas notificationes. </p>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import Loader from './Loader.vue';
    import VueTimeago from 'vue-timeago'
    export default {
        name: 'Notifications',
        components:{
            VueTimeago,
            Loader
        },
        data(){
            return{
                loading: false,
                empty: false,
                read:{
                    data: []
                },
                unread:{
                    data: []
                }
            }
        },
        methods:{
            async fetchNotifications(){
                try {
                    this.loading = true;
                    this.unread.data = [];
                    this.read.data = [];
                    this.empty = false;
                    const query = await this.$repositories.notifications.unread();
                    if(query.data.length > 0){
                        query.data.forEach(item => {
                            const ITEMS = {
                                id: item.id,
                                type: item.data.type,
                                link: item.data.link,
                                message: item.data.message,
                                date: item.data.date
                            }
                            this.unread.data.push(ITEMS);
                        });
                        this.$store.commit('setUnreadNotifications', true);
                    } else {
                        this.$store.commit('setUnreadNotifications', false);
                        this.empty = true;
                    }
                    this.loading = false;
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            },
            async markAsRead(id){
                this.$store.commit('setNotifications', false);
                try {
                    const data = new FormData();
                    data.append('_method', 'put');
                    await this.$repositories.notifications.markAsRead(id, data);
                } catch (error) {
                    console.log(error);
                }
            }   
        },
        created(){
            this.fetchNotifications();
            this.$store.commit('setNotifications', false);
            /*
            window.Echo.channel('notifications')
            .listen('NotificationsEvent', (e) => {
                this.loading = true;
                this.unread.data = [];
                this.read.data = [];
                this.empty = false;
                if(e.notifications.length > 0){
                    e.notifications.forEach(item => {
                        const ITEMS = {
                            id: item.id,
                            type: item.data.type,
                            link: item.data.link,
                            message: item.data.message,
                            date: item.data.date
                        }
                        this.unread.data.push(ITEMS);
                    });
                    this.$store.commit('setUnreadNotifications', true);
                } else {
                    this.$store.commit('setUnreadNotifications', false);
                    this.empty = true;
                }
            });
             */
           this.loading = false;
        }
    }
</script>

<style lang="scss" scoped>
    .modal-card-body{
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        ul{
            li{
                padding: .5rem 0;
                border-bottom: 1px solid $light;
                &:last-child{
                    border: none;
                }
            }
        }   
    }
</style>