<template>
  <div id="providers-table">


  <div>
  <!-- Modal para mostrar la contraseña generada -->
  <b-modal v-model="modalImpoetarCSV" title="Importar Prestadores">
    <div class="modal-card" style="width: 100%,  height: 100%">
      <header class="modal-card-head">
        <p class="modal-card-title">Importar Prestadores</p>
      </header>
      <section class="modal-card-body">
      
           
          <input
             type="file"
            ref="fileInput"
             
           />

          <b-button   
            native-type="submit"
            type="is-primary"
            icon-pack="fas"
            class="ml-2 mr-2"
            :disabled="isSaving"
            @click="enviarArchivoCSV"
          >
          enviar
          </b-button>

      </section>
    </div>
  </b-modal>
</div>




    <div>
      <!-- Modal para mostrar la contraseña generada -->
      <b-modal v-model="modalContraseña" title="Contraseña generada">
        <div class="modal-card" style="width: 100%">
          <header class="modal-card-head">
            <p class="modal-card-title"></p>
          </header>
          <section class="modal-card-body">
            La contraseña del prestador es:
            <p style="font-size: 25px">{{ this.contraseñaGenerada }}</p>
          </section>
        </div>
      </b-modal>
    </div>

    <b-modal v-model="showModal" title="Nueva Ventana Modal">
      <div class="modal-card" style="width: 100%">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="!id_prestador">Nuevo Prestador</p>
          <p class="modal-card-title" v-else>Editar Prestador</p>
        </header>
        <section class="modal-card-body">
          <form @submit.prevent="sendProvider">
            <section class="modal-card-body">
              <div class="columns">
                <div class="column">
                  <b-field label="Nombre de la empresa" required>
                    <b-input v-model="businessName" type="text"></b-input>
                  </b-field>
                  <b-field label="Dirección" required>
                    <b-input v-model="address" type="text"></b-input>
                  </b-field>
                  <b-field label="Web" required>
                    <b-input v-model="homepage" type="text"></b-input>
                  </b-field>
                  <b-field label="Tipología" required>
                    <b-input v-model="typology" type="text"></b-input>
                  </b-field>
                </div>

                <div class="column">
                  <b-field label="Nombre comercial" required>
                    <b-input v-model="comercialName" type="text"></b-input>
                  </b-field>
                  <b-field label="Ciudad" required>
                    <b-input v-model="city" type="text"></b-input>
                  </b-field>
                  <b-field label="Email" required>
                    <b-input v-model="email" type="email" value=""></b-input>
                  </b-field>
                  <b-field label="Especialidades">
                    <b-taginput
                      v-model="Specialties"
                      :data="tagSpecialties"
                      placeholder="Seccione varios elementos..."
                      field="name"
                      autocomplete
                      separator=","
                      :open-on-focus="openOnFocus"
                    ></b-taginput>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="CUIT" required>
                    <b-input v-model="cuit" type="text"></b-input>
                  </b-field>
                  <b-field label="Provincia" required>
                    <b-select
                      placeholder="Seleccionar Red Prestacional"
                      v-model="province"
                      required
                    >
                      <option value="Buenos Aires">Buenos Aires</option>
                      <option value="Córdoba">Córdoba</option>
                      <option value="Catamarca">Catamarca</option>
                      <option value="Jujuy">Jujuy</option>
                      <option value="Tucuman">Tucuman</option>
                      <option value="Formosa">Formosa</option>
                      <option value="Santiago del Estero">
                        Santiago del Estero
                      </option>
                      <option value="Salta">Salta</option>
                      <option value="Corrientes">Corrientes</option>
                      <option value="Entre Ríos">Entre Ríos</option>
                      <option value="La Rioja">La Rioja</option>
                      <option value="San Luis">San Luis</option>
                      <option value="Santa Fe">Santa Fe</option>
                      <option value="Mendoza">Mendoza</option>
                      <option value="San Juan">San Juan</option>
                      <option value="Chaco">Chaco</option>
                      <option value="Chubut">Chubut</option>
                      <option value="Capital Federal">Capital Federal</option>
                      <option value="La Pampa">La Pampa</option>
                      <option value="Misiones">Misiones</option>
                      <option value="Neuquen">Neuquen</option>
                      <option value="Río Negro">Río Negro</option>
                      <option value="Santa Cruz">Santa Cruz</option>
                      <option value="Tierra del Fuego">Tierra del Fuego</option>
                    </b-select>
                  </b-field>
                  <b-field label="Teléfono" required>
                    <b-input v-model="phone" type="text"></b-input>
                  </b-field>
                  <b-field label="Planes">
                    <b-taginput
                      v-model="Plans"
                      :data="tagPlans"
                      placeholder="Seccione varios elementos..."
                      field="name"
                      autocomplete
                      separator=","
                      :open-on-focus="openOnFocus"
                    ></b-taginput>
                  </b-field>
                </div>
              </div>
              <!-- Agrega los demás campos -->
            </section>

          <div class="buttons" style="display: flex; align-items: center;">
    <b-button
      native-type="submit"
      type="is-primary"
      icon-pack="fas"
      class="ml-2 mr-2"
      :disabled="isSaving"
    >
      Guardar
    </b-button>
    
    <!-- Checkbox "Reiniciar Contraseña" -->
    <b-field style="margin-left: 1rem;">
      <b-checkbox v-model="NewPass">Reiniciar Contraseña</b-checkbox>
    </b-field>
  </div>

          </form>
        </section>
      </div>
    </b-modal>
    <section class="columns is-multiline is-mobile">
      <div class="column is-12-touch is-6-desktop">
        <div class="level-left">
          <h1 class="title has-text-primary">Prestadores</h1>
        </div>
      </div>



      

      <div class="column is-12-touch is-12-desktop has-text-right">
        <b-button v-if="this.$hasPermission('prestadores.crear')"
          native-type="submit"
          type="is-primary"
          icon-pack="fas"
          class="ml-2 mr-2"
          @click="openModal"
          >Nuevo Prestador
        </b-button>

         <b-button v-if="this.$hasPermission('prestadores.crear')"
          native-type="submit"
          type="is-primary"
          icon-pack="fas"
          class="ml-2 mr-2"
          @click="openModalCSV"
          >Importar CSV
        </b-button>

        <a
          class="button is-primary"
          :href="`http://www.dipres.com.ar/providers/list_from_state.csv?management_id=1&state_id=${this.state}&plan_import_code=${this.plan}`"
          target="_blank"
        >
          descargar plantilla CSV
        </a>
      </div>
    </section>

    <div class="card">
      <div class="card-content">
        <b-field grouped>
          <Search @value="getValue" />
          <p class="control">
            <b-field>
              <b-select
                v-model="providers.province"
                placeholder="Seleccionar provincia"
                expanded
                @input="getProvince"
              >
                <option value="">Selecciona Provincia</option>
                <option value="Buenos Aires">Buenos Aires</option>
                <option value="Córdoba">Córdoba</option>
                <option value="Catamarca">Catamarca</option>
                <option value="Jujuy">Jujuy</option>
                <option value="Tucuman">Tucuman</option>
                <option value="Formosa">Formosa</option>
                <option value="Santiago del Estero">Santiago del Estero</option>
                <option value="Salta">Salta</option>
                <option value="Corrientes">Corrientes</option>
                <option value="Entre Ríos">Entre Ríos</option>
                <option value="La Rioja">La Rioja</option>
                <option value="San Luis">San Luis</option>
                <option value="Santa Fe">Santa Fe</option>
                <option value="Mendoza">Mendoza</option>
                <option value="San Juan">San Juan</option>
                <option value="Chaco">Chaco</option>
                <option value="Chubut">Chubut</option>
                <option value="Capital Federal">Capital Federal</option>
                <option value="La Pampa">La Pampa</option>
                <option value="Misiones">Misiones</option>
                <option value="Neuquen">Neuquen</option>
                <option value="Río Negro">Río Negro</option>
                <option value="Santa Cruz">Santa Cruz</option>
                <option value="Tierra del Fuego">Tierra del Fuego</option>
              </b-select>
            </b-field>
          </p>
          <p class="control">
            <b-field>
              <b-select
                v-model="providers.plan"
                placeholder="Seleccionar plan"
                expanded
                @input="getPlan"
              >
                <option value="">Selecciona Plan</option>
                <option
                  v-for="(item, index) in tagPlans"
                  :value="item.id"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
          </p>
        </b-field>
        <b-table
          hoverable
          paginated
          pagination-simple
          backend-pagination
          :scrollable="true"
          :sticky-header="true"
          :total="providers.total"
          :per-page="providers.perPage"
          height="295"
          :loading="providers.loading"
          :data="providers.isEmpty ? [] : providers.filter"
        >
          <b-table-column field="name" label="Nombre" v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="alias" label="Alias" v-slot="props">
            {{ props.row.alias }}
          </b-table-column>

          <b-table-column field="tipology" label="Tipología" v-slot="props">
            {{ props.row.tipology }}
          </b-table-column>

          <b-table-column field="address" label="Dirección" v-slot="props">
            {{ props.row.address }}
          </b-table-column>

          <b-table-column field="phone" label="Teléfono" v-slot="props">
            {{ props.row.phone }}
          </b-table-column>

          <b-table-column field="email" label="Email" v-slot="props">
            {{ props.row.email }}
          </b-table-column>

          <b-table-column field="web" label="Web" v-slot="props">
            {{ props.row.web }}
          </b-table-column>

          <b-table-column label="Acciones">
            <template #default="props" v-if="this.$hasPermission('prestadores.editar')">
              <div class="buttons">
                <b-button 
                  @click="deleteProvider(props.row.id)"
                  type="is-danger"
                  size="is-small"
                >
                  <b-icon icon="trash"></b-icon>
                </b-button>
                <b-button 
                  @click="editProvider(props.row)"
                  type="is-primary"
                  size="is-small"
                >
                  <b-icon icon="edit"></b-icon>
                </b-button>
              </div>
            </template>
          </b-table-column>

          <template #empty>
            <div class="has-text-centered">No se encontraron resultados</div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "./Search.vue";
import axios from "axios";
import { alertSuccess, alertFailed } from "@/helpers";

export default {
  name: "ProvidersTable",
  components: {
    Search,
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_ROOT_API,
      modalImpoetarCSV: false,
      file: null,
      openOnFocus: true,
      isSaving: false,
      Plans: [],
      Specialties: [],
      tagSpecialties: [],
      tagPlans: [],
      NewPass: false,
      showModal: false,
      modalContraseña: false,
      state: "Seleccion Plan",
      plan: "",
      businessName: "",
      address: "",
      homepage: "",
      typology: "",
      comercialName: "",
      city: "",
      email: "",
      password: "",
      cuit: "",
      province: "",
      phone: "",
      plan: [],
      speciality: [],
      contraseñaGenerada: "",
      passwordError: false,
      confirmError: false,
      id_prestador: null,
      providers: {
        data: [],
        filter: [],
        total: 0,
        order: "desc",
        page: 1,
        perPage: 10,
        loading: false,
        isEmpty: true,
        search: "",
        plan: "",
        province: "",
        disabled: ""
      },
    };
  },
  methods: {
    async fetchProviders() {
      try {
        this.providers.loading = true;

        const response = await this.$repositories.providers.index(
          this.providers.search,   this.providers.province, this.providers.plan,
        );
        console.log(response);
        const results = response.data;
        console.log(response.data)
   
          this.providers.data = [];
          this.providers.filter = [];
          this.providers.total = results.length;

          results.forEach((item) => {
            const ITEMS = {
              id: item.id,
              name: item.business_name,
              alias: item.comercial_name,
              tipology: item.typology,
              province: item.province,
              city: item.city,
              address: item.address,
              phone: item.phone,
              email: item.email,
              web: item.homepage,
              speciality: item.specialities,
              plan: item.plans_of_provider,
              cuit: item.cuit,
            };

            this.providers.data.push(ITEMS);
            this.providers.filter.push(ITEMS);
          });

          this.providers.isEmpty = false;
        

        this.providers.loading = false;
      } catch (error) {
        console.log(error);
        this.providers.loading = false;
        this.providers.isEmpty = true;
      }
    },

    getValue(value) {
      this.providers.search = value;
      this.providers.loading = true;
      this.fetchProviders();
    },

    getPlan(){
      console.log(this.providers.plan)
      this.providers.loading = true;
      this.fetchProviders();
    },

     getProvince(value){
      this.providers.loading = true;
      this.fetchProviders();
    },

    async fetchSpecialities() {
      try {
        const response = await this.$repositories.providers.specialities();
        const results = response.data;

        this.tagSpecialties = results.map((speciality, index) => ({
          name: speciality.name,
          id: (index + 1).toString(),
        }));

        //console.log(this.tagSpecialties);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchPlans() {
      try {
        const response = await this.$repositories.providers.plans();
        const results = response.data;
        
        this.tagPlans = results.map((plan2, index) => ({
          name: plan2.name,
          id: ( index + 1).toString(),
        }));

      console.log( this.tagPlans);
      } catch (error) {
        console.log(error);
      }
    },

    // Genera una contraseña aleatoria
    generarContrasenaAleatoria() {
      const caracteres =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let contraseña = "";
      for (let i = 0; i < 10; i++) {
        contraseña += caracteres.charAt(
          Math.floor(Math.random() * caracteres.length)
        );
      }
      return contraseña;
    },

    mostrarModalContrasena(contraseña) {
      this.mostrarModal(`La contraseña generada es: ${contraseña}`);
    },

    editProvider(data) {
      this.businessName = data.name;
      this.comercialName = data.alias;
      this.address = data.address;
      this.homepage = data.web;
      this.typology = data.tipology;
      this.city = data.city;
      this.email = data.email;
      this.cuit = data.cuit;
      this.province = data.province;
      this.phone = data.phone;
      this.Specialties = data.speciality;
      this.Plans = data.plan;
      this.id_prestador = data.id;
      console.log(data.speciality);
      this.showModal = true;
    },

    sendProvider() {
      this.isSaving = true;
      if (this.id_prestador) {
        console.log(this.Plans);
         if(this.NewPass){
        this.contraseñaGenerada = this.generarContrasenaAleatoria();
         }
        
        const formData = {
          business_name: this.businessName,
          address: this.address,
          homepage: this.homepage,
          typology: this.typology,
          comercial_name: this.comercialName,
          city: this.city,
          email: this.email,
          password: this.contraseñaGenerada,
          cuit: this.cuit,
          province: this.province,
          phone: this.phone,
          speciality: this.Specialties,
          plan: this.Plans,
          NewPass: this.NewPass
        };

        axios
          .put(
            `${this.baseURL}/api/v1/prestador/update/` + this.id_prestador,
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            alertSuccess("Se guardaron los datos!");

            this.showModal = false; // Cierra el modal
            this.resetFields(); // Restablece los valores de los campos
            this.fetchProviders();
            //this.mostrarModalContrasena(this.contraseñaGenerada);
             if(this.NewPass){
            this.modalContraseña = true;
             }
              this.isSaving = false;
          })
          .catch((error) => {
            console.error(error);

            if (
              error.response.status === 400 &&
              error.response.data === "El cuit ya está registrado."
            ) {
              // Mostrar el alert con el mensaje recibido del servidor
              alertFailed(error.response.data);
            } else {
              alertFailed("Algo salió mal!");
            }
          });
      } else {
       
        this.contraseñaGenerada = this.generarContrasenaAleatoria();
        
        const formData = {
          business_name: this.businessName,
          address: this.address,
          homepage: this.homepage,
          typology: this.typology,
          comercial_name: this.comercialName,
          city: this.city,
          email: this.email,
          password: this.contraseñaGenerada,
          cuit: this.cuit,
          province: this.province,
          phone: this.phone,
          speciality: this.Specialties,
          plan: this.Plans,
          
        };

        axios
          .post(`${this.baseURL}/api/v1/prestador/create`, formData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            alertSuccess("Se guardaron los datos!");

            this.showModal = false; // Cierra el modal
            this.resetFields(); // Restablece los valores de los campos
            this.fetchProviders();
            //this.mostrarModalContrasena(this.contraseñaGenerada);
           
            this.modalContraseña = true;
            this.isSaving = false;
            
          })
          .catch((error) => {
            console.error(error);

            if (
              error.response.status === 400 &&
              error.response.data === "El cuit ya está registrado."
            ) {
              // Mostrar el alert con el mensaje recibido del servidor
              alertFailed(error.response.data);
            } else {
              alertFailed("Algo salió mal!");
            }
          });
      }
    },

    resetFields() {
      this.businessName = "";
      this.address = "";
      this.homepage = "";
      this.typology = "";
      this.comercialName = "";
      this.city = "";
      this.email = "";
      this.password = "";
      this.cuit = "";
      this.province = "";
      this.phone = "";
      this.passwordError = false;
      this.confirmError = false;
      (this.Specialties = null), (this.Plans = null);
    },

    openModal() {
      this.resetFields();
      this.id_prestador = null;
      this.showModal = true;
    },

     openModalCSV() {
      
      this.modalImpoetarCSV = true;
    },

    enviarArchivoCSV() {
      const file = this.$refs.fileInput.files[0];
      if (!file) {
        alert('Por favor, seleccione un archivo.');
        return;
      }

      this.isSaving = true;

      const formData = new FormData();
      formData.append('file', file);

      
      fetch(`${this.baseURL}/api/v1/prestador/file-import`, {
        method: 'POST',
        body: formData
      })
      
        .then(data => {
          console.log('Archivo enviado con éxito:', data);
          this.isSaving = false;
          alertSuccess("Se Importaron los registros!");
          this.modalImpoetarCSV = false;
          this.fetchProviders();
        })
        .catch(error => {
          console.error('Error al enviar el archivo:', error);
          this.isSaving = false;
          this.modalImpoetarCSV = false;
           alertFailed("Algo salio mal!");
        });
    },
  

    deleteProvider(id) {
      if (!id) {
        console.error("ID del proveedor no definido");
        return;
      }

      // Realizar la solicitud POST
      axios
        .post(`${baseURL}/api/v1/prestador/delete/${id}`)
        .then((response) => {
          // Manejar la respuesta exitosa, si es necesario
          alertSuccess("Se eliminó el prestador!");
          this.fetchProviders();
        })
        .catch((error) => {
          // Manejar el error, si ocurre
          console.error("Error al eliminar el registro:", error);
        });
    },
  },

  created() {
    this.fetchProviders();
    this.fetchSpecialities();
    this.fetchPlans();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
#providers-table {
  form {
    margin-bottom: 1rem;
  }
}
</style>
