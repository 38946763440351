<template>
  <div v-if="!auth" id="app">
    <Login/>
  </div>
  <div v-else id="app">
    <Sidebar v-if="$route.name != 'Venta'"/>
    <section id="content" :class="($route.name != 'Venta') ? 'content' : ''">
      <router-view></router-view>
      <Modal/>
    </section> 
  </div>
</template>

<script>
  import Login from '@/components/Login.vue';
  import Sidebar from '@/components/Sidebar.vue';
  import Modal from '@/components/Notifications-modal.vue';
  export default {
    components:{
      Login,
      Sidebar,
      Modal
    },
    methods:{

    },
    computed:{
      auth(){
        return this.$store.getters.getAuth;
      }
      
    },
  }
</script>

<style lang="scss">
  #app{
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    background-color: $light;
    section{
      &.content{
        margin-left: 75px;
      }
    }
    .button{
      text-transform: uppercase;
      font-weight: 600;
      &.is-circle{
        border-radius: 50%!important;
      }
    }
    .dropdown-item{
      text-transform: uppercase;
      font-weight: 500;
    }
    .collapse{
        &.card{
            .card-header{
                box-shadow: none!important;
            }
            .card-content{
              .label{
                    color: $primary!important;
              }   
              input.input, select, textarea{
                  border-top: 0;
                  border-bottom: 2px solid $warning;
                  border-left: 0;
                  border-right: 0;
              }
              button{
                  &.is-circle{
                      border-radius: 50%!important;
                  }
                  &.is-text{
                      text-decoration: none!important;
                  }
              }
            }
        }
    }
    .content ul{
      list-style: none;
      margin: 0;
    }
  }
  .dialog{
    .modal-card{
      .modal-card-foot{
        padding-top: 0;
        border-top: 0;
        background-color: $white;
        border-radius: 0;
      }
    }
  }
</style>

