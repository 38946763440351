<template>
    <b-input
        :disabled="disabled" 
        :placeholder="placeholder"
        type="search"
        v-model="value"
        icon-right="search"
        @input="setValue"
        expanded>
    </b-input>
</template>

<script>
    export default {
        name:'TicketSearch',
        props:{
            placeholder:{
                type: String,
                default: 'Buscar...'
            },
            disabled:{
                type: Boolean,
                default: false
            }
        },
        data(){
            return{
                value: ''
            }
        },
        methods:{
            setValue(){
                this.$emit('value', this.value);
            }
        }
    }
</script>

<style lang="scss">
    .input{
        border-color: $white;
    }
    .input[disabled]{
        border: 1px solid #e5e5e5;
    }

</style>