export default (axios, baseURL) => {
    return{
        //
        index(page, search){
            return axios.get(`${baseURL}/api/departaments?search=${search}&page=${page}`)
        },
        list(){
            return axios.get(`${baseURL}/api/departaments`)
        },
        list_not_marketer(){
            return axios.get(`${baseURL}/api/departaments_list`)
        },
        show(id){
            return axios.get(`${baseURL}/api/departaments/${id}`)
        },
        create(data){
            return axios.post(`${baseURL}/api/departaments`, 
                data
            );
        },
        update(id, data){
            return axios.post(`${baseURL}/api/departaments/${id}`,
                data
            );
        },
        delete(id){
            return axios.delete(`${baseURL}/api/departaments/${id}`);
        },
    }
}