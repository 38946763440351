<template>
  <div id="login">
    <Loader :active="loading"></Loader>
    <div class="background"></div>
    <div class="login-content">
      <figure class="image is-128x128">
        <img src="/img/osfatunalt-30-b.svg" width="250" alt="Logo osfatun" />
      </figure>
      <div class="columns">
        <div class="column">
          <div class="card">
            <div class="card-content">
              <h1 class="title is-uppercase has-text-centered has-text-primary">
                Iniciar Sesión
              </h1>
              <form @submit.prevent="login">
                <b-field>
                  <b-input
                    type="email"
                    v-model="email"
                    placeholder="Email"
                    required
                  >
                  </b-input>
                </b-field>
                <b-field>
                  <b-input
                    type="password"
                    v-model="password"
                    minlength="4"
                    icon-pack="fas"
                    placeholder="Contraseña"
                    password-reveal
                    required
                  >
                  </b-input>
                </b-field>
                <b-field>
                  <b-button
                    type="is-warning"
                    native-type="submit"
                    expanded
                    outlined
                  >
                    Iniciar Sesión
                  </b-button>
                </b-field>
              </form>
            </div>
          </div>
        </div>
      </div>

      <figure class="image is-64x64">
        <img src="/img/Logo_zaro.svg" alt="Logo zaro" />
      </figure>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader.vue";
import { alertSuccess, alertFailed } from "@/helpers";
import Cookies from 'js-cookie';

export default {
  name: "Login",
  components: {
    Loader,
  },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },



  methods: {
    async login() {
      try {
        this.loading = true;

        const data = new FormData();
        data.append("email", this.email);
        data.append("password", this.password);

        const query = await this.$repositories.auth.login(data);
        this.$store.commit("setAuth", true);
        this.$store.commit("setSession", query.data);
        //console.log(query.data);
        //sessionStorage.setItem("token", query.data.token);
         Cookies.set('token', query.data.token, { expires: 1 }); // Aquí, "expires" es opcional y establece la duración de la cookie en días
        this.$router.push("/home");

        alertSuccess("Ingreso exitoso!");
        this.loading = false;
      } catch (error) {
        alertFailed("Email o contraseña invalidos!");
        this.password = "";
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: rgb(0, 147, 191);
    background: linear-gradient(
      45deg,
      rgba(0, 147, 191, 1) 35%,
      rgba(0, 117, 143, 1) 100%
    );
  }
  .login-content {
    figure {
      margin: 0 auto;
    }
    .card {
      width: 400px;
      .card-content {
        padding: 2.5rem;
        form {
          input {
            background-color: $light;
          }
          button {
            border-width: 2px;
            color: $primary;
          }
        }
      }
    }
  }
}
</style>
