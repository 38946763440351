<template>
  <div id="agents-page">
    <div class="columns">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-left">
            <h1 class="title has-text-primary">Agentes</h1>
          </div>
          <div class="level-right">
            <b-button
              tag="router-link"
              :to="'/agentes/nuevo'"
              type="is-primary">
              Crear Nuevo
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Tabs -->
    <b-tabs v-model="activeTab">
      <b-tab-item label="Lista de Agentes">
        <div class="columns">
          <div class="column">
            <Table :search="search"/>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Registro">
        <div class="columns">
          <div class="column">
            <AgentTimeline />
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import Table from '@/components/Agents-table.vue';
import AgentTimeline from '@/components/Agents-timeline.vue';

export default {
  name: 'AgentsPage',
  components: {
    Table,
    AgentTimeline
  },
  data() {
    return {
      search: '',
      activeTab: 0, // Controla la tab activa
    };
  },
  methods: {
    getValue(value) {
      this.search = value;
    },
  }
}
</script>

<style lang="scss" scoped>
#agents-page {
  position: relative;
  height: 100vh;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
