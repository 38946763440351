import axios from 'axios';
import store from '../store';
import router from '../router';
import {alertFailed} from '../helpers';
import createAuth from './auth.js';
import createUsers from './users.js';
import createAgents from './agents.js';
import createTickets from './tickets.js';
import createSales from './sales.js';
import createContacts from './contacts.js';
import createProviders from './providers.js';
import createDepartaments from './departaments.js';
import createGerencies from './gerencies.js';
import createDelegations from './delegations.js';
import createNotifications from './notifications.js';
import createPermissions from './permissions.js';
import Cookies from 'js-cookie';

// Axios Global Config
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.interceptors.request.use(
  config => {
    const token = Cookies.get('token');
    const auth = token ? `Bearer ${token}` : '';
    config.headers.common['Authorization'] = auth;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  });

/*
axios.interceptors.response.use(
  (error) => {
    if (error.response.status == 401) {
      store.commit('setAuth', false);
      store.commit('setSession', {});
      router.push('/');
      alertFailed('Token Invalido!');
    }
  }
);
*/

// Base URL 
const baseURL = process.env.VUE_APP_ROOT_API;

export default {
  auth: createAuth(axios, baseURL),
  users: createUsers(axios, baseURL),
  agents: createAgents(axios, baseURL),
  sales: createSales(axios, baseURL),
  tickets: createTickets(axios, baseURL),
  contacts: createContacts(axios, baseURL),
  providers: createProviders(axios, baseURL),
  departaments: createDepartaments(axios, baseURL),
  gerencies: createGerencies(axios, baseURL),
  delegations: createDelegations(axios, baseURL),
  notifications: createNotifications(axios, baseURL),
  permissions: createPermissions(axios, baseURL),
}
