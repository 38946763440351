import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store";
import LoginPage from "../views/Login-page.vue";
import AgentsPage from "../views/desktop/agents/Agents.vue";
import AgentFormPage from "../views/desktop/agents/AgentForm";
import TicketsPage from "../views/desktop/tickets/Tickets.vue";
import NewTicketPage from "../views/desktop/tickets/New.vue";
import SalesPage from "../views/desktop/sales/Sales.vue";
import SaleFormPage from "../views/desktop/sales/SaleForm.vue";
import DepartamentsPage from "../views/desktop/departaments/Departaments.vue";
import DepartamentForm from "../views/desktop/departaments/DepartamentForm.vue";
import DepartamentPermission from "../views/desktop/departaments/DepartamentsPermissions.vue";
import DelegationsPage from "../views/desktop/delegations/Delegations.vue";
import DelegationForm from "../views/desktop/delegations/DelegationForm.vue";
import ContactsPage from "../views/desktop/contacts/Contacts.vue";
import ContactForm from "../views/desktop/contacts/ContactForm.vue";
import ProvidersPage from "../views/desktop/providers/Providers.vue";
import PlansPage from "../views/desktop/plans/PlansEditPage.vue";
import CapitaMinima from "../views/desktop/plans/FormCapitaMinima.vue";
import Monotributo from "../views/desktop/plans/FormMonotributo.vue";
import Vigencia from "../views/desktop/plans/FormVigencia.vue";
import Adherentes from "../views/desktop/plans/FormAdherentes.vue";
import home from "../views/desktop/Home.vue";
import store from '@/store'; 

Vue.use(VueRouter);

// Función de verificación de permisos
function hasPermission(permissionToCheck) {
  const userPermissions = store.getters["getSession"]["permissions"];
  return userPermissions && userPermissions.includes(permissionToCheck);
}

const routes = [
 
  {
    path: '/',
    component: home
  },
  
  {
    path: '/home',
    component: home
  },
  {
    path: '/login',
    component: LoginPage,
    meta: {
      requiresAuth: false,
    },
    
  },
  {
    path: '/agentes',
    component: AgentsPage,
    beforeEnter: (to, from, next) => {
      if (hasPermission('agentes.ver')) {
        next();
     }
    }
  },
  {
    path: '/agentes/nuevo',
    component: AgentFormPage,
    beforeEnter: (to, from,next) => {
      if(hasPermission('agentes.crear')) 
     {
        next();
     }
    }
  },
  {
    path: '/agentes/editar/:id',
    component: AgentFormPage,
    beforeEnter: (to, from, next) => {
      if (hasPermission('agentes.ver')) {
        next();
     }
    }
  },
  {
    path: '/departamentos',
    component: DepartamentsPage,
    beforeEnter: (to, from, next) => {
      if(hasPermission('departamentos.ver')) 
     {
        next();
     }
    }
  },
  {
    path: '/departamentos/nuevo',
    component: DepartamentForm,
    beforeEnter: (to, from, next) => {
      if(hasPermission('departamentos.crear')) 
     {
        next();
     }
    }
  },
  {
    path: '/departamentos/editar/:id',
    component: DepartamentForm,
    beforeEnter: (to, from, next) => {
      if(hasPermission('departamentos.editar')) 
     {
        next();
     }
    }
  },
  {
    path: '/departamentos/permissions/:id/:name',
    component: DepartamentPermission,
   
  },
  {
    path: '/delegaciones',
    component: DelegationsPage,
    beforeEnter: (to, from, next) => {
      if(hasPermission('delegaciones.ver')) 
     {
        next();
     }
    }
  },
  {
    path: '/delegaciones/nuevo',
    component: DelegationForm,
    beforeEnter: (to, from, next) => {
      if(hasPermission('delegaciones.crear')) 
     {
        next();
     }
    }
  },
  {
    path: '/delegaciones/editar/:id',
    component: DelegationForm,
    beforeEnter: (to, from, next) => {
      if(hasPermission('delegaciones.editar')) 
     {
        next();
     }
    }
  },
  {
    path: '/contactos',
    component: ContactsPage,
    beforeEnter: (to, from, next) => {
      if(hasPermission('contactos.ver')) 
     {
        next();
     }
    }
  },
  {
    path: '/contacts/editar/:id',
    component: ContactForm,
    beforeEnter: (to, from, next) => {
      if(hasPermission('contactos.editar')) 
     {
        next();
     }
    }
  },
  {
    path: '/consultas',
    component: TicketsPage,
    beforeEnter: (to, from, next) => {
      if(hasPermission('consultas.ver')) 
     {
        next();
     }
    }
  },
  {
    path: '/consultas/id/:id',
    component: TicketsPage,
    beforeEnter: (to, from, next) => {
      if(hasPermission('consultas.ver')) 
     {
        next();
     }
    }
  },
  {
    path: '/consultas/nuevo',
    component: NewTicketPage,
    beforeEnter: (to, from, next) => {
      if(hasPermission('consultas.crear')) 
     {
        next();
     }
    }
  },
  {
    path: '/ventas',
    component: SalesPage,
    beforeEnter: (to, from, next) => {
      if(hasPermission('ventas.ver')) 
     {
        next();
     }
    }
  },
  {
    path: '/ventas/id/:id',
    name: 'Venta',
    component: SaleFormPage,
    beforeEnter: (to, from, next) => {
      if(hasPermission('ventas.ver')) 
     {
        next();
     }
    }
  },
  {
    path: '/prestadores',
    component: ProvidersPage,
    beforeEnter: (to, from, next) => {
      if(hasPermission('prestadores.ver')) 
     {
        next();
     }
    }
  },
  
  {
    path: '/planes',
    component: PlansPage,

    children: [
      
      {
        path: '/capita',
        name: 'capita',
        component: CapitaMinima,
      },
      {
        path: '/monotributo',
        name: 'monotributo',
        component: Monotributo,
      },
      {
        path: '/vigencia',
        name: 'vigencia',
        component: Vigencia,
      },
      {
        path: '/adherentes',
        name: 'adherentes',
        component: Adherentes,
      },
    ]
  },
  
 
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});



export default router;
