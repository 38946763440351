<template>
    <div id="agents-table">
        <div class="card">
            <div class="card-content">
                <b-field grouped @keyup.enter="fetchAgents">
          <!-- Campo de búsqueda -->
          <b-field >
            <Search @value="getValue" />
          </b-field>

          <!-- Filtro por Departamento -->
          <b-field >
            <b-select v-model="filters.departament" placeholder="Todos los Departamentos">
              <option :value="null">Todos</option>
              <option v-for="departament in departaments" :key="departament.id" :value="departament.id">
                {{ departament.name }}
              </option>
            </b-select>
          </b-field>

          <!-- Filtro por Delegación -->
          <b-field >
            <b-select v-model="filters.delegation" placeholder="Todas las Delegaciones">
              <option :value="null">Todas</option>
              <option v-for="delegation in delegations" :key="delegation.id" :value="delegation.id">
                {{ delegation.name }}
              </option>
            </b-select>
          </b-field>

          <!-- Botón para aplicar filtros -->
          <b-field>
            <b-button type="is-primary" @click="fetchAgents">Aplicar Filtros</b-button>
          </b-field>
        </b-field>

                <b-table
                    hoverable
                    paginated
                    pagination-simple
                    backend-pagination
                    @page-change="onPageChange"
                    :scrollable="true"
                    :sticky-header="true"
                    :total="agents.total"
                    :per-page="agents.perPage"
                    height="350"
                    :loading="agents.loading"
                    :data="agents.isEmpty ? [] : agents.data">

                    <b-table-column field="name" label="Nombre" v-slot="props">
                        {{ props.row.name }} {{ props.row.lastname }}
                    </b-table-column>

                  

                    <b-table-column field="departament" label="Departamento" v-slot="props">
                        {{ props.row.departament }}
                    </b-table-column>

                    <b-table-column field="delegations" label="Delegación" v-slot="props">
                        {{ props.row.delegation }}
                    </b-table-column>

                    <b-table-column field="role" label="Rol" v-slot="props">
                        {{ props.row.role }}
                    </b-table-column>

                    <b-table-column label="" centered v-slot="props">
                        <span class="tag is-uppercase has-text-weight-bold" :class="(props.row.active) ? 'is-success' : 'is-danger'">
                            {{ (props.row.active) ? 'Activo' : 'Inactivo' }}
                        </span>
                    </b-table-column>

                    <b-table-column label="" centered v-slot="props" v-if="this.$hasPermission('agentes.editar')">
                        <b-button
                            tag="router-link"
                            :to="`/agentes/editar/${props.row.id}`"
                            icon-pack="fas"
                            icon-left="pencil-alt">
                        </b-button>
                    </b-table-column>

                    <template #empty>
                        <div class="has-text-centered">No se encontraron resultados</div>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
import Search from './Search.vue';

export default {
    name: 'AgentsTable',
    data() {
        return {
            agents: {
                data: [],
                total: 0,
                order: 'desc',
                page: 1,
                perPage: 5,
                loading: true,
                isEmpty: false,
                search: ''
            },
            departaments: [],
            delegations: [],
            filters: {
                departament: null,
                delegation: null
            }
        };
    },
    components: {
        Search
    },
    methods: {
        async fetchAgents() {
            try {
                this.agents.loading = true;

                const params = {
                    page: this.agents.page,
                    search: this.agents.search,
                    delegation_id: this.filters.delegation,
                    departament_id: this.filters.departament
                };

                const query = await this.$repositories.agents.index(params);

                this.agents.data = [];
                this.agents.total = query.data.users.total;

                if (query.data.users.data.length > 0) {
                    query.data.users.data.forEach(item => {
                        const ITEMS = {
                            id: item.id,
                            name: item.name,
                            lastname: item.lastname,
                            departament: item.departament.name,
                            delegation: item.delegations.name,
                            role: item.role.name,
                            active: Boolean(item.is_active)
                        };
                        this.agents.data.push(ITEMS);
                    });
                }

                this.agents.loading = false;
            } catch (error) {
                this.agents.loading = false;
                console.log(error);
            }
        },

      async fetchFilters() {
    try {
        const departamentsResponse = await this.$repositories.departaments.list();
        
        // Ordenar los departamentos alfabéticamente por el campo "name"
        this.departaments = departamentsResponse.data.departaments.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
    } catch (error) {
        console.log('Error al cargar departamentos:', error);
    }

    try {
        const delegationsResponse = await this.$repositories.delegations.list();

        // Ordenar las delegaciones alfabéticamente por el campo "name"
        this.delegations = delegationsResponse.data.delegations.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
    } catch (error) {
        console.log('Error al cargar delegaciones:', error);
    }
},


        onPageChange(page) {
            this.agents.page = page;
            this.fetchAgents();
        },

        getValue(value) {
            this.agents.search = value;
            this.agents.loading = true;
            this.fetchAgents();
        }
    },
    created() {
        this.fetchAgents();
        this.fetchFilters();
    }
};
</script>
