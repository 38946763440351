export default (axios, baseURL) => {
    return{
        index(search, date_from, date_to){
            return axios.get(`${baseURL}/api/sales?search=${search}&date_from=${date_from}&date_to=${date_to}`);
        },
        indexhome(){
            return axios.get(`${baseURL}/api/saleshome`);
        },
        show(id){
            return axios.get(`${baseURL}/api/sales/${id}`);
        },
        create(data){
            return axios.post(`${baseURL}/api/sales`,
                data
            );
        },
        update(id, data){
            return axios.post(`${baseURL}/api/sales/${id}`,
                data
            );
        },
        delete(id){
            return axios.delete(`${baseURL}/api/sales/${id}`);
        },
        stage(id, data){
            return axios.post(`${baseURL}/api/sales/stage/${id}`,
                data
            );
        },
        assign(id, data){
            return axios.post(`${baseURL}/api/sales/${id}/vendor`,
                data
            );
        },
        employerFiscalData(cuit){
            return axios.get(`${baseURL}/api/sales/employer/cuit/${cuit}`);
        },
        createEmployerData(data){
            return axios.post(`${baseURL}/api/sales/employer`,
                data
            );
        },
        updateEmployerData(id, data){
            return axios.post(`${baseURL}/api/sales/employer/${id}`,
                data
            ); 
        },
        createFamilyGroupData(data){
            return axios.post(`${baseURL}/api/sales/family`,
                data
            );
        },
        updateFamilyGroupData(id, data){
            return axios.post(`${baseURL}/api/sales/family/${id}`,
                data
            );
        },
        createDeclarationData(data){
            return axios.post(`${baseURL}/api/sales/declaration`,
                data
            );
        },
        updateDeclarationData(id, data){
            return axios.post(`${baseURL}/api/sales/declaration/${id}`,
                data
            );
        },
        createAuditData(data){
            return axios.post(`${baseURL}/api/sales/audit`,
                data
            );
        },
        updateAuditData(id, data){
            return axios.post(`${baseURL}/api/sales/audit/${id}`,
                data
            );
        },
        createDocumentationData(data){
            return axios.post(`${baseURL}/api/sales/documentation`,
                data
            );
        },
        deleteDocumentation(id){
            return axios.delete(`${baseURL}/api/sales/documentation/${id}`);
        },
        addChannelAndUnity(data){
            return axios.post(`${baseURL}/api/sales/channel-unity`,
                data
            );
        },
        downloadReport(date_from, date_to){
            return axios.get(`${baseURL}/api/report/sales?date_from=${date_from}&date_to=${date_to}`, {
                responseType: 'arraybuffer'
            })
            .then(response => {
                
              var date2 = date_to.toString();
              var date3 = date2.slice(0, -6);
              let blob = new Blob([response.data], { type: 'application/xlsx' })
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = 'Reporte_Ventas desde_:'+date_from+'_hasta_:'+date3+'.xlsx'
              link.click()
            })
            .catch(error => {
                    console.log(error);
                }
            );

        },
        assignState(data){
            return axios.post(`${baseURL}/api/states/sale`, data);
        },
        originsInherited(){
            return axios.get(`${baseURL}/api/sales/origins/inherited`);
        },
    }
}