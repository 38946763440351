<template>
  <div id="providers-table">
    <!-- Modal ver factura-->

    <b-modal v-model="showModal" title="Nueva Ventana Modal">
      <div class="modal-card" style="width: 100%">
        <section class="modal-card-body">
          <h4>INFORMACIÓN DE LA FACTURA</h4>
          <table class="table">
            <thead></thead>
            <tbody>
              <tr>
                <td>Factura N°: {{ invoiceData.id }}</td>
                <td>Prestador: {{ invoiceData.prestador }}</td>
                <td>Fecha: {{ invoiceData.fecha }}</td>

                <b-button
                  type="is-info"
                  size="is-small"
                  @click="downloadFile(invoiceData.url_invoice)"
                >
                  <b-icon
                    style="margin-right: 3px"
                    icon="fas fa-download"
                  ></b-icon>
                  Descargar archivo
                </b-button>
              </tr>
            </tbody>
          </table>

          <h4>REMITO PRESTACIONAL ASOCIADO</h4>
          <table class="table">
            <thead>
              <tr>
                <th>AFILIADO</th>
                <th>ORDEN</th>
                <th>PRÁCTICA</th>
                <th>IMPORTE</th>
                <th>CANTIDAD</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="service in this.invoiceData.providerService"
                :key="service.id"
              >
                <td>{{ service.dni_afiliado }}</td>
                <td>{{ service.order_no }}</td>
                <td>{{ service.practice_code }}</td>
                <td>{{ service.amount }}</td>
                <td>{{ service.cuantity }}</td>
                <td>
                  <b-button
                    @click="toggleDocs(service)"
                    type="is-info"
                    size="is-small"
                  >
                    <b-icon
                      style="margin-right: 3px"
                      icon="fas fa-eye"
                    ></b-icon>
                    {{
                      service.showDocs
                        ? "Ocultar Documentación"
                        : "Ver Documentación"
                    }}
                  </b-button>

                  <div v-if="service.showDocs" style="margin-top: 5px">
                    <p
                      v-for="(file, index) in service.service_files"
                      :key="file.id"
                    >
                      <b-button
                        type="is-info-Light"
                        size="is-small"
                        @click="downloadFile(file.path)"
                      >
                        <b-icon
                          style="margin-right: 3px"
                          icon="fas fa-download"
                        ></b-icon>
                        Descargar archivo {{ index + 1 }}
                      </b-button>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="buttons-outer-container">
            <div class="buttons-left-container">
              <!-- Botón izquierdo -->
              <b-button
                type="is-info"
                size="is-small"
                style="margin-right: 50px"
                v-if="invoiceData.estado != 'Sin Ingresar'"
                @click="returnState(invoiceData.id, invoiceData.estado)"
              >
                <b-icon
                  style="margin-right: 3px"
                  icon="fas fa-arrow-left"
                ></b-icon>
                Volver Estado Anterior
              </b-button>
            </div>

            <div class="buttons-right-container">
              <!-- Botones derecha -->
              <b-button
                type="is-info-Light"
                size="is-small"
                style="margin-right: 3px"
                @click="exportToXLSX()"
              >
                <b-icon
                  style="margin-right: 3px"
                  icon="fas fa-download"
                ></b-icon>
                Descargar Remito
              </b-button>

              <b-button
                type="is-info"
                size="is-small"
                style="margin-right: 3px"
                v-if="invoiceData.estado === 'Sin Ingresar'"
                @click="changeState(invoiceData.id, 'Ingresada')"
              >
                <b-icon
                  style="margin-right: 3px"
                  icon="fas fa-arrow-right"
                ></b-icon>
                Ingresar Factura
              </b-button>

              <b-button
                type="is-info"
                size="is-small"
                style="margin-right: 3px"
                v-if="invoiceData.estado === 'Ingresada'"
                @click="changeState(invoiceData.id, 'Liquidada')"
              >
                <b-icon
                  style="margin-right: 3px"
                  icon="fas fa-arrow-right"
                ></b-icon>
                Liquidar
              </b-button>

              <b-button
                type="is-danger"
                size="is-small"
                style="margin-right: 3px"
                @click="changeState(invoiceData.id, 'Rechazada')"
                v-if="invoiceData.estado != 'Liquidada'"
              >
                <b-icon style="margin-right: 3px" icon="fas fa-times"></b-icon>
                Rechazar
              </b-button>
            </div>
          </div>
        </section>
      </div>
    </b-modal>

    <div class="column is-12-touch is-6-desktop">
      <div class="level-left">
        <h1 class="title has-text-primary">Facturación</h1>
   
      </div>
    </div>

    <!--FIN  Modal ver factura-->

    <div class="card">
      <div class="card-content">
        <b-field grouped>
          <Search @value="getValue" />

          <b-field>
            <b-datepicker
              v-model="invoices.startDate"
              placeholder="Fecha desde"
           
            ></b-datepicker>
          </b-field>

          <b-field>
            <b-datepicker
              v-model="invoices.endDate"
              placeholder="Fecha hasta"
            
            ></b-datepicker>
          </b-field>

          <b-field v-if="this.$hasPermission('prestadores.listacompleta')">
            <b-select
              v-model="invoices.state"
              placeholder="Seleccionar Estado"
              expanded
              @input="getState"
            >
              <option value="">Todos los estados</option>
              <option value="Sin Ingresar">Sin Ingresar</option>
              <option value="Ingresada">Ingresada</option>
              <option value="Liquidada">Liquidada</option>
              <option value="Rechazada">Rechazada</option>
            </b-select>
          </b-field>
        </b-field>
        <b-table
          hoverable
          paginated
          pagination-simple
          backend-pagination
          :scrollable="true"
          :sticky-header="true"
          :total="invoices.total"
          :per-page="invoices.perPage"
          height="295"
          :loading="invoices.loading"
          :data="invoices.isEmpty ? [] : invoices.filter"
        >
          <b-table-column field="id" label="Registro" v-slot="props">
            {{ props.row.id }}
          </b-table-column>

          <b-table-column field="prestador" label="Prestador" v-slot="props">
            {{ props.row.prestador }}
          </b-table-column>

          <b-table-column field="fecha" label="Fecha" v-slot="props">
            {{ formatDate(props.row.fecha) }}
          </b-table-column>

          <b-table-column field="estado" label="Estado" v-slot="props">
            <span :class="getStateClass(props.row.estado)">{{
              props.row.estado
            }}</span>
          </b-table-column>

          <b-table-column label="Acciones">
            <template #default="props">
              <div class="buttons">
                <b-button
                  @click="descargarArchivo(props.row.url_invoice)"
                  type="is-info"
                  size="is-small"
                >
                  <b-icon icon="fas fa-download"></b-icon>
                </b-button>
                <b-button
                  @click="viewInvoice(props.row)"
                  type="is-info"
                  size="is-small"
                >
                  <b-icon icon="fas fa-ellipsis-v"></b-icon>
                </b-button>
              </div>
            </template>
          </b-table-column>

          <template #empty>
            <div class="has-text-centered">No se encontraron resultados</div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "./Search.vue";
import axios from "axios";
import { alertSuccess, alertFailed } from "@/helpers";
import ExcelJS from "exceljs";
import moment from 'moment';


export default {
  name: "ProvidersTable",
  components: {
    Search,
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_ROOT_API,
      showModal: false,
      invoiceData: "",
      providerService: "",
      searchValue: null,
      departament: "",
      invoices: {
        data: [],
        filter: [],
        total: 0,
        order: "desc",
        page: 1,
        perPage: 10,
        loading: false,
        isEmpty: true,
        search: "",
        startDate: "",
        endDate: "",
        state: "",
      },
    };
  },
  methods: {
    

    async fetchInvoices() {
      
      // Formatear las fechas antes de enviarlas
      const formattedStartDate = this.invoices.startDate ? moment(this.invoices.startDate).format('YYYY-MM-DD') : '';
      const formattedEndDate = this.invoices.endDate ? moment(this.invoices.endDate).format('YYYY-MM-DD') : '';
      
      if(this.$hasPermission('prestadores.listasiningresar')){
        this.invoices.state = "Sin Ingresar"
      }
      try {
        this.invoices.loading = true;
        const response = await this.$repositories.providers.invoiceList(
           this.$store.getters.getSession.departament_id ,  this.invoices.search,formattedStartDate,formattedEndDate,this.invoices.state,
        );
        console.log(response);

        const results = response.data;

        results.sort((a, b) => b.id - a.id);

        results.forEach((item) => {
          item.formattedDate = this.formatDate(item.created_at);
        });
       
          this.invoices.data = [];
          this.invoices.filter = [];
          this.invoices.total = results.length;

          results.forEach((item) => {
            const ITEMS = {
              id: item.id,
              prestador: item.provider.business_name,
              fecha: item.formattedDate,
              estado: item.state,
              url_invoice: item.url_invoice,
              providerService: item.provider_services,
              showDocs: false,
            };

            this.invoices.data.push(ITEMS);
            this.invoices.filter.push(ITEMS);
          });
        

        this.invoices.isEmpty = this.invoices.data.length === 0;
        this.invoices.loading = false;
      } catch (error) {
        console.log(error);
        this.invoices.loading = false;
        this.invoices.isEmpty = true;
      }
    },

    async exportToXLSX() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Hoja1");

      // Agregar encabezados
      const headers = ['AFILIADO', 'ORDEN', 'PRÁCTICA', 'IMPORTE', 'CANTIDAD'];
      worksheet.addRow(headers);

      // Agregar filas de datos
      this.invoiceData.providerService.forEach((service) => {
        const total = service.amount * service.cuantity; // Calcula el total
        const totalFormatted = total.toLocaleString("es-ES", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }); // Formatea el total sin símbolo de moneda
        const rowData = [
          service.dni_afiliado,
          service.order_no,
          service.practice_code,
          total,
          service.cuantity,
        ];
        worksheet.addRow(rowData);
      });

      const columnImporte = worksheet.getColumn(4);
      columnImporte.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
        if (rowNumber === 1) {
          // La primera fila es el encabezado, no necesitas establecer el tipo de celda
          return;
        }

       cell.numFmt = "0.00"; 
      });

      // Generar el archivo XLSX
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "tabla.xlsx";
      a.click();
      URL.revokeObjectURL(url);
    },

    returnState(id, currentState) {
      let newState = "";

      if (currentState === "Ingresada") {
        newState = "Sin Ingresar";
      } else if (currentState === "Liquidada") {
        newState = "Ingresada";
      }

      if (newState !== "") {
        const confirmationMessage = `¿Está seguro de cambiar el estado de "${currentState}" a "${newState}"?`;

        if (confirm(confirmationMessage)) {
          // Realiza la solicitud POST con el nuevo estado
          axios
            .post(`${this.baseURL}/api/v1/prestador/change_state/${id}`, {
              state: newState,
            })
            .then((response) => {
              alertSuccess("Se actualizó el estado!");
              this.showModal = false;
              this.fetchInvoices();
            })
            .catch((error) => {
              console.error("Error al cambiar el estado:", error);
            });
        }
      }
    },

    changeState(id, newState) {
      const confirmationMessage = "¿Está seguro de cambiar de estado?";

      if (confirm(confirmationMessage)) {
        // Realiza una solicitud POST a tu backend con los datos
        axios
          .post(`${this.baseURL}/api/v1/prestador/change_state/${id}`, {
            state: newState,
          })
          .then((response) => {
            alertSuccess("Se actualizó el estado!");
            this.showModal = false;
            this.fetchInvoices();
          })
          .catch((error) => {
            console.error("Error al cambiar el estado:", error);
          });
      }
    },

    downloadFile(path) {
      window.open(path, "_blank");
    },

    toggleDocs(service) {
      service.showDocs = !service.showDocs;
      this.$forceUpdate();
      console.log(service);
    },

    viewInvoice(data) {
      this.showModal = true;
      this.invoiceData = data;
    },

    getStateClass(estado) {
      if (estado === "Sin Ingresar") {
        return "yellow-bg"; // CSS class for yellow background
      } else if (estado === "Ingresada") {
        return "green-bg"; // CSS class for green background
      } else if (estado === "Rechazada") {
        return "red-bg"; // CSS class for red background
      } else if (estado === "Liquidada") {
        return "blue-bg"; // CSS class for blue background
      } else {
        return ""; // No additional class
      }
    },

    formatDate(dateString) {
      const dateParts = dateString.split("T")[0].split("-");
      return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    },

    descargarArchivo(enlace) {
      console.log(enlace);
      // Crear un enlace temporal para la descarga
      const link = document.createElement("a");
      link.href = enlace;
      link.target = "_blank"; // Abrir en una nueva pestaña (opcional)
      link.download = enlace.substring(enlace.lastIndexOf("/") + 1); // Establecer el nombre de archivo

      // Disparar el evento de clic en el enlace
      link.click();
    },

     getValue(value) {
      this.invoices.search = value;
      this.invoices.loading = true;
      this.fetchInvoices();
    },

     getState() {   
      this.invoices.loading = true;
      this.fetchInvoices();
    },
  },

  created() {
    this.fetchInvoices();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
#providers-table {
  form {
    margin-bottom: 1rem;
  }
}
</style>

<style scoped>
.yellow-bg {
  background-color: yellow;
}

.icon {
  margin-right: 5px; /* Ajusta el valor según tu preferencia */
}

.green-bg {
  background-color: green;
  color: white; /* Change text color for better contrast */
}

.red-bg {
  background-color: red;
  color: white; /* Change text color for better contrast */
}

.blue-bg {
  background-color: blue;
  color: white; /* Change text color for better contrast */
}

.buttons-outer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.buttons-left-container {
  display: flex;
  align-items: center;
}

.buttons-right-container {
  display: flex;
  align-items: center;
}
</style>
