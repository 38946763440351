<template>
  <div id="new-agent-page">
    <b-tabs>
      <!-- Pestaña para el formulario -->
      <b-tab-item label="Información del Agente">
        <AgentForm />
      </b-tab-item>

      <!-- Pestaña para el timeline -->
      <b-tab-item label="Historial del Agente">
        <AgentTimeline />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import AgentForm from '@/components/Agent-form.vue';
import AgentTimeline from '@/components/Agent-timeline.vue';

export default {
  name: 'AgentFormPage',
  components: {
    AgentForm,
    AgentTimeline
  }
};
</script>
