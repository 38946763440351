<template>
  <div>
    <Loader :active="loading"></Loader>
    <div class="card">
      <div class="card-content">
        <h4>Consultas</h4>
        <b-field>
          <b-switch v-model="consultas"></b-switch>
        </b-field>

        <div v-if="consultas">
          <div class="columns">
            <div
              v-for="(rol, rolNombre) in roles.consultas"
              :key="rolNombre"
              class="column"
            >
              <p>{{ rolNombre }}</p>
              <b-field
                v-for="(permiso, permisoNombre) in rol.permisos"
                :key="permisoNombre"
              >
                <b-switch class="is-small" v-model="permiso.valor">{{
                  permiso.etiqueta
                }}</b-switch>
              </b-field>
            </div>
          </div>
        </div>

        <h4>Ventas</h4>
        <b-field>
          <b-switch v-model="ventas"></b-switch>
        </b-field>

        <div v-if="ventas">
          <div class="columns">
            <div
              v-for="(rol, rolNombre) in roles.ventas"
              :key="rolNombre"
              class="column"
            >
              <p>{{ rolNombre }}</p>
              <b-field
                v-for="(permiso, permisoNombre) in rol.permisos"
                :key="permisoNombre"
              >
                <b-switch class="is-small" v-model="permiso.valor">{{
                  permiso.etiqueta
                }}</b-switch>
              </b-field>
            </div>
          </div>
        </div>

        <h4>Delegaciones</h4>
        <b-field>
          <b-switch v-model="delegaciones"></b-switch>
        </b-field>

        <div v-if="delegaciones">
          <div class="columns">
            <div
              v-for="(rol, rolNombre) in roles.delegaciones"
              :key="rolNombre"
              class="column"
            >
              <p>{{ rolNombre }}</p>
              <b-field
                v-for="(permiso, permisoNombre) in rol.permisos"
                :key="permisoNombre"
              >
                <b-switch class="is-small" v-model="permiso.valor">{{
                  permiso.etiqueta
                }}</b-switch>
              </b-field>
            </div>
          </div>
        </div>

        <h4>Prestadores</h4>
        <b-field>
          <b-switch v-model="prestadores"></b-switch>
        </b-field>

        <div v-if="prestadores">
          <div class="columns">
            <div
              v-for="(rol, rolNombre) in roles.prestadores"
              :key="rolNombre"
              class="column"
            >
              <p>{{ rolNombre }}</p>
              <b-field
                v-for="(permiso, permisoNombre) in rol.permisos"
                :key="permisoNombre"
              >
                <b-switch class="is-small" v-model="permiso.valor">{{
                  permiso.etiqueta
                }}</b-switch>
              </b-field>
            </div>
          </div>
        </div>

        <h4>Contactos</h4>
        <b-field>
          <b-switch v-model="contactos"></b-switch>
        </b-field>

        <div v-if="contactos">
          <div class="columns">
            <div
              v-for="(rol, rolNombre) in roles.contactos"
              :key="rolNombre"
              class="column"
            >
              <p>{{ rolNombre }}</p>
              <b-field
                v-for="(permiso, permisoNombre) in rol.permisos"
                :key="permisoNombre"
              >
                <b-switch class="is-small" v-model="permiso.valor">{{
                  permiso.etiqueta
                }}</b-switch>
              </b-field>
            </div>
          </div>
        </div>

        <h4>Cuotas y minimos</h4>
        <b-field>
          <b-switch v-model="cuotasyminimos"></b-switch>
        </b-field>

        <div v-if="cuotasyminimos">
          <div class="columns">
            <div
              v-for="(rol, rolNombre) in roles.cuotasyminimos"
              :key="rolNombre"
              class="column"
            >
              <p>{{ rolNombre }}</p>
              <b-field
                v-for="(permiso, permisoNombre) in rol.permisos"
                :key="permisoNombre"
              >
                <b-switch class="is-small" v-model="permiso.valor">{{
                  permiso.etiqueta
                }}</b-switch>
              </b-field>
            </div>
          </div>
        </div>

        <h4>Agentes</h4>
        <b-field>
          <b-switch v-model="agentes"></b-switch>
        </b-field>

        <div v-if="agentes">
          <div class="columns">
            <div
              v-for="(rol, rolNombre) in roles.agentes"
              :key="rolNombre"
              class="column"
            >
              <p>{{ rolNombre }}</p>
              <b-field
                v-for="(permiso, permisoNombre) in rol.permisos"
                :key="permisoNombre"
              >
                <b-switch class="is-small" v-model="permiso.valor">{{
                  permiso.etiqueta
                }}</b-switch>
              </b-field>
            </div>
          </div>
        </div>

        <h4>Departamentos</h4>
        <b-field>
          <b-switch v-model="departamentos"></b-switch>
        </b-field>

        <div v-if="departamentos">
          <div class="columns">
            <div
              v-for="(rol, rolNombre) in roles.departamentos"
              :key="rolNombre"
              class="column"
            >
              <p>{{ rolNombre }}</p>
              <b-field
                v-for="(permiso, permisoNombre) in rol.permisos"
                :key="permisoNombre"
              >
                <b-switch class="is-small" v-model="permiso.valor">{{
                  permiso.etiqueta
                }}</b-switch>
              </b-field>
            </div>
          </div>
        </div>
        <br />
        <br />
        <b-button @click="update">Guardar</b-button>
        <b-button tag="router-link" :to="`/departamentos`" icon-pack="fas"
          >Volver
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader.vue";
import { alertSuccess, alertFailed } from "@/helpers";
export default {
  name: "DepartamentsPermissions",
  components: {
    Loader,
  },
  data() {
    return {
      consultas: false,
      ventas: false,
      delegaciones: false,
      prestadores: false,
      contactos: false,
      cuotasyminimos: false,
      agentes: false,
      departamentos: false,
      loading: false,

      roles: {
        //consultas
        consultas: {
          Supervisor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              //migerencia: { etiqueta: "De mi gerencia", valor: false },
              midepartamento: { etiqueta: "Solo de mi departamento", valor: false },
              miusuario: { etiqueta: "Solo de mi usuario", valor: false },
              crear: { etiqueta: "Crear", valor: false },
            },
          },
          Vendedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
             // migerencia: { etiqueta: "De mi gerencia", valor: false },
              midepartamento: { etiqueta: "Solo de mi departamento", valor: false },
              miusuario: { etiqueta: "Solo de mi usuario", valor: false },
              crear: { etiqueta: "Crear", valor: false },
            },
          },
          Operario: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              //migerencia: { etiqueta: "De mi gerencia", valor: false },
              midepartamento: { etiqueta: "Solo de mi departamento", valor: false },
              miusuario: { etiqueta: "Solo de mi usuario", valor: false },
              crear: { etiqueta: "Crear", valor: false },
            },
          },
          Auditor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              //migerencia: { etiqueta: "De mi gerencia", valor: false },
              midepartamento: { etiqueta: "Solo de mi departamento", valor: false },
              miusuario: { etiqueta: "Solo de mi usuario", valor: false },
              crear: { etiqueta: "Crear", valor: false },
            },
          },
          Veedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              //migerencia: { etiqueta: "De mi gerencia", valor: false },
              midepartamento: { etiqueta: "Solo de mi departamento", valor: false },
              miusuario: { etiqueta: "Solo de mi usuario", valor: false },
              crear: { etiqueta: "Crear", valor: false },
            },
          },
          Tercerizado: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
             // migerencia: { etiqueta: "De mi gerencia", valor: false },
              midepartamento: { etiqueta: "Solo de mi departamento", valor: false },
              miusuario: { etiqueta: "Solo de mi usuario", valor: false },
              crear: { etiqueta: "Crear", valor: false },
            },
          },
        },
        //ventas
        ventas: {
          Supervisor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
            },
          },
          Vendedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
            },
          },
          Operario: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
            },
          },
          Auditor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
            },
          },
          Veedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
            },
          },
          Tercerizado: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
            },
          },
        },

        //delegaciones
        delegaciones: {
          Supervisor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
            },
          },
          Vendedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
            },
          },
          Operario: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
            },
          },
          Auditor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
            },
          },
          Veedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
            },
          },
          Tercerizado: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
            },
          },
        },

        //prestadores

        prestadores: {
          Supervisor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              listasiningresar: {
                etiqueta: "Lista sin ingresar",
                valor: false,
              },
              listacompleta: { etiqueta: "Lista completa", valor: false },
            },
          },
          Vendedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              listasiningresar: {
                etiqueta: "Lista sin ingresar",
                valor: false,
              },
              listacompleta: { etiqueta: "Lista completa", valor: false },
            },
          },
          Operario: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              listasiningresar: {
                etiqueta: "Lista sin ingresar",
                valor: false,
              },
              listacompleta: { etiqueta: "Lista completa", valor: false },
            },
          },
          Auditor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              listasiningresar: {
                etiqueta: "Lista sin ingresar",
                valor: false,
              },
              listacompleta: { etiqueta: "Lista completa", valor: false },
            },
          },
          Veedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              listasiningresar: {
                etiqueta: "Lista sin ingresar",
                valor: false,
              },
              listacompleta: { etiqueta: "Lista completa", valor: false },
            },
          },
          Tercerizado: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              listasiningresar: {
                etiqueta: "Lista sin ingresar",
                valor: false,
              },
              listacompleta: { etiqueta: "Lista completa", valor: false },
            },
          },
        },

        //contactos
        contactos: {
          Supervisor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              verconsultas: { etiqueta: "Ver consultas", valor: false },
            },
          },
          Vendedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              verconsultas: { etiqueta: "Ver consultas", valor: false },
            },
          },
          Operario: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              verconsultas: { etiqueta: "Ver consultas", valor: false },
            },
          },
          Auditor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              verconsultas: { etiqueta: "Ver consultas", valor: false },
            },
          },
          Veedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              verconsultas: { etiqueta: "Ver consultas", valor: false },
            },
          },
          Tercerizado: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              verconsultas: { etiqueta: "Ver consultas", valor: false },
            },
          },
        },

        //contactos
        contactos: {
          Supervisor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              verconsultas: { etiqueta: "Ver consultas", valor: false },
            },
          },
          Vendedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              verconsultas: { etiqueta: "Ver consultas", valor: false },
            },
          },
          Operario: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              verconsultas: { etiqueta: "Ver consultas", valor: false },
            },
          },
          Auditor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              verconsultas: { etiqueta: "Ver consultas", valor: false },
            },
          },
          Veedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              verconsultas: { etiqueta: "Ver consultas", valor: false },
            },
          },
          Tercerizado: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              verconsultas: { etiqueta: "Ver consultas", valor: false },
            },
          },
        },

        //cuotas y minimos

        cuotasyminimos: {
          Supervisor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
            },
          },
          Vendedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
            },
          },
          Operario: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
            },
          },
          Auditor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
            },
          },
          Veedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
            },
          },
          Tercerizado: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
            },
          },
        },

        //agentes

        agentes: {
          Supervisor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              eliminar: { etiqueta: "Eliminar", valor: false },
              editarmiperfil: { etiqueta: "Editar mi perfil", valor: false },
            },
          },
          Vendedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              eliminar: { etiqueta: "Eliminar", valor: false },
              editarmiperfil: { etiqueta: "Editar mi perfil", valor: false },
            },
          },
          Operario: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              eliminar: { etiqueta: "Eliminar", valor: false },
              editarmiperfil: { etiqueta: "Editar mi perfil", valor: false },
            },
          },
          Auditor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              eliminar: { etiqueta: "Eliminar", valor: false },
              editarmiperfil: { etiqueta: "Editar mi perfil", valor: false },
            },
          },
          Veedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              eliminar: { etiqueta: "Eliminar", valor: false },
              editarmiperfil: { etiqueta: "Editar mi perfil", valor: false },
            },
          },
          Tercerizado: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              eliminar: { etiqueta: "Eliminar", valor: false },
              editarmiperfil: { etiqueta: "Editar mi perfil", valor: false },
            },
          },
        },

        //departamentos
        departamentos: {
          Supervisor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              eliminar: { etiqueta: "Eliminar", valor: false },
            },
          },
          Vendedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              eliminar: { etiqueta: "Eliminar", valor: false },
            },
          },
          Operario: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              eliminar: { etiqueta: "Eliminar", valor: false },
            },
          },
          Auditor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              eliminar: { etiqueta: "Eliminar", valor: false },
            },
          },
          Veedor: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              eliminar: { etiqueta: "Eliminar", valor: false },
            },
          },
          Tercerizado: {
            permisos: {
              ver: { etiqueta: "Ver", valor: false },
              crear: { etiqueta: "Crear", valor: false },
              editar: { etiqueta: "Editar", valor: false },
              eliminar: { etiqueta: "Eliminar", valor: false },
            },
          },
        },
      },
    };
  },

  watch: {
    "roles.consultas.Supervisor.permisos.midepartamento.valor": function (
      newValue
    ) {
      if (newValue) {
        this.roles.consultas.Supervisor.permisos.miusuario.valor = false;
      }
    },
    "roles.consultas.Supervisor.permisos.miusuario.valor": function (newValue) {
      if (newValue) {
        this.roles.consultas.Supervisor.permisos.midepartamento.valor = false;
      }
    },

    "roles.consultas.Vendedor.permisos.midepartamento.valor": function (
      newValue
    ) {
      if (newValue) {
        this.roles.consultas.Vendedor.permisos.miusuario.valor = false;
      }
    },
    "roles.consultas.Vendedor.permisos.miusuario.valor": function (newValue) {
      if (newValue) {
        this.roles.consultas.Vendedor.permisos.midepartamento.valor = false;
      }
    },

    "roles.consultas.Operario.permisos.midepartamento.valor": function (
      newValue
    ) {
      if (newValue) {
        this.roles.consultas.Operario.permisos.miusuario.valor = false;
      }
    },
    "roles.consultas.Operario.permisos.miusuario.valor": function (newValue) {
      if (newValue) {
        this.roles.consultas.Operario.permisos.midepartamento.valor = false;
      }
    },

       "roles.consultas.Auditor.permisos.midepartamento.valor": function (
      newValue
    ) {
      if (newValue) {
        this.roles.consultas.Auditor.permisos.miusuario.valor = false;
      }
    },
    "roles.consultas.Auditor.permisos.miusuario.valor": function (newValue) {
      if (newValue) {
        this.roles.consultas.Auditor.permisos.midepartamento.valor = false;
      }
    },

    "roles.consultas.Veedor.permisos.midepartamento.valor": function (
      newValue
    ) {
      if (newValue) {
        this.roles.consultas.Veedor.permisos.miusuario.valor = false;
      }
    },
    "roles.consultas.Veedor.permisos.miusuario.valor": function (newValue) {
      if (newValue) {
        this.roles.consultas.Veedor.permisos.midepartamento.valor = false;
      }
    },

        "roles.consultas.Tercerizado.permisos.midepartamento.valor": function (
      newValue
    ) {
      if (newValue) {
        this.roles.consultas.Tercerizado.permisos.miusuario.valor = false;
      }
    },
    "roles.consultas.Tercerizado.permisos.miusuario.valor": function (newValue) {
      if (newValue) {
        this.roles.consultas.Tercerizado.permisos.midepartamento.valor = false;
      }
    },

  },

  async created() {
    try {
      // Llamada a la función para cargar los permisos
      await this.fetchPermissions();

      // Lista de secciones
      const secciones = [
        "consultas",
        "ventas",
        "delegaciones",
        "prestadores",
        "contactos",
        "cuotasyminimos",
        "agentes",
        "departamentos",
      ];

      // Verificar permisos para cada sección
      secciones.forEach((seccion) => {
        const seccionPermisos = this.roles[seccion];

        for (const rol in seccionPermisos) {
          if (
            Object.values(seccionPermisos[rol].permisos).some(
              (permiso) => permiso.valor
            )
          ) {
            this[seccion] = true;
            break;
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    async update() {
      try {
        console.log("action", "create");
        this.loading = true;
        const data = new FormData();
        data.append("roles", JSON.stringify(this.roles));
        data.append("departament_id", this.$route.params.id);

        await this.$repositories.permissions.update(data);

        //this.$router.push("/departamentos");
        alertSuccess("Registro exitoso!");
        this.loading = false;
      } catch (error) {
        this.loading = false;
        alertFailed("Ha ocurrido un error!");
      }
    },

    async fetchPermissions() {
      try {
        this.loading = true;
        const id = this.$route.params.id;
        const query = await this.$repositories.permissions.permissionsByDepartament(
          id
        );
        const items = query.data.permissions;

        // Recorrer los permisos obtenidos del backend
        items.forEach((permiso) => {
          const seccion = permiso.permission.split(".")[0]; // Obtener la sección del permiso

          if (this.roles[seccion] && this.roles[seccion][permiso.role]) {
            // Verificar si la sección y el rol existen en los roles declarados en el componente
            const permisoNombre = permiso.permission.split(".")[1]; // Obtener el nombre del permiso
            this.roles[seccion][permiso.role].permisos[
              permisoNombre
            ].valor = true;
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
