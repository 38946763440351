export default(axios, baseURL) => {
    return {
        index(params) {
            return axios.get(`${baseURL}/api/users`, { params });
        },
        withSupervisor(){
            return axios.get(`${baseURL}/api/users/withSupervisor`)
        },
        withMarketerSupervisor(id){
            return axios.get(`${baseURL}/api/users/withMarketerSupervisor/${id}`)
        },
        auditors(){
            return axios.get(`${baseURL}/api/auditors`)
        },
        positions(){
            return axios.get(`${baseURL}/api/positions`)
        },
        departament(id){
            return axios.get(`${baseURL}/api/users/departament/${id}`)
        },
        delegations(id){
            return axios.get(`${baseURL}/api/users/delegations/${id}`)
        },
        show(id){
            return axios.get(`${baseURL}/api/users/${id}`)
        },
        create(data){
            return axios.post(`${baseURL}/api/users`, 
                data
            );
        },
        update(id, data){
            return axios.post(`${baseURL}/api/users/${id}`,
                data
            );
        },
        delete(id){
            return axios.delete(`${baseURL}/api/users/${id}`);
        },
        roles(){
            return axios.get(`${baseURL}/api/roles`);
        },

        getTimelines(page = 1, userId = null, startDate = null, endDate = null) {
            let url = `${baseURL}/api/timelines?page=${page}`;
            if (userId) url += `&user_id=${userId}`;
            if (startDate) url += `&start_date=${startDate}`;
            if (endDate) url += `&end_date=${endDate}`;
          
            return axios.get(url);
          }
          
    }
}