<template>
    <b-modal
        v-model="active"
        has-modal-card
        :can-cancel="false">
        <Loader :active="loading"></Loader>
        
        <form @submit.prevent="create">
            <div class="modal-card" style="width:350">
                <header class="modal-card-head">
                    <div class="level modal-card-title mb-0">
                        <div class="level-left is-block">
                            <div class="level-item is-justify-content-flex-start mb-2"> 
                                <h4 class="title is-4 has-text-primary is-uppercase">Consulta número</h4>
                                <h4 class="title is-4 has-text-dark ml-3">#{{ ticketId(ticket.id) }}</h4>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        class="delete"
                        @click="active = false"
                    />
                </header>
                <div class="modal-card-body">
                    <!--User Data-->
                    <b-collapse :open="false" animation="slide" position="is-top" aria-id="contentIdForA11y1">
                        <template #trigger="props">
                            <div class="is-flex">
                                <h4 class="title is-4 has-text-primary mb-0 mr-2">{{ ticket.contact.name }}</h4>
                                <a class="has-text-grey" aria-controls="contentIdForA11y1">
                                    {{ !props.open ? 'ver más' : 'ver menos' }}
                                    <b-icon pack="fas" :icon="!props.open ? 'sort-down' : 'sort-up'"></b-icon>
                                </a>
                            </div> 
                        </template>
                        <p class="is-grey">Dni: {{ ticket.contact.dni }}</p>
                        <p class="is-grey">Email: {{ ticket.contact.email }}</p>
                        <p class="is-grey">Tel: {{ ticket.contact.phone }}</p>
                        <p class="is-grey">Localidad: {{ `${ticket.contact.city}, ${ticket.contact.province}` }}</p>
                    </b-collapse>
                    <!--Message Section-->
                    <section class="messages">
                        <ul v-chat-scroll>
                            <li v-for="(message, index) in ticket.messages"
                                :key="index">
                                <!-- Message Received -->
                                <div v-if="message.type == 'received' && message.files.length > 0">
                                    <div class="control mr-2 mb-2" v-for="(file, index) in message.files" :key="index">
                                        <b-field>
                                            <a :href="file.path" download>
                                                <b-tag 
                                                    aria-close-label="Close tag">
                                                    <div class="media is-align-items-center">
                                                        <div class="media-left">
                                                            <b-icon
                                                                pack="fas"
                                                                :icon="(file.type == 'image/jpg' || file.type == 'image/png') ? 'image' : 'paperclip'">
                                                            </b-icon>
                                                        </div>
                                                        <div class="media-content">
                                                            <p>{{ urlShort(file.path) }}</p>
                                                        </div>
                                                    </div>
                                                </b-tag>
                                            </a>
                                        </b-field>
                                    </div>
                                </div>
                                <div v-if="(message.type == 'received' && message.content != '')" class="message-box">
                                    <b-message>
                                        <b-input
                                            v-model="message.content" 
                                            type="textarea"
                                            disabled>
                                        </b-input>
                                        <!--<p class="has-text-grey mb-3">{{ message.content }}</p>-->
                                        <small class="has-text-grey">{{ message.date }} hs</small>
                                    </b-message>
                                    <div class="avatar">
                                        <figure class="image is-48x48 ml-3">
                                            <img class="is-rounded" src="https://bulma.io/images/placeholders/48x48.png">
                                        </figure>
                                    </div>
                                </div>
                                <!-- Message Sended -->           
                                <div v-if="message.type == 'sended' && message.files.length > 0">
                                    <div class="control mr-2 mb-2" v-for="(file, index) in message.files" :key="index">
                                        <b-field>
                                            <a :href="file.path" download >
                                                <b-tag 
                                                    style="margin-left:auto;"
                                                    aria-close-label="Close tag">
                                                    <div class="media is-align-items-center">
                                                        <div class="media-left">
                                                            <b-icon
                                                                pack="fas"
                                                                :icon="(file.type == 'image/jpg' || file.type == 'image/png') ? 'image' : 'paperclip'">
                                                            </b-icon>
                                                        </div>
                                                        <div class="media-content">
                                                            <p>{{ urlShort(file.path) }}</p>
                                                        </div>
                                                    </div>
                                                </b-tag>
                                            </a>
                                        </b-field>
                                    </div>
                                </div>
                                <div v-if="(message.type == 'sended' && message.content != '')" class="message-box me">
                                    <div class="avatar">
                                        <figure class="image is-48x48 mr-3">
                                            <img class="is-rounded" src="/img/logo_osfatun_sidebar.png">
                                        </figure>
                                    </div>
                                    <b-message>
                                        <p class="has-text-white mb-3">{{ message.content }}</p>
                                        <small class="has-text-white">{{ message.date }} hs</small>
                                    </b-message>
                                </div>
                            </li>
                        </ul>
                    </section>
                </div>
                <div class="modal-card-foot"></div>
            </div>
        </form>
    </b-modal>
</template>

<script>
    import Loader from "@/components/Loader.vue";
    import {fillString} from "@/helpers";
    export default {
        name: 'SaleModalTicket',
        components:{
            Loader
        },
        data(){
            return{
                loading: false,
                active: false,
                ticket:{
                    id: undefined,
                    statuses: {
                        status:''
                    },
                    agent:{
                        id: undefined,
                        name: '',
                        lastname: '',
                        avatar: null,
                    },
                    departament:{
                        id: undefined,
                        name:''
                    },
                    contact:{
                        id: undefined,
                        name: '',
                        lastname: '',
                        email: '',
                        phone: '',
                        dni: '',
                        city: '',
                        province:''
                    },
                    messages:[],
                    files:[],
                },
            }
        },
        methods:{
            async fetchTicket(id){
                try {
                    this.loading = true;
                    const query = await this.$repositories.tickets.show(id);

                    const ticket = query.data.ticket;
                    this.ticket.id = ticket.id;
                    this.ticket.contact.id = ticket.contact.id;
                    this.ticket.contact.name = ticket.contact.fullname;
                    this.ticket.contact.email = ticket.contact.email;
                    this.ticket.contact.phone = ticket.contact.phone;
                    this.ticket.contact.dni = ticket.contact.dni;
                    this.ticket.contact.city = ticket.contact.city;
                    this.ticket.contact.province = ticket.contact.province;
                    this.ticket.departament.id = ticket.departament.id;
                    this.ticket.departament.name = ticket.departament.name;
                    this.ticket.agent.id = (ticket.user) ? ticket.user.id : undefined;
                    this.ticket.agent.name = (ticket.user) ? ticket.user.name : '';
                    this.ticket.agent.lastname = (ticket.user) ? ticket.user.lastname : '';
                    this.ticket.agent.avatar = (ticket.user) ? ticket.user.avatar : null;

                    const messages = ticket.messages;
                    this.ticket.messages = [];
                    messages.forEach(message => {
                        const ITEMS = {
                            content: message.content,
                            date: message.created_at,
                            type: message.type,
                            files: []
                        }
                        if(message.file.length > 0){
                            const files = message.file;
                            files.forEach(file => {
                                ITEMS.files.push(file);
                            });
                        }
                        this.ticket.messages.push(ITEMS);
                    });
                    this.active = true;
                    this.loading = false;

                } catch (error) {
                    console.log(error);
                }
            },
            ticketId(value){
                return fillString(value, 4);
            },
        },
        created(){
            this.$EventBus.$on('saleTicketModal', (id) => {
			    this.fetchTicket(id);
		    });
        },
    }
</script>

<style lang="scss" scoped>
    .messages{
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top: 2px solid $light;
        ul{
            max-height: 350px;
            overflow-y: auto;
        }
        .message-box{
            display: flex;
            align-items: flex-end;
            margin-bottom: 1rem;
            .message{
                border-radius: 0!important;
                ~.message-body{
                    border-radius: 0!important;
                    border-color: transparent!important;
                }
            }
            &.me{
                .message{
                    background: rgb(0,147,191);
                    background: linear-gradient(45deg, rgba(0,147,191,1) 35%, rgba(0,117,143,1) 100%);;
                    .message-body{
                        color: $white;
                    }
                }
            } 
        }
    }
</style>