<template>
  <div id="sale-form">
    <Loader :active="loading"></Loader>

    <!--Navbar Top-->
    <nav class="navbar is-fixed-top">
      <router-link :to="'/ventas'">
        <img src="/img/logo-horizontal.png" alt="Logo osfatun" width="150" />
      </router-link>
      <div class="navbar-item column is-flex is-align-self-start block">
        <form v-on:submit.prevent="addChannelUnity" method="post">
          <input type="hidden" name="id" :value="sale.id" />
          <div
            class="columns is-flex is-align-items-center is-align-self-start mt-3 mx-auto"
          >
            <div class="column">
              <b-field label="Canal" horizontal>
                <b-select
                  placeholder="Elija un canal"
                  v-model="channel_id"
                  name="channel_id"
                >
                  <option :value="0">Comercializadora</option>
                  <option :value="1">Asesor Propio</option>
                  <option :value="2">Call Center</option>
                  <option :value="3">Delegación</option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Unidad" horizontal>
                <b-select
                  placeholder="Elija una unidad"
                  v-model="unity_id"
                  name="unity_id"
                >
                  <option
                    v-for="(option, index) in delegations"
                    :value="option.id"
                    :key="index"
                  >
                    {{ option.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-button
                size="is-small"
                type="is-primary"
                native-type="submit"
                value="Guardar"
              >
                Guardar
              </b-button>
            </div>
          </div>
        </form>
      </div>
      <b-button
        tag="router-link"
        :to="'/ventas'"
        size="is-medium"
        type="is-ghost"
        icon-pack="fas"
        icon-left="times"
      >
      </b-button>
    </nav>
    <!--Content-->

    <div class="columns">
      <div class="column">
        <section>
          <div
            class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
          >
            <div class="column m-0 p-0">
              <h4>
                Estado:
                <span class="tag is-primary">{{
                  states.labels[states.options.indexOf(sale.state.name)]
                }}</span>
              </h4>
            </div>
            <div class="column m-0 p-0 is-flex is-justify-content-end">
              <b-field>
                <b-button
                  @click="showModalHistory = true"
                  class="has-text-primary"
                  type="is-warning"
                  icon-pack="fas"
                  icon-left="history"
                  outlined
                >
                  Estados
                </b-button>
              </b-field>
            </div>
          </div>
        </section>
        <section>
          <div class="columns align-center">
            <div class="column">
              <b-field label="Canal" horizontal>
                <p>{{ sale.channel }}</p>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Unidad" horizontal>
                <p>{{ sale.delegation }}</p>
              </b-field>
            </div>
          </div>
        </section>
        <!-- Section Form-->
        <section class="form">
          <!--Comment-->
          <b-collapse
            :open="false"
            class="card"
            animation="slide"
            aria-id="contentIdForA11y3"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y3"
              >
                <p
                  class="card-header-title title is-5 is-uppercase has-text-grey-light mb-0"
                >
                  Comentarios
                </p>
                <a class="card-header-icon">
                  <b-icon
                    pack="fas"
                    :icon="props.open ? 'sort-down' : 'sort-up'"
                  >
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <p>
                  <span class="has-text-weight-bold">Observaciones: </span
                  >{{ sale.observation }}
                </p>
                <form @submit.prevent="updateComment">
                  <nav class="navbar">
                    <b-button
                      v-if="
                        form.comment.disabled == true && sale.stage != 'lead'
                      "
                      @click="form.comment.disabled = false"
                      class="is-circle"
                      type="is-primary"
                      icon-pack="fas"
                      icon-left="pencil-alt"
                    >
                    </b-button>

                    <div
                      class="buttons"
                      v-if="
                        form.comment.disabled == false && sale.stage != 'lead'
                      "
                    >
                      <b-button
                        @click="form.comment.disabled = true"
                        class="is-circle"
                        type="is-danger"
                        icon-pack="fas"
                        icon-left="times"
                      >
                      </b-button>
                      <b-button
                        class="is-circle"
                        native-type="submit"
                        type="is-success"
                        icon-pack="fas"
                        icon-left="check"
                      >
                      </b-button>
                    </div>
                  </nav>
                  <!-- Form -->
                  <div class="column">
                    <b-field label="Mensaje" expanded>
                      <b-input
                        type="textarea"
                        v-model="form.comment.data.message"
                        :disabled="form.comment.disabled"
                      ></b-input>
                    </b-field>
                  </div>
                  <!-- End Form -->
                </form>
              </div>
            </div>
          </b-collapse>
          <!--Contact-->
          <b-collapse
            :open="false"
            class="card"
            animation="slide"
            aria-id="contentIdForA11y3"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y3"
              >
                <p
                  class="card-header-title title is-5 is-uppercase has-text-grey-light mb-0"
                >
                  Datos del solicitante titular
                </p>
                <a class="card-header-icon">
                  <b-icon
                    pack="fas"
                    :icon="props.open ? 'sort-down' : 'sort-up'"
                  >
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <form @submit.prevent="updateContact">
                  <nav class="navbar">
                    <b-button
                      v-if="
                        form.contact.disabled == true && sale.stage != 'lead'
                      "
                      @click="form.contact.disabled = false"
                      class="is-circle"
                      type="is-primary"
                      icon-pack="fas"
                      icon-left="pencil-alt"
                    >
                    </b-button>

                    <div
                      class="buttons"
                      v-if="
                        form.contact.disabled == false && sale.stage != 'lead'
                      "
                    >
                      <b-button
                        @click="form.contact.disabled = true"
                        class="is-circle"
                        type="is-danger"
                        icon-pack="fas"
                        icon-left="times"
                      >
                      </b-button>
                      <b-button
                        class="is-circle"
                        native-type="submit"
                        type="is-success"
                        icon-pack="fas"
                        icon-left="check"
                      >
                      </b-button>
                    </div>
                  </nav>
                  <!-- Form -->

                  <div class="columns">
                    <div class="column">
                      <b-field label="Nombre y Apellido*" expanded>
                        <b-input
                          v-model="form.contact.data.fullname"
                          :disabled="form.contact.disabled"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="CUIL/DNI*" expanded>
                        <b-input
                          v-model="form.contact.data.cuil"
                          type="number"
                          :disabled="form.contact.disabled"
                          maxlength="11"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Edad*" expanded>
                        <b-input
                          type="number"
                          v-model="form.contact.data.age"
                          :disabled="form.contact.disabled"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Nacionalidad*" expanded>
                        <b-input
                          v-model="form.contact.data.nacionality"
                          :disabled="form.contact.disabled"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Nacimiento*" expanded>
                        <b-input
                          type="date"
                          v-model="form.contact.data.date_birth"
                          :disabled="form.contact.disabled"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Estado Civil*">
                        <b-select
                          expanded
                          placeholder="Seleccionar"
                          v-model="form.contact.data.civil_status"
                          :disabled="form.contact.disabled"
                          required
                        >
                          <option value="Casado">Casado</option>
                          <option value="Soltero">Soltero</option>
                          <option value="Divorciado">Divorciado</option>
                          <option value="Concubinato">Concubinato</option>
                        </b-select>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Peso*">
                        <b-input
                          expanded
                          @input="calculateImcContact()"
                          type="number"
                          step="0.01"
                          v-model="form.contact.data.weight"
                          :disabled="form.contact.disabled"
                          required
                        ></b-input>
                        <p class="control">
                          <span class="button is-static">kg</span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Altura*">
                        <b-input
                          expanded
                          @input="calculateImcContact()"
                          type="number"
                          step="0.01"
                          v-model="form.contact.data.height"
                          :disabled="form.contact.disabled"
                          required
                        ></b-input>
                        <p class="control">
                          <span class="button is-static">cm</span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="IMC*" expanded>
                        <b-input
                          type="number"
                          step="0.01"
                          v-model="form.contact.data.imc"
                          :disabled="form.contact.disabled"
                          readonly
                          required
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="FUM">
                        <b-input
                          type="date"
                          v-model="form.contact.data.fum"
                          :disabled="form.contact.disabled"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Provincia*">
                        <b-select
                          @input="getCities()"
                          v-model="form.contact.data.province"
                          placeholder="Seleccionar"
                          expanded
                          required
                          :disabled="form.contact.disabled"
                        >
                          <option
                            v-for="(province, index) in provinces.data"
                            :value="province"
                            :key="index"
                          >
                            {{ province }}
                          </option>
                        </b-select>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Ciudad*">
                        <b-select
                          v-model="form.contact.data.city"
                          placeholder="Seleccionar"
                          expanded
                          :disabled="form.contact.disabled"
                          :loading="cities.loading"
                          required
                        >
                          <option
                            v-for="(city, index) in cities.data"
                            :value="city"
                            :key="index"
                          >
                            {{ city }}
                          </option>
                        </b-select>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Dirección*" expanded>
                        <b-input
                          v-model="form.contact.data.address"
                          :disabled="form.contact.disabled"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Departamento/Unidad" expanded>
                        <b-input
                          v-model="form.contact.data.departament"
                          :disabled="form.contact.disabled"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field grouped label="Teléfono fijo">
                        <b-input
                          style="width: 75px"
                          v-model="form.contact.data.characteristic_phone"
                          type="text"
                          pattern="[0-9]*"
                          :disabled="form.contact.disabled"
                          maxlength="4"
                        >
                        </b-input>

                        <b-input
                          expanded
                          v-model="form.contact.data.phone"
                          type="text"
                          :disabled="form.contact.disabled"
                          pattern="[0-9]*"
                        >
                        </b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field grouped label="Celular*">
                        <b-field>
                          <p class="control">
                            <span class="button is-static">0</span>
                          </p>
                          <b-input
                            style="width: 75px"
                            v-model="form.contact.data.characteristic_celphone"
                            type="text"
                            pattern="[0-9]*"
                            maxlength="4"
                            :disabled="form.contact.disabled"
                            required
                          >
                          </b-input>
                        </b-field>
                        <b-field expanded>
                          <p class="control">
                            <span class="button is-static">15</span>
                          </p>
                          <b-input
                            expanded
                            v-model="form.contact.data.celphone"
                            type="text"
                            pattern="[0-9]*"
                            :disabled="form.contact.disabled"
                            required
                          >
                          </b-input>
                        </b-field>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field grouped label="Teléfono Laboral">
                        <b-input
                          style="width: 75px"
                          v-model="form.contact.data.characteristic_work_phone"
                          type="text"
                          pattern="[0-9]*"
                          :disabled="form.contact.disabled"
                          maxlength="4"
                        >
                        </b-input>

                        <b-input
                          expanded
                          v-model="form.contact.data.work_phone"
                          type="text"
                          :disabled="form.contact.disabled"
                          pattern="[0-9]*"
                        >
                        </b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Email*" expanded>
                        <b-input
                          type="email"
                          v-model="form.contact.data.email"
                          :disabled="form.contact.disabled"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Email Laboral" expanded>
                        <b-input
                          type="email"
                          v-model="form.contact.data.work_email"
                          :disabled="form.contact.disabled"
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Trabajo*">
                        <b-select
                          expanded
                          placeholder="Seleccionar"
                          v-model="form.contact.data.work"
                          :disabled="form.contact.disabled"
                        >
                          <option value="Relación de dependencia">
                            Relación de dependencia
                          </option>
                          <option value="Monotributo">Monotributo</option>
                          <option value="Particular">
                            Particular (prepago)
                          </option>
                        </b-select>
                      </b-field>
                    </div>
                  </div>

                  <b-field>
                    <b-checkbox
                      class="mt-3"
                      type="is-success"
                      v-model="form.contact.data.not_teacher"
                      :disabled="form.contact.disabled"
                    >
                      Soy NODOCENTE de las UUNN
                    </b-checkbox>
                  </b-field>

                  <!-- End Form -->
                </form>
              </div>
            </div>
          </b-collapse>
          <!--Familiar Group-->
          <b-collapse
            :open="false"
            class="card"
            animation="slide"
            aria-id="contentIdForA11y4"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y4"
              >
                <p
                  class="card-header-title title is-5 is-uppercase has-text-grey-light mb-0"
                >
                  Datos del grupo familiar
                </p>
                <a class="card-header-icon">
                  <b-icon
                    pack="fas"
                    :icon="props.open ? 'sort-down' : 'sort-up'"
                  >
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <div
                  class="form-group"
                  v-for="(family, index) in form.family"
                  :key="index"
                >
                  <form
                    @submit.prevent="
                      family.is_new
                        ? createFamilyData(index)
                        : updateFamilyData(index)
                    "
                  >
                    <nav class="navbar">
                      <b-button
                        v-if="family.disabled == true && sale.stage != 'lead'"
                        @click="family.disabled = false"
                        class="is-circle"
                        type="is-primary"
                        icon-pack="fas"
                        icon-left="pencil-alt"
                      >
                      </b-button>

                      <div
                        class="buttons"
                        v-if="family.disabled == false && sale.stage != 'lead'"
                      >
                        <b-button
                          v-if="family.is_new && index !== 0"
                          @click="removeForm(index)"
                          class="is-circle"
                          type="is-grey"
                          icon-pack="fas"
                          icon-left="trash"
                        >
                        </b-button>
                        <b-button
                          @click="family.disabled = true"
                          class="is-circle"
                          type="is-danger"
                          icon-pack="fas"
                          icon-left="times"
                        >
                        </b-button>
                        <b-button
                          class="is-circle"
                          native-type="submit"
                          type="is-success"
                          icon-pack="fas"
                          icon-left="check"
                        >
                        </b-button>
                      </div>
                    </nav>
                    <!-- Form -->
                    <div class="columns">
                      <div class="column">
                        <b-field label="Nombre y Apellido*" expanded>
                          <b-input
                            v-model="family.data.fullname"
                            :disabled="family.disabled"
                            required
                          ></b-input>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="CUIL/DNI*" expanded>
                          <b-input
                            v-model="family.data.cuil"
                            type="number"
                            maxlength="11"
                            :disabled="family.disabled"
                            required
                          ></b-input>
                        </b-field>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <b-field label="Edad*" expanded>
                          <b-input
                            type="number"
                            v-model="family.data.age"
                            :disabled="family.disabled"
                            required
                          ></b-input>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Parentesco*">
                          <b-select
                            expanded
                            placeholder="Seleccionar"
                            v-model="family.data.relationship"
                            :disabled="family.disabled"
                            required
                          >
                            <option value="Conyuge">Conyuge</option>
                            <option value="Concubina/o">Concubina/o</option>
                            <option value="Hija/o propio soltero menor 21 años">
                              Hija/o propio soltero menor 21 años
                            </option>
                            <option
                              value="Hija/o propio soltero hasta 25 años estudiante"
                            >
                              Hija/o propio soltero hasta 25 años estudiante
                            </option>
                            <option value="Hija/o propio discapacitado">
                              Hija/o propio discapacitado
                            </option>
                            <option
                              value="Hija/o de concubino soltero menor 21 años"
                            >
                              Hija/o de concubino soltero menor 21 años
                            </option>
                            <option
                              value="Hija/o de concubino soltero hasta 25 años estudiante"
                            >
                              Hija/o de concubino soltero hasta 25 años
                              estudiante
                            </option>
                            <option value="Hija/o de concubino discapacitado">
                              Hija/o de concubino discapacitado
                            </option>
                            <option
                              value="Familiar a cargo (Nietos o padres a cargo del titular)"
                            >
                              Familiar a cargo (Nietos o padres a cargo del
                              titular)
                            </option>
                            <option value="Menor bajo guarda o tutela">
                              Menor bajo guarda o tutela
                            </option>
                          </b-select>
                        </b-field>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <b-field label="Nacimiento*" expanded>
                          <b-input
                            type="date"
                            v-model="family.data.date_birth"
                            :disabled="family.disabled"
                            required
                          ></b-input>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Peso*" expanded>
                          <b-input
                            expanded
                            @input="calculateImc(index)"
                            type="number"
                            step="0.01"
                            v-model="family.data.weight"
                            :disabled="family.disabled"
                            required
                          ></b-input>
                          <p class="control">
                            <span class="button is-static">kg</span>
                          </p>
                        </b-field>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <b-field label="Altura*">
                          <b-input
                            expanded
                            @input="calculateImc(index)"
                            type="number"
                            step="0.01"
                            v-model="family.data.height"
                            :disabled="family.disabled"
                            required
                          ></b-input>
                          <p class="control">
                            <span class="button is-static">cm</span>
                          </p>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="IMC*" expanded>
                          <b-input
                            type="number"
                            step="0.01"
                            v-model="family.data.imc"
                            :disabled="family.disabled"
                            required
                          ></b-input>
                        </b-field>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <b-field label="FUM">
                          <b-input
                            type="date"
                            v-model="family.data.fum"
                            :disabled="family.disabled"
                          ></b-input>
                        </b-field>
                      </div>
                      <div class="column"></div>
                    </div>

                    <hr />
                  </form>
                </div>
                <!-- Button -->
                <b-button
                  @click="addForm"
                  type="is-text"
                  icon-pack="fas"
                  icon-left="user-plus"
                >
                  Agregar integrante
                </b-button>
                <!-- End Button-->
                <!-- End Form-->
              </div>
            </div>
          </b-collapse>
          <!--Employer-->
          <b-collapse
            :open="false"
            class="card"
            animation="slide"
            aria-id="contentIdForA11y5"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y5"
              >
                <p
                  class="card-header-title title is-5 is-uppercase has-text-grey-light mb-0"
                >
                  Datos del empleador
                </p>
                <a class="card-header-icon">
                  <b-icon
                    pack="fas"
                    :icon="props.open ? 'sort-down' : 'sort-up'"
                  >
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <form
                  @submit.prevent="
                    form.employer.is_new
                      ? createEmployerData()
                      : updateEmployerData()
                  "
                >
                  <nav class="navbar">
                    <b-button
                      v-if="
                        form.employer.disabled == true && sale.stage != 'lead'
                      "
                      @click="form.employer.disabled = false"
                      class="is-circle"
                      type="is-primary"
                      icon-pack="fas"
                      icon-left="pencil-alt"
                    >
                    </b-button>

                    <div
                      class="buttons"
                      v-if="
                        form.employer.disabled == false && sale.stage != 'lead'
                      "
                    >
                      <b-button
                        @click="form.employer.disabled = true"
                        class="is-circle"
                        type="is-danger"
                        icon-pack="fas"
                        icon-left="times"
                      >
                      </b-button>
                      <b-button
                        class="is-circle"
                        native-type="submit"
                        type="is-success"
                        icon-pack="fas"
                        icon-left="check"
                      >
                      </b-button>
                    </div>
                  </nav>

                  <!-- Forms -->
                  <div class="columns">
                    <div class="column">
                      <b-field label="CUIT*">
                        <b-input
                          type="number"
                          v-model="form.employer.data.cuit"
                          maxlength="11"
                          :disabled="form.employer.disabled"
                          :loading="form.employer.loading"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Razón Social*">
                        <b-input
                          v-model="form.employer.data.bussines_name"
                          :disabled="form.employer.disabled"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Dirección Fiscal*">
                        <b-input
                          v-model="form.employer.data.fiscal_address"
                          :disabled="form.employer.disabled"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Domicilio Casa Central">
                        <b-input
                          v-model="form.employer.data.address"
                          :disabled="form.employer.disabled"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Domicilio Sucursal">
                        <b-input
                          v-model="form.employer.data.branch_office_address"
                          :disabled="form.employer.disabled"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field grouped label="Teléfono Casa Central">
                        <b-input
                          style="width: 75px"
                          v-model="form.employer.data.characteristic_phone"
                          type="text"
                          pattern="[0-9]*"
                          :disabled="form.employer.disabled"
                          maxlength="4"
                        >
                        </b-input>

                        <b-input
                          expanded
                          v-model="form.employer.data.phone"
                          type="text"
                          :disabled="form.employer.disabled"
                          pattern="[0-9]*"
                        >
                        </b-input>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field grouped label="Teléfono Sucursal">
                        <b-input
                          style="width: 75px"
                          v-model="
                            form.employer.data
                              .characteristic_branch_office_phone
                          "
                          type="text"
                          pattern="[0-9]*"
                          :disabled="form.employer.disabled"
                          maxlength="4"
                        >
                        </b-input>

                        <b-input
                          expanded
                          v-model="form.employer.data.branch_office_phone"
                          type="text"
                          :disabled="form.employer.disabled"
                          pattern="[0-9]*"
                        >
                        </b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field grouped label="Teléfono RRHH">
                        <b-input
                          style="width: 75px"
                          v-model="form.employer.data.characteristic_rrhh_phone"
                          type="text"
                          pattern="[0-9]*"
                          :disabled="form.employer.disabled"
                          maxlength="4"
                        >
                        </b-input>

                        <b-input
                          expanded
                          v-model="form.employer.data.rrhh_phone"
                          type="text"
                          :disabled="form.employer.disabled"
                          pattern="[0-9]*"
                        >
                        </b-input>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Email Casa Central" expanded>
                        <b-input
                          type="email"
                          v-model="form.employer.data.email"
                          :disabled="form.employer.disabled"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Email Sucursal" expanded>
                        <b-input
                          type="email"
                          v-model="form.employer.data.branch_office_email"
                          :disabled="form.employer.disabled"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Email RRHH" expanded>
                        <b-input
                          type="email"
                          v-model="form.employer.data.rrhh_email"
                          :disabled="form.employer.disabled"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column"></div>
                  </div>
                  <!-- End Forms -->
                </form>
              </div>
            </div>
          </b-collapse>
          <!--Declaration-->
          <b-collapse
            :open="false"
            class="card"
            animation="slide"
            aria-id="contentIdForA11y6"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y6"
              >
                <p
                  class="card-header-title title is-5 is-uppercase has-text-grey-light mb-0"
                >
                  Declaración jurada - Antecedentes de salud
                </p>
                <a class="card-header-icon">
                  <b-icon
                    pack="fas"
                    :icon="props.open ? 'sort-down' : 'sort-up'"
                  >
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <form
                  @submit.prevent="
                    form.declaration.is_new
                      ? createDeclarationData()
                      : updateDeclarationData()
                  "
                >
                  <nav class="navbar">
                    <b-button
                      v-if="
                        form.declaration.disabled == true &&
                        sale.stage != 'lead'
                      "
                      @click="form.declaration.disabled = false"
                      class="is-circle"
                      type="is-primary"
                      icon-pack="fas"
                      icon-left="pencil-alt"
                    >
                    </b-button>

                    <div
                      class="buttons"
                      v-if="
                        form.declaration.disabled == false &&
                        sale.stage != 'lead'
                      "
                    >
                      <b-button
                        @click="form.declaration.disabled = true"
                        class="is-circle"
                        type="is-danger"
                        icon-pack="fas"
                        icon-left="times"
                      >
                      </b-button>
                      <b-button
                        class="is-circle"
                        native-type="submit"
                        type="is-success"
                        icon-pack="fas"
                        icon-left="check"
                      >
                      </b-button>
                    </div>
                  </nav>
                  <b-tabs>
                    <b-tab-item
                      v-for="(item, index) in form.declaration.data"
                      :key="index"
                      :label="item.label"
                    >
                      <b-field
                        v-for="(row, i) in item.rows"
                        :key="i"
                        :grouped="row.input == 'radio'"
                      >
                        <!-- radio button -->
                        <label
                          v-if="row.input == 'radio'"
                          class="label has-text-primary"
                        >
                          {{ row.title }}
                        </label>
                        <div
                          class="control is-block"
                          v-if="row.input == 'radio'"
                        >
                          <b-radio
                            v-model="row.value"
                            :name="`radio_${i}`"
                            :native-value="true"
                            :disabled="form.declaration.disabled"
                            required
                          >
                            Si
                          </b-radio>
                          <b-radio
                            v-model="row.value"
                            :name="`radio_${i}`"
                            :disabled="form.declaration.disabled"
                            :native-value="false"
                          >
                            No
                          </b-radio>
                        </div>
                        <!-- textarea -->
                        <b-input
                          v-if="row.input == 'text'"
                          v-show="item.rows[i - 1].value"
                          type="textarea"
                          v-model="row.value"
                          :placeholder="row.title"
                          :required="row.required"
                          :disabled="form.declaration.disabled"
                        >
                        </b-input>
                      </b-field>
                    </b-tab-item>
                  </b-tabs>
                </form>
              </div>
            </div>
          </b-collapse>
          <!--Audit Medical-->
          <b-collapse
            :open="false"
            v-if="sale.stage != 'lead' && sale.stage != 'prospecto'"
            class="card"
            animation="slide"
            aria-id="contentIdForA11y7"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y7"
              >
                <p
                  class="card-header-title title is-5 is-uppercase has-text-grey-light mb-0"
                >
                  Auditoría médica
                </p>
                <a class="card-header-icon">
                  <b-icon
                    pack="fas"
                    :icon="props.open ? 'sort-down' : 'sort-up'"
                  >
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <form
                  @submit.prevent="
                    form.audit.is_new ? createAuditData() : updateAuditData()
                  "
                >
                  <nav class="navbar">
                    <b-button
                      v-if="form.audit.disabled == true && sale.stage != 'lead'"
                      @click="form.audit.disabled = false"
                      class="is-circle"
                      type="is-primary"
                      icon-pack="fas"
                      icon-left="pencil-alt"
                    >
                    </b-button>

                    <div
                      class="buttons"
                      v-if="
                        form.audit.disabled == false && sale.stage != 'lead'
                      "
                    >
                      <b-button
                        @click="form.audit.disabled = true"
                        class="is-circle"
                        type="is-danger"
                        icon-pack="fas"
                        icon-left="times"
                      >
                      </b-button>
                      <b-button
                        class="is-circle"
                        native-type="submit"
                        type="is-success"
                        icon-pack="fas"
                        icon-left="check"
                      >
                      </b-button>
                    </div>
                  </nav>

                  <label class="label has-text-primary mb-4"
                    >Quien suscribe, auditor médico de OSFATUN, deja constancia
                    que se ha realizado la afiliación que precede esta firma y
                    concluye:</label
                  >
                  <div
                    class="mb-3 is-flex is-justify-content-space-between is-align-items-center"
                  >
                    <div class="is-flex is-align.items-center">
                      <h5 class="title is-5 has-text-grey-light mb-0 mr-3">
                        Auditor:
                      </h5>
                      <b-select
                        expanded
                        placeholder="Seleccionar"
                        v-model="form.audit.data.user_id"
                        :disabled="form.audit.disabled"
                      >
                        <option
                          v-for="auditor in auditors.data"
                          :key="auditor.id"
                          :value="auditor.id"
                        >
                          {{ auditor.fullname }}
                        </option>
                      </b-select>
                    </div>
                    <b-field horizontal label="Fecha">
                      <b-input
                        v-model="form.audit.data.date"
                        :disabled="form.audit.disabled"
                        required
                        type="date"
                      >
                      </b-input>
                    </b-field>
                  </div>
                  <b-field grouped>
                    <label class="label">
                      1) La valoración de riesgo es:
                    </label>
                    <p class="control ml-2">
                      <b-radio
                        :disabled="form.audit.disabled"
                        v-model="form.audit.data.risk_assessment"
                        type="is-warning"
                        native-value="bajo"
                        name="risk_assessment"
                        required
                      >
                        BAJO
                      </b-radio>
                      <b-radio
                        :disabled="form.audit.disabled"
                        v-model="form.audit.data.risk_assessment"
                        type="is-warning"
                        native-value="medio"
                        name="risk_assessment"
                      >
                        MEDIO
                      </b-radio>
                      <b-radio
                        :disabled="form.audit.disabled"
                        v-model="form.audit.data.risk_assessment"
                        type="is-warning"
                        native-value="alto"
                        name="risk_assessment"
                      >
                        ALTO
                      </b-radio>
                    </p>
                  </b-field>
                  <b-field grouped>
                    <label class="label">
                      2) El dictamen final de la ficha es:
                    </label>
                    <p class="control ml-2">
                      <b-radio
                        :disabled="form.audit.disabled"
                        v-model="form.audit.data.opinion_final"
                        type="is-warning"
                        name="opinion_final"
                        native-value="aprobado"
                        required
                      >
                        APROBADO
                      </b-radio>
                      <b-radio
                        :disabled="form.audit.disabled"
                        v-model="form.audit.data.opinion_final"
                        type="is-warning"
                        name="opinion_final"
                        native-value="desaprobado"
                      >
                        DESAPROBADO
                      </b-radio>
                    </p>
                  </b-field>
                  <b-field class="file is-primary">
                    <b-upload
                      class="file-label"
                      v-model="form.audit.data.file"
                      :disabled="form.audit.disabled"
                      required
                    >
                      <span class="file-cta">
                        <span
                          class="file-label is-uppercase has-text-weight-bold"
                          >Subir archivo auditoría médica</span
                        >
                      </span>
                      <span class="file-name" v-if="form.audit.data.file">
                        {{ form.audit.data.file.name }}
                      </span>
                    </b-upload>
                  </b-field>
                  <ul
                    class="menu-list ml-0 pr-3"
                    v-if="form.audit.data.file_path"
                  >
                    <li
                      class="is-flex is-align-items-center is-justify-content-space-between"
                    >
                      {{ urlShort(form.audit.data.file_path) }}
                      <b-button
                        class="is-circle"
                        tag="a"
                        :href="form.audit.data.file_path"
                        type="is-grey"
                        icon-pack="fas"
                        icon-left="file-download"
                      >
                      </b-button>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </b-collapse>
          <!--Documentation-->
          <b-collapse
            :open="false"
            v-if="sale.stage != 'lead'"
            class="card"
            animation="slide"
            aria-id="contentIdForA11y8"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y8"
              >
                <p
                  class="card-header-title title is-5 is-uppercase has-text-grey-light mb-0"
                >
                  Archivos / Documentación
                </p>
                <a class="card-header-icon">
                  <b-icon
                    pack="fas"
                    :icon="props.open ? 'sort-down' : 'sort-up'"
                  >
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <form @submit.prevent="createDocumentationData()">
                  <nav class="navbar">
                    <b-button
                      v-if="
                        form.documentation.disabled == true &&
                        sale.stage != 'lead'
                      "
                      @click="form.documentation.disabled = false"
                      class="is-circle"
                      type="is-primary"
                      icon-pack="fas"
                      icon-left="pencil-alt"
                    >
                    </b-button>

                    <div
                      class="buttons"
                      v-if="
                        form.documentation.disabled == false &&
                        sale.stage != 'lead'
                      "
                    >
                      <b-button
                        @click="form.documentation.disabled = true"
                        class="is-circle"
                        type="is-danger"
                        icon-pack="fas"
                        icon-left="times"
                      >
                      </b-button>
                      <b-button
                        class="is-circle"
                        native-type="submit"
                        type="is-success"
                        icon-pack="fas"
                        icon-left="check"
                      >
                      </b-button>
                    </div>
                  </nav>
                  <b-tabs vertical>
                    <b-tab-item>
                      <template #header>
                        <span> Cargados por el asesor</span>
                      </template>
                      <b-field>
                        <b-upload
                          v-model="form.documentation.file.agent"
                          multiple
                          drag-drop
                          expanded
                          :disabled="form.documentation.disabled"
                        >
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                Arrastrar los archivos a este cuadro o hacer
                                clic para seleccionar
                              </p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>
                      <div class="list">
                        <ul class="menu-list ml-0">
                          <li
                            class="is-flex is-align-items-center is-justify-content-space-between"
                            v-for="(file, index) in form.documentation.file
                              .agent"
                            :key="index"
                          >
                            {{ file.name }}
                            <b-button
                              @click="removeItemAgentList(index)"
                              class="is-circle"
                              type="is-grey"
                              icon-pack="fas"
                              icon-left="trash"
                            >
                            </b-button>
                          </li>
                          <Draggable
                            class="sort-list"
                            :list="form.documentation.data.agent"
                            ghost-class="ghost-card"
                            group="tickets"
                            @start="drag = true"
                            @end="drag = false"
                            :animation="200"
                          >
                            <li
                              class="is-flex is-align-items-center is-justify-content-space-between sort-list-item"
                              v-for="data in form.documentation.data.agent"
                              :key="data.id"
                            >
                              {{ urlShort(data.file_path) }}
                              <div class="buttons">
                                <b-button
                                  class="is-circle"
                                  tag="a"
                                  :href="data.file_path"
                                  target="_blank"
                                  type="is-grey"
                                  icon-pack="fas"
                                  icon-left="file-download"
                                >
                                </b-button>
                                <b-button
                                  @click="deleteDocumentationData(data.id)"
                                  class="is-circle"
                                  type="is-grey"
                                  icon-pack="fas"
                                  icon-left="trash"
                                >
                                </b-button>
                              </div>
                            </li>
                          </Draggable>
                        </ul>
                      </div>
                    </b-tab-item>

                    <b-tab-item>
                      <template #header>
                        <span> Cargados por el potencial afiliado</span>
                      </template>
                      <!--<b-field>
                        <b-upload
                          v-model="form.documentation.file.contact"
                          multiple
                          drag-drop
                          expanded
                          :disabled="form.documentation.disabled"
                        >
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                Arrastrar los archivos a este cuadro o hacer
                                clic para seleccionar
                              </p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>-->
                      <div class="list">
                        <ul class="menu-list ml-0">
                          <li
                            class="is-flex is-align-items-center is-justify-content-space-between"
                            v-for="(file, index) in form.documentation.file
                              .contact"
                            :key="index"
                          >
                            {{ file.name }}
                            <b-button
                              @click="removeItemContactList(index)"
                              class="is-circle"
                              type="is-grey"
                              icon-pack="fas"
                              icon-left="trash"
                            >
                            </b-button>
                          </li>
                          <Draggable
                            class="sort-list"
                            :list="form.documentation.data.contact"
                            ghost-class="ghost-card"
                            group="tickets"
                            @start="drag = true"
                            @end="drag = false"
                            :animation="200"
                          >
                            <li
                              class="is-flex is-align-items-center is-justify-content-space-between sort-list-item"
                              v-for="(data, index) in form.documentation.data
                                .contact"
                              :key="index"
                            >
                              {{
                                data.is_signature
                                  ? data.description
                                  : urlShort(data.file_path)
                              }}
                              <div class="buttons">
                                <b-button
                                  v-if="data.is_signature"
                                  class="is-circle"
                                  type="is-grey"
                                  icon-pack="fas"
                                  icon-left="image"
                                  @click="showSignature(data)"
                                >
                                </b-button>
                                <b-button
                                  v-else
                                  class="is-circle"
                                  tag="a"
                                  :href="data.file_path"
                                  target="_blank"
                                  type="is-grey"
                                  icon-pack="fas"
                                  icon-left="file-download"
                                >
                                </b-button>
                              </div>
                            </li>
                          </Draggable>
                        </ul>
                      </div>
                    </b-tab-item>
                  </b-tabs>
                </form>
              </div>
            </div>
          </b-collapse>
        </section>
      </div>
      <b-modal v-model="modalSignature.show">
        <iframe
          :src="modalSignature.src"
          width="100%"
          height="600"
          frameborder="0"
        ></iframe>
      </b-modal>
      <b-modal
        v-model="showModalHistory"
        title="Historial de Estados"
        size="is-medium"
        :closable="false"
        :scrollable="true"
      >
        <div class="box bg-light p-5 m-5">
          <div
            class="is-flex is-flex-direction-row is-justify-content-end is-align-items-center"
          >
            <div class="is-flex is-flex-direction-column">
              <b-field label="Asignar estado">
                <b-select
                  v-model="states.selected"
                  placeholder="Seleccionar..."
                >
                  <option
                    v-for="(opt, index) in states.options"
                    :key="index + 1"
                    :value="opt"
                  >
                    {{ states.labels[index] }}
                  </option>
                </b-select>
                <p class="control">
                  <b-button
                    :disabled="
                      !(states.selected && states.selected != sale.state.name)
                    "
                    @click="assignState"
                    class="has-text-warning"
                    type="is-primary"
                    icon-pack="fas"
                    icon-left="save"
                  >
                    Guardar
                  </b-button>
                </p>
              </b-field>
            </div>
          </div>
          <b-table
            :data="sale.statesHistory"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            :paginated="true"
            :per-page="10"
            :show-empty="true"
            max-height="600"
            min-height="250"
          >
            <b-table-column field="name" label="Estado" v-slot="props">
              {{ states.labels[states.options.indexOf(props.row.name)] }}
            </b-table-column>
            <b-table-column field="created_at" label="Fecha" v-slot="props">
              {{ formatDate(props.row.created_at) }}
            </b-table-column>
            <b-table-column
              field="user_name"
              label="Responsable"
              v-slot="props"
            >
              {{
                props.row.user_name
                  ? props.row.user_name + " " + props.row.user_last_name
                  : "-"
              }}
            </b-table-column>
            <template #empty>
              <div class="has-text-centered">No se encontraron resultados</div>
            </template>
          </b-table>
        </div>
      </b-modal>
      <!--
        MODAL "Change stage. Are you sure?"
       -->
      <b-modal
        v-model="showModalSure"
        size="is-small"
        :can-cancel="false"
        :width="400"
      >
        <div class="box bg-light p-1">
          <header class="mb-3 has-background-light p-4">
            <p class="modal-card-title">Volver a {{ backstage }}</p>
          </header>
          <div
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
          >
            <div class="is-align-self-flex-start m-4">
              <p>Está seguro?</p>
            </div>
            <div
              class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center mt-3 is-align-self-flex-end"
            >
              <b-button
                class="mr-1"
                label="Cancelar"
                @click="() => (showModalSure = false)"
              />
              <b-button
                label="Continuar"
                @click="setStage(backstage)"
                type="is-primary"
              />
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!--Navbar Bottom-->
    
    <nav class="navbar is-fixed-bottom">
      <b-field class="mb-0">
        <multiselect
          @select="addVendor"
          v-model="assign.agent"
          :multiple="false"
          :options="agents.data"
          group-values="users"
          group-label="name"
          :group-select="false"
          select-label=""
          deselect-label="Click para remover"
          placeholder="Seleccionar"
          track-by="name"
          label="name"
        
    
        >
          <span slot="noResult">No se encontraron resultados.</span>
        </multiselect>
        <!--
                <b-field>
                    <b-select 
                        @input="addVendor"
                        v-model="assign.agent"
                        placeholder="Seleccionar" 
                        icon-pack="fas" 
                        icon="user">
                        <optgroup  
                            v-for="departament in agents.data" 
                            :key="departament.id"
                            :label="(departament.name == 'Ventas') ? `Vendedores - ${departament.name}` : `Comercializadora - ${departament.name}`">
                            <option 
                                v-for="agent in departament.users"
                                :key="agent.id" 
                                :value="agent.id">{{ (agent.role_id == 1) ? `Supervisor - ${agent.name} ${agent.lastname}` : `Vendedor - ${agent.name} ${agent.lastname}` }}
                            </option>
                        </optgroup>
                    </b-select>
                </b-field>
                -->
        <p v-if="this.$store.getters.getSession.role_id == 1" class="control">
          <b-tooltip
            label="Asignar vendedor"
            type="is-dark"
            position="is-top"
            :square="true"
          >
            <b-button
              @click="assignVendor()"
              class="has-text-primary"
              type="is-warning"
              icon-pack="fas"
              icon-left="retweet"
              :loading="assign.loading"
              :disabled="assign.disabled"
              outlined
            >
            </b-button>
          </b-tooltip>
        </p>
      </b-field>
      <!-- 
        BUTTONS STAGES
       -->
      <div class="buttons">
        <b-button
          v-if="sale.stage == 'lead'"
          :disabled="sale.user == ''"
          @click="setStage('prospecto')"
          type="is-primary"
        >
          Pasar a prospecto
        </b-button>
        <b-button
          v-if="sale.stage == 'prospecto'"
          @click="sureStage('lead')"
          type="is-light"
        >
          Volver a Lead
        </b-button>
        <b-button
          v-if="sale.stage == 'prospecto'"
          :disabled="completeProspectoStep ? false : true"
          @click="completeProspectoStep ? setStage('auditor') : ''"
          type="is-primary"
        >
          Pasar a auditoría
        </b-button>
        <b-button
          v-if="sale.stage == 'auditor'"
          @click="sureStage('prospecto')"
          type="is-light"
        >
          Volver a Prospecto
        </b-button>
        <b-button
          v-if="sale.stage == 'auditor'"
          :disabled="
            completeAuditorStep
              ? false
              : true && $store.getters.getSession.role_id == 4
          "
          @click="completeAuditorStep ? setStage('calificado') : ''"
          type="is-primary"
        >
          Pasar a Calificado
        </b-button>
        <b-button
          v-if="sale.stage == 'calificado'"
          @click="sureStage('auditor')"
          type="is-light"
        >
          Volver a Auditor
        </b-button>
        <b-button
          v-if="sale.stage == 'calificado'"
          :disabled="completeCalificadoStep ? false : true"
          @click="completeCalificadoStep ? setStage('cierre') : ''"
          type="is-primary"
        >
          Pasar a Cierre
        </b-button>
        <b-button
          v-if="sale.stage == 'cierre'"
          @click="sureStage('calificado')"
          type="is-light"
        >
          Volver a Calificado
        </b-button>
        <b-button
          class="has-text-primary"
          type="is-warning"
          outlined
          :loading="pdf.loading"
          @click="generateReport()"
        >
          Generar reporte
        </b-button>
      </div>
    </nav>

    <b>Imagenes adjuntas a la venta</b>
    <ul>
      <li v-for="(item, index) in form.documentation.data.agent" :key="index">
        <a target="_blank" :href="item.file_path" download>
          <img :src="item.file_path" alt="Imagen" />
        </a>
      </li>
    </ul>

    <!--Content Pdf-->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="`${sale.channel}-${sale.delegation}-${sale.contact.cuil}-${sale.contact.fullname}`"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a3"
      pdf-orientation="landscape"
      pdf-content-width="98%"
      ref="html2Pdf"
      @progress="progress($event)"
    >
      <section slot="pdf-content">
        <section class="pdf-item">
          <nav class="navbar">
            <img
              src="/img/logo-horizontal.png"
              alt="Logo osfatun"
              width="150"
            />
            <h1 class="title is-5 is-uppercase has-text-dark mb-0">
              id: #{{ saleId(sale.id) }}
            </h1>
          </nav>
          <br />
          <div class="columns is-flex is-align-items-center">
            <div class="column">
              <h1 class="title is-5 is-uppercase has-text-dark mb-0">
                Canal de venta: {{ sale.channel }}
              </h1>
            </div>
            <div class="column">
              <h1 class="title is-5 is-uppercase has-text-dark mb-0">
                Unidad de venta: {{ sale.delegation }}
              </h1>
            </div>
          </div>
          <hr />
          <div class="columns">
            <div class="column">
              <p
                class="card-header-title title is-5 is-uppercase has-text-grey-light"
              >
                Datos del solicitante titular
              </p>

              <div class="columns">
                <div class="column">
                  <b-field label="Nombre y Apellido*" expanded>
                    <b-input
                      v-model="form.contact.data.fullname"
                      :disabled="form.contact.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="CUIL/DNI*" expanded>
                    <b-input
                      v-model="form.contact.data.cuil"
                      type="number"
                      :disabled="form.contact.disabled"
                      maxlength="11"
                      required
                    ></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Edad*" expanded>
                    <b-input
                      type="number"
                      v-model="form.contact.data.age"
                      :disabled="form.contact.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Nacionalidad*" expanded>
                    <b-input
                      v-model="form.contact.data.nacionality"
                      :disabled="form.contact.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Nacimiento*" expanded>
                    <b-input
                      type="date"
                      v-model="form.contact.data.date_birth"
                      :disabled="form.contact.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Estado Civil*">
                    <b-select
                      expanded
                      placeholder="Seleccionar"
                      v-model="form.contact.data.civil_status"
                      :disabled="form.contact.disabled"
                      required
                    >
                      <option value="Casado">Casado</option>
                      <option value="Soltero">Soltero</option>
                      <option value="Divorciado">Divorciado</option>
                      <option value="Concubinato">Concubinato</option>
                    </b-select>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Peso*">
                    <b-input
                      expanded
                      @input="calculateImcContact()"
                      type="number"
                      step="0.01"
                      v-model="form.contact.data.weight"
                      :disabled="form.contact.disabled"
                      required
                    ></b-input>
                    <p class="control">
                      <span class="button is-static">kg</span>
                    </p>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Altura*">
                    <b-input
                      expanded
                      @input="calculateImcContact()"
                      type="number"
                      step="0.01"
                      v-model="form.contact.data.height"
                      :disabled="form.contact.disabled"
                      required
                    ></b-input>
                    <p class="control">
                      <span class="button is-static">cm</span>
                    </p>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="IMC*" expanded>
                    <b-input
                      type="number"
                      step="0.01"
                      v-model="form.contact.data.imc"
                      :disabled="form.contact.disabled"
                      readonly
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="FUM">
                    <b-input
                      type="date"
                      v-model="form.contact.data.fum"
                      :disabled="form.contact.disabled"
                    ></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Provincia*">
                    <b-select
                      @input="getCities()"
                      v-model="form.contact.data.province"
                      placeholder="Seleccionar"
                      expanded
                      required
                      :disabled="form.contact.disabled"
                    >
                      <option
                        v-for="(province, index) in provinces.data"
                        :value="province"
                        :key="index"
                      >
                        {{ province }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Ciudad*">
                    <b-select
                      v-model="form.contact.data.city"
                      placeholder="Seleccionar"
                      expanded
                      :disabled="form.contact.disabled"
                      :loading="cities.loading"
                      required
                    >
                      <option
                        v-for="(city, index) in cities.data"
                        :value="city"
                        :key="index"
                      >
                        {{ city }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Dirección*" expanded>
                    <b-input
                      v-model="form.contact.data.address"
                      :disabled="form.contact.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Departamento/Unidad" expanded>
                    <b-input
                      v-model="form.contact.data.departament"
                      :disabled="form.contact.disabled"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="html2pdf__page-break" />
        <section class="pdf-item">
          <nav class="navbar">
            <img
              src="/img/logo-horizontal.png"
              alt="Logo osfatun"
              width="150"
            />
            <h1 class="title is-5 is-uppercase has-text-dark mb-0">
              id: #{{ saleId(sale.id) }}
            </h1>
          </nav>
          <hr />
          <div class="columns">
            <div class="column">
              <p
                class="card-header-title title is-5 is-uppercase has-text-grey-light"
              >
                Datos del solicitante titular
              </p>

              <div class="columns">
                <div class="column">
                  <b-field grouped label="Teléfono fijo">
                    <b-input
                      style="width: 75px"
                      v-model="form.contact.data.characteristic_phone"
                      type="text"
                      pattern="[0-9]*"
                      :disabled="form.contact.disabled"
                      maxlength="4"
                    >
                    </b-input>

                    <b-input
                      expanded
                      v-model="form.contact.data.phone"
                      type="text"
                      :disabled="form.contact.disabled"
                      pattern="[0-9]*"
                    >
                    </b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field grouped label="Celular*">
                    <b-field>
                      <p class="control">
                        <span class="button is-static">0</span>
                      </p>
                      <b-input
                        style="width: 75px"
                        v-model="form.contact.data.characteristic_celphone"
                        type="text"
                        pattern="[0-9]*"
                        maxlength="4"
                        :disabled="form.contact.disabled"
                        required
                      >
                      </b-input>
                    </b-field>
                    <b-field expanded>
                      <p class="control">
                        <span class="button is-static">15</span>
                      </p>
                      <b-input
                        expanded
                        v-model="form.contact.data.celphone"
                        type="text"
                        pattern="[0-9]*"
                        :disabled="form.contact.disabled"
                        required
                      >
                      </b-input>
                    </b-field>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field grouped label="Teléfono Laboral">
                    <b-input
                      style="width: 75px"
                      v-model="form.contact.data.characteristic_work_phone"
                      type="text"
                      pattern="[0-9]*"
                      :disabled="form.contact.disabled"
                      maxlength="4"
                    >
                    </b-input>

                    <b-input
                      expanded
                      v-model="form.contact.data.work_phone"
                      type="text"
                      :disabled="form.contact.disabled"
                      pattern="[0-9]*"
                    >
                    </b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Email*" expanded>
                    <b-input
                      type="email"
                      v-model="form.contact.data.email"
                      :disabled="form.contact.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Email Laboral" expanded>
                    <b-input
                      type="email"
                      v-model="form.contact.data.work_email"
                      :disabled="form.contact.disabled"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Trabajo*">
                    <b-select
                      expanded
                      placeholder="Seleccionar"
                      v-model="form.contact.data.work"
                      :disabled="form.contact.disabled"
                    >
                      <option value="Relación de dependencia">
                        Relación de dependencia
                      </option>
                      <option value="Monotributo">Monotributo</option>
                      <option value="Particular">Particular (prepago)</option>
                    </b-select>
                  </b-field>
                </div>
              </div>

              <b-field>
                <b-checkbox
                  class="mt-3"
                  type="is-success"
                  v-model="form.contact.data.not_teacher"
                  :disabled="form.contact.disabled"
                >
                  Soy NODOCENTE de las UUNN
                </b-checkbox>
              </b-field>
              <div class="column"></div>
            </div>
          </div>
        </section>

        <div class="html2pdf__page-break" />

        <section
          class="pdf-item"
          v-for="(family, index) in form.family"
          :key="index"
        >
          <nav class="navbar">
            <img
              src="/img/logo-horizontal.png"
              alt="Logo osfatun"
              width="150"
            />
            <h1 class="title is-5 is-uppercase has-text-dark mb-0">
              id: #{{ saleId(sale.id) }}
            </h1>
          </nav>
          <hr />
          <div class="columns">
            <div class="column">
              <p
                class="card-header-title title is-5 is-uppercase has-text-grey-light"
              >
                Datos del grupo familiar
              </p>

              <div class="columns">
                <div class="column">
                  <b-field label="Nombre y Apellido*" expanded>
                    <b-input
                      v-model="family.data.fullname"
                      :disabled="family.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="CUIL/DNI*" expanded>
                    <b-input
                      v-model="family.data.cuil"
                      type="number"
                      maxlength="11"
                      :disabled="family.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Edad*" expanded>
                    <b-input
                      type="number"
                      v-model="family.data.age"
                      :disabled="family.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Parentesco*">
                    <b-select
                      expanded
                      placeholder="Seleccionar"
                      v-model="family.data.relationship"
                      :disabled="family.disabled"
                      required
                    >
                      <option value="Conyuge">Conyuge</option>
                      <option value="Concubina/o">Concubina/o</option>
                      <option value="Hija/o propio soltero menor 21 años">
                        Hija/o propio soltero menor 21 años
                      </option>
                      <option
                        value="Hija/o propio soltero hasta 25 años estudiante"
                      >
                        Hija/o propio soltero hasta 25 años estudiante
                      </option>
                      <option value="Hija/o propio discapacitado">
                        Hija/o propio discapacitado
                      </option>
                      <option value="Hija/o de concubino soltero menor 21 años">
                        Hija/o de concubino soltero menor 21 años
                      </option>
                      <option
                        value="Hija/o de concubino soltero hasta 25 años estudiante"
                      >
                        Hija/o de concubino soltero hasta 25 años estudiante
                      </option>
                      <option value="Hija/o de concubino discapacitado">
                        Hija/o de concubino discapacitado
                      </option>
                      <option
                        value="Familiar a cargo (Nietos o padres a cargo del titular)"
                      >
                        Familiar a cargo (Nietos o padres a cargo del titular)
                      </option>
                      <option value="Menor bajo guarda o tutela">
                        Menor bajo guarda o tutela
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Nacimiento*" expanded>
                    <b-input
                      type="date"
                      v-model="family.data.date_birth"
                      :disabled="family.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Peso*" expanded>
                    <b-input
                      expanded
                      @input="calculateImc(index)"
                      type="number"
                      step="0.01"
                      v-model="family.data.weight"
                      :disabled="family.disabled"
                      required
                    ></b-input>
                    <p class="control">
                      <span class="button is-static">kg</span>
                    </p>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Altura*">
                    <b-input
                      expanded
                      @input="calculateImc(index)"
                      type="number"
                      step="0.01"
                      v-model="family.data.height"
                      :disabled="family.disabled"
                      required
                    ></b-input>
                    <p class="control">
                      <span class="button is-static">cm</span>
                    </p>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="IMC*" expanded>
                    <b-input
                      type="number"
                      step="0.01"
                      v-model="family.data.imc"
                      :disabled="family.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="FUM">
                    <b-input
                      type="date"
                      v-model="family.data.fum"
                      :disabled="family.disabled"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column"></div>
              </div>
            </div>
          </div>
        </section>

        <div class="html2pdf__page-break" />

        <section class="pdf-item">
          <nav class="navbar">
            <img
              src="/img/logo-horizontal.png"
              alt="Logo osfatun"
              width="150"
            />
            <h1 class="title is-5 is-uppercase has-text-dark mb-0">
              id: #{{ saleId(sale.id) }}
            </h1>
          </nav>
          <hr />
          <div class="columns">
            <div class="column">
              <p
                class="card-header-title title is-5 is-uppercase has-text-grey-light"
              >
                Datos del empleador
              </p>

              <div class="columns">
                <div class="column">
                  <b-field label="CUIT*">
                    <b-input
                      type="number"
                      v-model="form.employer.data.cuit"
                      maxlength="11"
                      :disabled="form.employer.disabled"
                      :loading="form.employer.loading"
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Razón Social*">
                    <b-input
                      v-model="form.employer.data.bussines_name"
                      :disabled="form.employer.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Dirección Fiscal*">
                    <b-input
                      v-model="form.employer.data.fiscal_address"
                      :disabled="form.employer.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Domicilio Casa Central">
                    <b-input
                      v-model="form.employer.data.address"
                      :disabled="form.employer.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Domicilio Sucursal">
                    <b-input
                      v-model="form.employer.data.branch_office_address"
                      :disabled="form.employer.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field grouped label="Teléfono Casa Central">
                    <b-input
                      style="width: 75px"
                      v-model="form.employer.data.characteristic_phone"
                      type="text"
                      pattern="[0-9]*"
                      :disabled="form.employer.disabled"
                      maxlength="4"
                    >
                    </b-input>

                    <b-input
                      expanded
                      v-model="form.employer.data.phone"
                      type="text"
                      :disabled="form.employer.disabled"
                      pattern="[0-9]*"
                    >
                    </b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field grouped label="Teléfono Sucursal">
                    <b-input
                      style="width: 75px"
                      v-model="
                        form.employer.data.characteristic_branch_office_phone
                      "
                      type="text"
                      pattern="[0-9]*"
                      :disabled="form.employer.disabled"
                      maxlength="4"
                    >
                    </b-input>

                    <b-input
                      expanded
                      v-model="form.employer.data.branch_office_phone"
                      type="text"
                      :disabled="form.employer.disabled"
                      pattern="[0-9]*"
                    >
                    </b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field grouped label="Teléfono RRHH">
                    <b-input
                      style="width: 75px"
                      v-model="form.employer.data.characteristic_rrhh_phone"
                      type="text"
                      pattern="[0-9]*"
                      :disabled="form.employer.disabled"
                      maxlength="4"
                    >
                    </b-input>

                    <b-input
                      expanded
                      v-model="form.employer.data.rrhh_phone"
                      type="text"
                      :disabled="form.employer.disabled"
                      pattern="[0-9]*"
                    >
                    </b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Email Casa Central" expanded>
                    <b-input
                      type="email"
                      v-model="form.employer.data.email"
                      :disabled="form.employer.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Email Sucursal" expanded>
                    <b-input
                      type="email"
                      v-model="form.employer.data.branch_office_email"
                      :disabled="form.employer.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Email RRHH" expanded>
                    <b-input
                      type="email"
                      v-model="form.employer.data.rrhh_email"
                      :disabled="form.employer.disabled"
                      required
                    ></b-input>
                  </b-field>
                </div>
                <div class="column"></div>
              </div>
            </div>
          </div>
        </section>

        <div class="html2pdf__page-break" />

        <section
          class="pdf-item"
          v-for="(item, index) in form.declaration.data"
          :key="index"
          :label="item.label"
        >
          <nav class="navbar">
            <img
              src="/img/logo-horizontal.png"
              alt="Logo osfatun"
              width="150"
            />
            <h1 class="title is-5 is-uppercase has-text-dark mb-0">
              id: #{{ saleId(sale.id) }}
            </h1>
          </nav>
          <hr />
          <div class="columns">
            <div class="column">
              <p
                class="card-header-title title is-5 is-uppercase has-text-grey-light"
              >
                Declaración jurada - {{ item.label }}
              </p>

              <section class="pdf-item" v-for="(row, i) in item.rows" :key="i">
                <b-field :grouped="row.input == 'radio'">
                  <!-- radio button -->
                  <label
                    v-if="row.input == 'radio'"
                    class="label has-text-dark"
                  >
                    {{ row.title }}
                  </label>
                  <div
                    class="control is-block"
                    v-if="row.input == 'radio'"
                    style="margin-left: auto"
                  >
                    <b-radio
                      v-model="row.value"
                      :name="`radio_${i}`"
                      :native-value="true"
                      :disabled="form.declaration.disabled"
                      required
                    >
                      Si
                    </b-radio>
                    <b-radio
                      v-model="row.value"
                      :name="`radio_${i}`"
                      :disabled="form.declaration.disabled"
                      :native-value="false"
                    >
                      No
                    </b-radio>
                  </div>
                  <!-- textarea -->
                  <b-input
                    v-if="row.input == 'text'"
                    v-show="item.rows[i - 1].value"
                    type="textarea"
                    v-model="row.value"
                    :placeholder="row.title"
                    :required="row.required"
                    :disabled="form.declaration.disabled"
                  >
                  </b-input>
                </b-field>
              </section>

              <div v-if="i == 8" class="html2pdf__page-break" />
            </div>
          </div>
        </section>

        <div class="html2pdf__page-break" />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import Draggable from "vuedraggable";
import Loader from "./Loader.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import {
  alertSuccess,
  alertFailed,
  fillString,
  provinces,
  cities,
} from "@/helpers";
import moment from "moment";

export default {
  name: "SaleForm",
  components: {
    VueHtml2pdf,
    Loader,
    Draggable,
    Multiselect,
  },
  data() {
    return {
      modalSignature: {
        show: false,
        src: null,
      },
      showModalHistory: false,
      showModalSure: false,
      backstage: null,
      token: null,
      delegations: [],
      channel_id: null,
      unity_id: null,
      states: {
        labels: ["No atendida", "En proceso", "Descartada", "Alta"],
        options: ["not attended", "in process", "discarded", "alta"],
        selected: "",
      },
      sale: {
        id: "",
        user: "",
        auditor: "",
        stage: "",
        delegation: "",
        channel: "",
        contact: {
          fullname: "",
          cuil: "",
        },
        observation: "",
        state: {
          name: "",
          created_at: "",
        },
        statesHistory: [],
      },
      delegation: {
        data: [],
      },
      assign: {
        loading: false,
        disabled: true,
        agent: "",
      },
      agents: {
        disabled: true,
        data: [],
      },
      vendors: {
        disabled: true,
        data: {},
      },
      auditors: {
        disabled: true,
        data: [],
      },
      provinces: {
        disabled: false,
        data: [],
      },
      cities: {
        disabled: true,
        laoding: false,
        data: [],
      },
      form: {
        comment: {
          disabled: true,
          data: {
            message: "",
          },
        },
        contact: {
          disabled: true,
          data: {
            id: "",
            fullname: "",
            cuil: "",
            age: "",
            nacionality: "",
            date_birth: "",
            civil_status: "",
            weight: "",
            height: "",
            imc: "",
            fum: "",
            province: "",
            city: "",
            address: "",
            departament: "",
            characteristic: "",
            phone: "",
            celphone: "",
            characteristic_work: "",
            work_phone: "",
            email: "",
            work_email: "",
            work: "",
            not_teacher: "",
          },
        },
        family: [
          {
            disabled: true,
            is_new: true,
            data: {
              fullname: "",
              cuil: "",
              age: "",
              relationship: "",
              date_birth: "",
              weight: "",
              height: "",
              imc: "",
              fum: "",
            },
          },
        ],
        employer: {
          disabled: true,
          loading: false,
          is_new: true,
          data: {
            cuit: "",
            bussines_name: "",
            fiscal_address: "",
            address: "",
            branch_office_address: "",
            characteristic: "",
            phone: "",
            rrhh_characteristic: "",
            rrhh_phone: "",
            branch_office_characteristic: "",
            branch_office_phone: "",
            email: "",
            rrhh_email: "",
            branch_office_email: "",
          },
        },
        declaration: {
          disabled: true,
          is_new: true,
          data: [
            {
              label: "Titular",
              rows: [
                {
                  title: "¿Estas bajo atención médica?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title: "¿Toma medicación regularmente?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title: "¿Tomó medicamentos en los últimos 6 meses?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title:
                    "En este momento ¿tiene algún motivo para consultar un médico?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title:
                    "¿Afecciones congénitas y hereditarias, discapacidades?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title: `¿Tuvo afecciones visuales, respiratorias,
                                                cardiovasculares, del sistema nervioso, aparato
                                                digestivo, articulaciones, columna vertebral, piel
                                                glándulas, sangre u oídos?*`,
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title:
                    "¿Ha sido operado y/o internado en alguna oportunidad?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title: "¿Debe realizarse alguna operación?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title: "¿Tiene alteraciones de menstruación?",
                  required: false,
                  input: "radio",
                  value: "",
                },
                {
                  title: "¿Ha tenido embarazos?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title:
                    "¿Cuántos? ¿Tuvo complicaciones en el embarazo y/o parto?",
                  required: false,
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title: "¿Está embarazada?",
                  required: false,
                  input: "radio",
                  value: "",
                },
                {
                  title: "¿Ha tenido interrupción de un embarazo?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title: `¿Tuvo o tiene cáncer, meningitis, hepatitis
                                                diabetes, chagas, gota, atrofia muscular,
                                                enfermedades infecciosas, ginecológicas o
                                                urológicas?*`,
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title: "¿Fuma, consume alcohol o utiliza drogas?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title:
                    "¿Tuvo o tiene alguna enfermedad de transmisión sexual?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title: `Si la anterior es afirmativa ¿está dispuesto a
                                                compartir su Historia Clínica con la Auditoría
                                                Médica?`,
                  required: false,
                  input: "radio",
                  value: "",
                },
                {
                  title: "¿Ha recibido transfusiones de sangre?",
                  required: false,
                  input: "radio",
                  value: "",
                },
                {
                  title: `¿Le han hecho tomografías, resonancias
                                                magnéticas, estudios radiológicos complejos,
                                                biopsia u otro estudio especial?*`,
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title: `¿Se ha efectuado un chequeo completo el último año?`,
                  required: false,
                  input: "radio",
                  value: "",
                },
                {
                  title:
                    "¿Intoxicaciones, accidentes de tránsito, trabajo u otro tipo?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title: "¿Tiene afecciones odontológicas?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title: "¿Requiere prótesis odontológicas u ortodoncias?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
                {
                  title:
                    "¿Estuvo o está bajo tratamiento psiquiátrico o psicológico?*",
                  required: true,
                  input: "radio",
                  value: "",
                },
                {
                  title: "Detallar (En caso de varios colorear uno por linea)",
                  active: false,
                  input: "text",
                  value: "",
                },
              ],
            },
          ],
        },
        audit: {
          disabled: true,
          is_new: true,
          data: {
            user_id: "",
            risk_assessment: "",
            opinion_final: "",
            date: "",
            file: null,
            file_path: "",
          },
        },
        documentation: {
          disabled: true,
          file: {
            contact: [],
            agent: [],
          },
          data: {
            contact: [],
            agent: [],
          },
        },
      },
      pdf: {
        loading: false,
      },
      loading: false,
    };
  },
  methods: {
    showSignature(data) {
      if (data.is_signature) {
        this.modalSignature.show = true;
        this.modalSignature.src = data.file_path;
      }
    },
    sureStage(value) {
      this.backstage = value;
      this.showModalSure = true;
    },
    formatDate(date) {
      return moment(date).format("HH:mm DD/MM/YYYY");
    },

    async assignState() {
      try {
        const formdata = new FormData();
        formdata.append("sale_id", this.sale.id);
        formdata.append("name_state", this.states.selected);
        this.loading = true;

        // Realizar la asignación de estado normal
        await this.$repositories.sales.assignState(formdata);

        // Verificar si el estado es "discarded" para enviar los datos a la API
        if (this.states.selected === "discarded") {
          const id = String(this.form.contact.data.id);
          try {
            await axios.post(
              "https://osfatun-api2.vercel.app/api/crm/descartar_afiliado",
              { id }
            );
            console.log("La afiliación fue descartada correctamente.");
          } catch (error) {
            console.error("Error al descartar la afiliación:", error);
            // Manejar el error según sea necesario
          }
        }

        // Realizar la carga adicional o actualización de la venta
        await this.fetchSale();
      } catch (error) {
        console.log("Catch error assignState: ", error);
      } finally {
        this.loading = false;
      }
    },

    async toDataURL(imageUrl) {
      try {
        // console.log(imageUrl)
        // const response = await fetch("https://picsum.photos/200/300",{
        //     method: 'GET',
        //     headers: {
        //       'Access-Control-Allow-Origin':'*'
        //     }
        // })
        // // console.log(response)
        // const imageBlob = await response.blob()
        // const reader = new FileReader();
        // reader.readAsDataURL(imageBlob);
        // // console.log(imageBlob)
        // // console.log(reader)
        // reader.onloadend = () => {
        //   const base64data = reader.result;
        //   return base64data;
        // }
        //   console.log("AAAAAAAAAAAAAAAAAA",asd);
        // var xhr = new XMLHttpRequest();
        // xhr.onload = function() {
        //   var reader = new FileReader();
        //   reader.onloadend =  function() {
        //     asd(reader.result);
        //   }
        //   reader.readAsDataURL(xhr.response);
        // };
        // xhr.open('GET', 'https://picsum.photos/200/300');
        // xhr.responseType = 'blob';
        // xhr.send();
        // var aux="asd";
        // function asd (w){
        //   aux = w;
        //   console.log("AASW",aux)
        //   return w;
        // }
      } catch (error) {
        alert(error);
      }
    },
    async addChannelUnity() {
      try {
        this.loading = true;
        const data = new FormData();
        data.append("id", this.sale.id);
        data.append("channel_id", this.channel_id);
        data.append("unity_id", this.unity_id);
        await this.$repositories.sales.addChannelAndUnity(data);
        alertSuccess("Se asociaron los datos a la venta!");
        this.loading = false;
      } catch (error) {
        alertFailed("Datos del canal o unidad invalidos!");
        this.unity_id = 0;
        this.channel_id = 0;

        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async fetchSale() {
      try {
        this.loading = true;
        const id = this.$route.params.id;
        const query = await this.$repositories.sales.show(id);
        const item = query.data.sale;
        const { delegations } = query.data;
        this.delegations = delegations;
        this.token = document.cookie.includes("token=");
        // si no hay usuario asignado y el usuario no es supervisor del departamento de ventas
        if (item.user == null) {
          if (
            this.$store.getters.getSession.departament_id != 1 &&
            this.$store.getters.getSession.departament_id != 6 &&
            this.$store.getters.getSession.departament_id != 3 &&
            this.$store.getters.getSession.departament_id != 28
          ) {
           if (this.$store.getters.getSession.role_id != 1  || this.$store.getters.getSession.role_id != 1) {
              this.$router.push("/ventas");
              alertFailed("Acceso denegado para tu rol de usuario!");
              return;
            }
            this.$router.push("/ventas");
            alertFailed("Acceso denegado para tu departamento!");
            return;
          }
        }

        // si el usuarios supervisor de ventas o supervisor de comercializadora
        if (
          ((this.$store.getters.getSession.role_id == 1) |
            (this.$store.getters.getSession.role_id == 2)) &
          (this.$store.getters.getSession.departament_marketer == true)
        ) {
          this.fetchWithMarketerSupervisor(
            this.$store.getters.getSession.departament_id
          );
        } else if (this.$store.getters.getSession.role_id == 1) {
          this.fetchWithSupervisor();
        }

        // Si el usuario es vendedor y no esta asignado
        if (
          (this.$store.getters.getSession.role_id == 2) &
          (item.user != null)
        ) {
          if (this.$store.getters.getSession.id != item.user.id) {
            this.$router.push("/ventas");
            alertFailed("Acceso denegado!");
            return;
          }
        }

        this.sale.id = item.id;
        this.sale.stage = item.stage;
        this.sale.delegation =
          item?.delegation?.name || "No se ha asignado una delegación";
        this.sale.channel = item?.channel || "No se ha asignado un canal";
        this.sale.contact.fullname = item?.contact?.fullname || "";
        this.sale.contact.cuil = item?.contact?.cuil || "";
        this.sale.state.name = item?.last_state?.name;
        this.sale.state.created_at = item?.last_state?.created_at;
        this.states.selected = item?.last_state?.name;
        this.sale.statesHistory = item?.states;
        this.sale.observation = item?.observation;
        if (item.comment != null) {
          this.form.comment.data.message = item.comment;
        }
        if (item.user != null) {
          this.sale.user = `${item.user.name} ${item.user.lastname}`;
          this.assign.agent = {
            id: item.user.id,
            name:
              item.user.role_id == 1
                ? `Supervisor - ${item.user.name} ${item.user.lastname}`
                : `Vendedor - ${item.user.name} ${item.user.lastname}`,
          };
        }

        if (item.contact != null) {
          this.form.contact.data = item.contact;
          this.getProvinces();
          this.getCities();
        }

        if (item?.family_group?.length > 0) {
          this.form.family = [];
          item.family_group.forEach((family) => {
            const ITEMS = {
              disabled: true,
              is_new: false,
              data: family,
            };
            this.form.family.push(ITEMS);
          });
        }

        if (item.medical_audit != null) {
          const ITEMS = {
            disabled: true,
            is_new: false,
            data: item.medical_audit,
          };
          this.form.audit = ITEMS;
        }

        if (item.employer != null) {
          this.form.employer.is_new = false;
          this.form.employer.data = item.employer;
        }

        if (item.declaration != null) {
          this.form.declaration.id = item.declaration.id;
          this.form.declaration.is_new = false;
          this.form.declaration.data = item.declaration.declaration;
        }

        if (item.documentation != null) {
          this.form.documentation.data.contact = [];
          this.form.documentation.data.agent = [];
          item.documentation.forEach((documentation) => {
            if (documentation.origin == "contacto") {
              const ITEMS = {
                id: documentation.id,
                order: documentation.order,
                file_path: documentation.file_path,
              };
              this.form.documentation.data.contact.push(ITEMS);
            }
            if (documentation.origin == "agente") {
              const ITEMS = {
                id: documentation.id,
                order: documentation.order,
                file_path: documentation.file_path,
              };
              this.form.documentation.data.agent.push(ITEMS);
            }
          });
          const response_contact_files =
            await this.$repositories.contacts.getFiles(
              this.form.contact.data.id
            );
          const response_contact_signatures =
            await this.$repositories.contacts.getSignatures(
              this.form.contact.data.id
            );
          response_contact_files?.data?.files.forEach((file) => {
            const ITEMS = {
              id: file.id,
              file_path: file.url,
            };
            this.form.documentation.data.contact.push(ITEMS);
          });
          response_contact_signatures?.data?.signatures.forEach((signature) => {
            const ITEMS = {
              id: signature.id,
              is_signature: true,
              file_path: signature.base_64,
              description: signature.description,
            };
            this.form.documentation.data.contact.push(ITEMS);
          });
          this.form.documentation.data.contact.sort(function (a, b) {
            return (b.order != null) - (a.order != null) || a - b;
          });
          this.form.documentation.data.agent.sort(function (a, b) {
            return (b.order != null) - (a.order != null) || a - b;
          });
        }
      } catch (error) {
        console.log("Catch error fetchSale: ", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchWithSupervisor() {
      try {
        const query = await this.$repositories.agents.withSupervisor();
        if (query.data.users.length > 0) {
          for (let i = 0; query.data.users.length > 0; i++) {
            this.agents.data.push({
              name: query.data.users[i]?.name,
              users: [],
            });

            query.data.users[i].users.forEach((user) => {
              this.agents.data[i].users.push({
                id: user.id,
                email: user.email,
                departamento: user.departament.description,
                nombre: `${user?.name} ${user?.lastname}`,
                name:
                  user.role_id == 1
                    ? `Supervisor - ${user?.name} ${user?.lastname}`
                    : `Vendedor - ${user?.name} ${user?.lastname}`,
              });
            });
          }
        }
      } catch (error) {
        console.log("Catch error fetchWithSupervisor", error);
      }
    },
    async fetchWithMarketerSupervisor(id) {
      try {
        const query = await this.$repositories.agents.withMarketerSupervisor(
          id
        );
        if (query.data.users.length > 0) {
          query.data.users.forEach((item) => {
            this.agents.data.push(item);
          });
        }
      } catch (error) {
        console.log("Catch Error fetchWithMarketerSupervisor: ", error);
      }
    },
    async fetchAuditors() {
      try {
        const query = await this.$repositories.agents.auditors();
        if (query.data.users.length > 0) {
          query.data.users.forEach((item) => {
            const ITEMS = {
              id: item.id,
              fullname: `${item.name} ${item.lastname}`,
              email: item.email,
            };
            this.auditors.data.push(ITEMS);
          });
          this.auditors.disabled = false;
        }
      } catch (error) {
        console.log("Catch error fetchAuditors: ", error);
      }
    },
    async fetchFiscalData() {
      if (this.form.employer.data.cuit.length == 11) {
        this.form.employer.loading = true;
        try {
          const query = await this.$repositories.sales.employerFiscalData(
            this.form.employer.data.cuit
          );
          this.form.employer.data.bussines_name = `${query.data.contact.datosGenerales.apellido} ${query.data.contact.datosGenerales.nombre}`;
          this.form.employer.data.fiscal_address = `${query.data.contact.datosGenerales.domicilioFiscal.direccion}, ${query.data.contact.datosGenerales.domicilioFiscal.localidad}, ${query.data.contact.datosGenerales.domicilioFiscal.descripcionProvincia}`;
          this.form.employer.loading = false;
        } catch (error) {
          alertFailed("La clave ingresada no es una CUIT!");
          this.form.employer.loading = false;
        }
      }
    },

    async setStage(stage) {
      try {
        if (this.sale.user == "") {
          alertFailed("Debe asignar un vendedor!");
          return;
        }
        this.loading = true;
        const id = this.sale.id;
        const data = new FormData();
        data.append("stage", stage);
        await setTimeout(() => {
          window.location.reload();
        }, 6000);
        await this.$repositories.sales.stage(id, data);
        await this.fetchSale();
      } catch (error) {
        console.log("Catch error setStage: ", error);
      } finally {
        this.backstage = "";
        this.showModalSure = false;
      }
    },

    async createEmployerData() {
      try {
        this.loading = true;
        const data = new FormData();
        data.append("sale_id", this.sale.id);
        for (const [key, value] of Object.entries(this.form.employer.data)) {
          data.append(key, value);
        }
        await this.$repositories.sales.createEmployerData(data);
        this.form.employer.disabled = true;
        alertSuccess("Registro exitoso!");
        await this.fetchSale();
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },
    async createFamilyData(index) {
      try {
        this.loading = true;

        const data = new FormData();
        data.append("sale_id", this.sale.id);
        for (const [key, value] of Object.entries(
          this.form.family[index].data
        )) {
          data.append(key, value);
        }
        await this.$repositories.sales.createFamilyGroupData(data);
        this.form.family[index].disabled = true;
        alertSuccess("Registro exitoso!");
        await this.fetchSale();
        this.addRow(index);
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },
    async createDeclarationData() {
      try {
        this.loading = true;
        const data = new FormData();
        data.append("sale_id", this.sale.id);
        data.append("declaration", JSON.stringify(this.form.declaration.data));
        await this.$repositories.sales.createDeclarationData(data);
        this.form.declaration.disabled = true;
        alertSuccess("Registro exitoso!");
        await this.fetchSale();
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },
    async createAuditData() {
      try {
        this.loading = true;
        const data = new FormData();
        data.append("sale_id", this.sale.id);
        for (const [key, value] of Object.entries(this.form.audit.data)) {
          data.append(key, value);
        }
        await this.$repositories.sales.createAuditData(data);
        this.form.audit.disabled = true;
        alertSuccess("Registro exitoso!");
        await this.fetchSale();
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },
    async createDocumentationData() {
      try {
        this.loading = true;
        const data = new FormData();
        data.append("sale_id", this.sale.id);
        this.form.documentation.file.agent.forEach((file) => {
          data.append("filesAgent[]", file);
        });
        this.form.documentation.file.contact.forEach((file) => {
          data.append("filesContact[]", file);
        });
        await this.$repositories.sales.createDocumentationData(data);
        this.form.documentation.disabled = true;
        this.form.documentation.file.agent = [];
        this.form.documentation.file.contact = [];
        alertSuccess("Registro exitoso!");
        await this.fetchSale();
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },

    async updateComment() {
      try {
        this.loading = true;
        const id = this.sale.id;
        const data = new FormData();
        data.append("_method", "put");
        data.append("comment", this.form.comment.data.message);
        await this.$repositories.sales.update(id, data);
        this.form.comment.disabled = true;
        alertSuccess("Registro exitoso!");
        this.loading = false;
      } catch (error) {
        console.log("Catch error updateComment: ", error);
      }
    },
    async updateContact() {
      try {
        this.loading = true;
        const id = this.form.contact.data.id;
        const data = new FormData();
        data.append("_method", "put");
        for (const [key, value] of Object.entries(this.form.contact.data)) {
          data.append(key, value);
        }
        await this.$repositories.contacts.update(id, data);
        this.form.contact.disabled = true;
        alertSuccess("Registro exitoso!");
        this.loading = false;
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },
    async updateEmployerData() {
      try {
        this.loading = true;
        const id = this.form.employer.data.id;
        const data = new FormData();
        data.append("_method", "put");
        for (const [key, value] of Object.entries(this.form.employer.data)) {
          data.append(key, value);
        }
        await this.$repositories.sales.updateEmployerData(id, data);
        this.form.employer.disabled = true;
        alertSuccess("Registro exitoso!");
        this.loading = false;
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },
    async updateFamilyData(index) {
      try {
        this.loading = true;

        const id = this.form.family[index].data.id;
        const data = new FormData();
        data.append("_method", "put");
        for (const [key, value] of Object.entries(
          this.form.family[index].data
        )) {
          data.append(key, value);
        }
        await this.$repositories.sales.updateFamilyGroupData(id, data);
        this.form.family[index].disabled = true;
        alertSuccess("Registro exitoso!");
        this.loading = false;
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },
    async updateDeclarationData() {
      try {
        this.loading = true;
        const id = this.form.declaration.id;
        const data = new FormData();
        data.append("_method", "put");
        data.append("declaration", JSON.stringify(this.form.declaration.data));
        await this.$repositories.sales.updateDeclarationData(id, data);
        this.form.declaration.disabled = true;
        alertSuccess("Registro exitoso!");
        await this.fetchSale();
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },
    async updateAuditData() {
      try {
        this.loading = true;
        const id = this.form.audit.data.id;
        const data = new FormData();
        data.append("_method", "put");
        for (const [key, value] of Object.entries(this.form.audit.data)) {
          data.append(key, value);
        }
        await this.$repositories.sales.updateAuditData(id, data);
        this.form.audit.disabled = true;
        alertSuccess("Registro exitoso!");
        await this.fetchSale();
        this.loading = false;
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },

    async deleteDocumentationData(id) {
      try {
        this.loading = true;
        await this.$repositories.sales.delete(id);
        alertSuccess("Registro exitoso!");
        await this.fetchSale();
      } catch (error) {
        alertFailed("Ha ocurrido un error!");
        this.loading = false;
      }
    },

    async assignVendor() {
      try {
        this.assign.loading = true;
        const id = this.sale.id;
        const data = new FormData();
        data.append("_method", "put");
        data.append("user_id", this.assign.agent.id);
        await this.$repositories.sales.assign(id, data);
        this.assign.disabled = true;
        this.assign.loading = false;
        this.fetchSale();
        //console.log("agente:", JSON.stringify(this.assign.agent));
        //console.log("contacto:", JSON.stringify(this.form.contact));

        alertSuccess("Registro exitoso!");

        // Realizar la segunda llamada POST
        const secondData = {
          id: String(this.form.contact.data.id), // Asumiendo que this.form.contact es el idAfiliadoCrm
          email: this.assign.agent.email,
          nombre: this.assign.agent.nombre,
          comercializadora: this.assign.agent.departamento,
        };

        try {
          const response = await axios.post(
            "https://osfatun-api2.vercel.app/api/crm/asignar_usuario",
            secondData
          );
          console.log("Respuesta de la API:", response.data);
        } catch (error) {
          console.log("Error en la llamada POST:", error);
          // Puedes manejar el error aquí si es necesario
          //throw error; // Re-lanzar el error para que sea manejado por el bloque catch externo
        }
      } catch (error) {
        alertFailed("Ha ocurrido un error");
      }
    },

    addVendor() {
      if (this.assign.agent != null) {
        this.assign.disabled = false;
      } else {
        this.assign.disabled = true;
      }
    },
    addForm() {
      const ITEMS = {
        disabled: true,
        is_new: true,
        data: {
          fullname: "",
          cuil: "",
          age: "",
          relationship: "",
          date_birth: "",
          weight: "",
          height: "",
          imc: "",
          fum: "",
        },
      };
      this.form.family.push(ITEMS);
    },
    removeForm(index) {
      this.form.family.splice(index, 1);
    },
    removeItemAgentList(index) {
      this.form.documentation.file.agent.splice(index, 1);
    },
    removeItemContactList(index) {
      this.form.documentation.file.contact.splice(index, 1);
    },
    saleId(value) {
      return fillString(value, 4);
    },
    urlShort(value) {
      const short = value.split("/");
      return short[short.length - 1];
    },
    calculateImcContact() {
      if (
        this.form.contact.data.weight != "" &&
        this.form.contact.data.height != ""
      ) {
        this.form.contact.data.imc =
          Math.round(
            (this.form.contact.data.weight * 10) /
              (this.form.contact.data.height / 100) /
              (this.form.contact.data.height / 100)
          ) / 10;
      }
    },
    calculateImc(index) {
      if (
        this.form.family[index].data.weight != "" &&
        this.form.family[index].data.height != ""
      ) {
        this.form.family[index].data.imc =
          Math.round(
            (this.form.family[index].data.weight * 10) /
              (this.form.family[index].data.height / 100) /
              (this.form.family[index].data.height / 100)
          ) / 10;
      }
    },

    completeProspectoStep() {
      if (this.form.contact.is_new && this.form.employer.is_new) {
        return false;
      }
      return true;
    },
    completeAuditorStep() {
      if (
        !this.form.contact.is_new &&
        !this.form.employer.is_new &&
        !this.form.auditor.is_new
      ) {
        return true;
      }
      return false;
    },
    completeCalificadoStep() {
      if (
        !this.form.contact.is_new &&
        !this.form.employer.is_new &&
        !this.form.auditor.is_new &&
        !this.form.documentation.is_new
      ) {
        return true;
      }
      return false;
    },

    getProvinces() {
      this.provinces.data = provinces();
    },
    getCities() {
      this.cities.data = cities(this.form.contact.data.province);
      this.cities.disabled = false;
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    saleId(value) {
      return fillString(value, 4);
    },
    progress($event) {
      this.pdf.loading = true;
      if ($event == 100) {
        this.pdf.loading = false;
      }
    },
    addRow(index) {
      const obj = {
        label: this.form.family[index].data.fullname,
        rows: [
          {
            title: "¿Estas bajo atención médica?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: "¿Toma medicación regularmente?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: "¿Tomó medicamentos en los últimos 6 meses?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title:
              "En este momento ¿tiene algún motivo para consultar un médico?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: "¿Afecciones congénitas y hereditarias, discapacidades?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: `¿Tuvo afecciones visuales, respiratorias,
                                        cardiovasculares, del sistema nervioso, aparato
                                        digestivo, articulaciones, columna vertebral, piel
                                        glándulas, sangre u oídos?*`,
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: "¿Ha sido operado y/o internado en alguna oportunidad?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: "¿Debe realizarse alguna operación?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: "¿Tiene alteraciones de menstruación?",
            required: false,
            input: "radio",
            value: "",
          },
          {
            title: "¿Ha tenido embarazos?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "¿Cuántos? ¿Tuvo complicaciones en el embarazo y/o parto?",
            required: false,
            active: false,
            input: "text",
            value: "",
          },
          {
            title: "¿Está embarazada?",
            required: false,
            input: "radio",
            value: "",
          },
          {
            title: "¿Ha tenido interrupción de un embarazo?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: `¿Tuvo o tiene cáncer, meningitis, hepatitis
                                        diabetes, chagas, gota, atrofia muscular,
                                        enfermedades infecciosas, ginecológicas o
                                        urológicas?*`,
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: "¿Fuma, consume alcohol o utiliza drogas?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: "¿Tuvo o tiene alguna enfermedad de transmisión sexual?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: `Si la anterior es afirmativa ¿está dispuesto a
                                        compartir su Historia Clínica con la Auditoría
                                        Médica?`,
            required: false,
            input: "radio",
            value: "",
          },
          {
            title: "¿Ha recibido transfusiones de sangre?",
            required: false,
            input: "radio",
            value: "",
          },
          {
            title: `¿Le han hecho tomografías, resonancias
                                        magnéticas, estudios radiológicos complejos,
                                        biopsia u otro estudio especial?*`,
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: `¿Se ha efectuado un chequeo completo el último año?`,
            required: false,
            input: "radio",
            value: "",
          },
          {
            title:
              "¿Intoxicaciones, accidentes de tránsito, trabajo u otro tipo?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: "¿Tiene afecciones odontológicas?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title: "¿Requiere prótesis odontológicas u ortodoncias?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
          {
            title:
              "¿Estuvo o está bajo tratamiento psiquiátrico o psicológico?*",
            required: true,
            input: "radio",
            value: "",
          },
          {
            title: "Detallar (En caso de varios colorear uno por linea)",
            active: false,
            input: "text",
            value: "",
          },
        ],
      };
      this.form.declaration.data.push(obj);
      if (this.form.declaration.is_new) {
        this.createDeclarationData();
      } else {
        this.updateDeclarationData();
      }
    },
  },
  computed: {
    filteredDataObj() {
      const newData = [];
      this.data.forEach((element) => {
        const items = element.items.filter(
          (item) => item.toLowerCase().indexOf(this.name.toLowerCase()) >= 0
        );
        if (items.length) {
          newData.push({ type: element.type, items });
        }
      });
      return newData;
    },
  },
  created() {
    this.fetchAuditors();
    this.fetchSale();
    this.getProvinces();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
#sale-form {
  height: calc(100vh - 162px);
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 90px;
  overflow-y: auto;
  .head {
    display: flex;
    align-items: center;
  }
  .form-group {
    margin-bottom: 1rem;
  }
  .field {
    &.is-grouped {
      .label {
        max-width: 75%;
      }
      .control {
        margin-left: auto;
      }
    }
  }
  .navbar {
    &.is-fixed-bottom {
      padding: 1rem;
      .buttons {
        margin-left: auto;
      }
    }
    &.is-fixed-top {
      padding: 1rem;
      align-items: center;
      justify-content: space-between;
      height: 90px;
    }
  }
  a[type="button"] {
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    border-radius: 50% !important;
  }
  .columns:first-child {
    margin-top: 83px;
  }
}
.collapse {
  &.card {
    .card-header {
      box-shadow: none !important;
    }
    .card-content {
      padding-top: 0;
      nav {
        justify-content: flex-end;
      }
    }
  }
}
.upload {
  position: relative !important;
  opacity: 1;
  &.upload-draggable {
    border-radius: none !important;
    width: auto !important;
    height: auto !important;
    display: block;
  }
}
.menu-list {
  li {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #dbdbdb;
  }
}
.ghost-card {
  opacity: 0.5;
  background: $white;
}
.sort-list-item {
  cursor: move;
}
.pdf-item {
  padding: 1rem;
  .navbar {
    align-items: center;
    justify-content: space-between;
  }
  .input-line {
    input.input,
    select,
    textarea {
      border-top: 0;
      border-bottom: 2px solid $warning;
      border-left: 0;
      border-right: 0;
    }
  }
}
</style>
