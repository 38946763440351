<template>
    <div id="new-contact-page">
        <ContactForm/>
    </div>
</template>

<script>
    import ContactForm from '@/components/Contact-form.vue';
    export default {
        name:'ContactFormPage',
        components:{
            ContactForm
        }
    }
</script>