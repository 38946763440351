<template>
    <div id="new-delagation-page">
        <DelegationForm/>
    </div>
</template>

<script>
    import DelegationForm from '@/components/Delegation-form.vue';
    export default {
        name:'DelegationFormPage',
        components:{
            DelegationForm
        }
    }
</script>