import { NotificationProgrammatic as Notification } from 'buefy';
import geography from './geography.json';

export const alertSuccess = (message) => {
    Notification.open({
        duration: 5000,
        message,
        type: 'is-success',
        position: 'is-bottom-right',
        hasIcon: false
    })
}

export const alertInfo = (message) => {
    Notification.open({
        duration: 5000,
        message,
        type: 'is-info',
        position: 'is-bottom-right',
        hasIcon: false
    })
}

export const alertFailed = (message) => {
    Notification.open({
        duration: 5000,
        message,
        type: 'is-danger',
        position: 'is-bottom-right',
        hasIcon: false
    })
}

export const fillString = (value, length) => {
    return String(value).padStart(length, '0'); 
}

export const provinces = () => {
    const arr = [];
    geography.forEach(provinces => {
        arr.push(provinces.nombre);
    });
    return arr;
}

export const cities = (value) => {
    const arr = [];
    geography.forEach(provinces => {
        if(provinces.nombre == value){
            const cities = provinces.ciudades;
            cities.forEach(city => {
                arr.push(city.nombre);
            });
        }
    });
    return arr;
}