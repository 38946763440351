<template>
  <div id="delegation-form">
    <Loader :active="loading"></Loader>
    <div class="columns">
      <div class="column is-6">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <div>
                <h1 class="title has-text-primary">
                  {{
                    $route.params.id ? "Editar Delegacion" : "Nueva Delegacion"
                  }}
                </h1>
                <nav class="breadcrumb" aria-label="breadcrumbs">
                  <ul>
                    <li>
                      <router-link :to="'/delegaciones'"
                        >Delegaciones</router-link
                      >
                    </li>
                    <li class="is-active">
                      <a href="#" aria-current="page">{{
                        $route.params.id
                          ? "Editar Delegacion"
                          : "Nueva Delegacion"
                      }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-6">
        <div class="form-content">
          <form @submit.prevent="form.submit">
            <b-field label="Nombre*">
              <b-input
                v-model="form.name"
                placeholder="Nombre"
                required
              ></b-input>
            </b-field>
            <b-field label="Tipo de delegación*">
              <b-select
                v-model="form.type"
                placeholder="Seleccionar"
                expanded
                required
              >
                <option
                  v-for="(type, index) in types.data"
                  :value="type"
                  :key="index"
                >
                  {{ type }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Provincia*">
              <b-select
                @input="getCities()"
                v-model="form.province"
                placeholder="Seleccionar"
                expanded
                required
              >
                <option
                  v-for="(province, index) in provinces.data"
                  :value="province"
                  :key="index"
                >
                  {{ province }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Ciudad*">
              <b-select
                v-model="form.city"
                placeholder="Seleccionar"
                expanded
                required
                :disabled="cities.disabled"
                :loading="cities.loading"
              >
                <option
                  v-for="(city, index) in cities.data"
                  :value="city"
                  :key="index"
                >
                  {{ city }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Dirección*">
              <b-input
                v-model="form.address"
                placeholder="Dirección"
                required
              ></b-input>
            </b-field>
            <b-field label="Código Postal*">
              <b-input
                v-model="form.postal_code"
                placeholder="Código Postal"
                required
              ></b-input>
            </b-field>
            <b-field label="Teléfono*">
              <b-input
                v-model="form.phone"
                type="tel"
                placeholder="Teléfono"
                required
              ></b-input>
            </b-field>
            <b-field label="Whatsapp">
              <b-input
                v-model="form.whatsapp"
                type="tel"
                placeholder="Whatsapp"
              ></b-input>
            </b-field>
            <b-field label="Email*">
              <b-input
                v-model="form.email"
                type="email"
                placeholder="Email"
                required
              ></b-input>
            </b-field>

            <hr />
            <!-- horario Normal-->

            <div>
              <b-field label="Horario Normal">
                <b-switch v-model="form.horaryNormal"> Mostrar Días </b-switch>
              </b-field>

              <div v-if="form.horaryNormal">
                <div v-for="day in daysOfWeek" :key="day">
                  <b-field
                    grouped
                    :label="`${capitalizeFirstLetter(
                      day
                    )} - Horario de Atención`"
                  >
                    <!-- Aquí puedes colocar otros campos y lógica para cada día -->
                  </b-field>
                </div>

                <div v-for="day in form.daysOfWeek" :key="day">
                  <b-field
                    grouped
                    :label="`${translateDay(day)} - Horario de Atención`"
                  >
                    <b-switch v-model="form[`${day}_horaryTypeEnabled`]">
                      Horario corrido │ mañana-tarde
                    </b-switch>

                    <b-field
                      :label="
                        form[`${day}_horaryTypeEnabled`]
                          ? 'Horario de la Mañana'
                          : 'Horario Corrido'
                      "
                      expanded
                    >
                      <b-input
                        v-model="form[`${day}_horary_morning_start`]"
                        type="time"
                        label="Hora de inicio (mañana)"
                       
                      ></b-input>

                      <b-input
                        v-model="form[`${day}_horary_morning_end`]"
                        type="time"
                        label="Hora de inicio (mañana)"
                    
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Horario de la tarde"
                      v-if="form[`${day}_horaryTypeEnabled`]"
                    >
                      <b-input
                        v-model="form[`${day}_horary_afternoon_start`]"
                        type="time"
                        placeholder="Desde"
                        
                      ></b-input>

                      <b-input
                        v-model="form[`${day}_horary_afternoon_end`]"
                        type="time"
                        placeholder="Hasta"
                       
                      ></b-input>
                    </b-field>
                  </b-field>
                </div>
              </div>
            </div>
            <!--fin  horario normal -->

            <hr />

            <!--teleasistencia -->

             <div>
              <b-field label="Teleasistencia">
                <b-switch v-model="form.teleAssistance"> Mostrar Días </b-switch>
              </b-field>

              <div v-if="form.teleAssistance">
                <div v-for="day in daysOfWeek" :key="day">
                  <b-field
                    grouped
                    :label="`${capitalizeFirstLetter(
                      day
                    )} - Horario de Atención`"
                  >
                    <!-- Aquí puedes colocar otros campos y lógica para cada día -->
                  </b-field>
                </div>

                <div v-for="day in form.daysOfWeek" :key="day">
                  <b-field
                    grouped
                    :label="`${translateDay(day)} - Horario de Atención`"
                  >
                    <b-switch v-model="form[`${day}_teleAssistanceType`]">
                      Horario corrido │ mañana-tarde
                    </b-switch>

                    <b-field
                      :label="
                        form[`${day}_teleAssistanceType`]
                          ? 'Horario de la Mañana'
                          : 'Horario Corrido'
                      "
                      expanded
                    >
                      <b-input
                        v-model="form[`${day}_tele_assistance_morning_start`]"
                        type="time"
                        label="Hora de inicio (mañana)"
                      ></b-input>

                      <b-input
                        v-model="form[`${day}_tele_assistance_morning_end`]"
                        type="time"
                        label="Hora de inicio (mañana)"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Horario de la tarde"
                      v-if="form[`${day}_teleAssistanceType`]"
                    >
                      <b-input
                        v-model="form[`${day}_tele_assistance_afternoon_start`]"
                        type="time"
                        placeholder="Desde"
                      ></b-input>

                      <b-input
                        v-model="form[`${day}_tele_assistance_afternoon_end`]"
                        type="time"
                        placeholder="Hasta"
                      ></b-input>
                    </b-field>
                  </b-field>
                </div>
              </div>
            </div>

            <!--fin teleasistencia -->
            <hr>

            <!--turno previo -->

             <div>
              <b-field label="Atención con turno previo">
                <b-switch v-model="form.shift"> Mostrar Días </b-switch>
              </b-field>

              <div v-if="form.shift">
                <div v-for="day in daysOfWeek" :key="day">
                  <b-field
                    grouped
                    :label="`${capitalizeFirstLetter(
                      day
                    )} - Horario de Atención`"
                  >
                    <!-- Aquí puedes colocar otros campos y lógica para cada día -->
                  </b-field>
                </div>

                <div v-for="day in form.daysOfWeek" :key="day">
                  <b-field
                    grouped
                    :label="`${translateDay(day)} - Horario de Atención`"
                  >
                    <b-switch v-model="form[`${day}_shiftType`]">
                      Horario corrido │ mañana-tarde
                    </b-switch>

                    <b-field
                      :label="
                        form[`${day}_shiftType`]
                          ? 'Horario de la Mañana'
                          : 'Horario Corrido'
                      "
                      expanded
                    >
                      <b-input
                        v-model="form[`${day}_shift_morning_start`]"
                        type="time"
                        label="Hora de inicio (mañana)"
                      ></b-input>

                      <b-input
                        v-model="form[`${day}_shift_morning_end`]"
                        type="time"
                        label="Hora de inicio (mañana)"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Horario de la tarde"
                      v-if="form[`${day}_shiftType`]"
                    >
                      <b-input
                        v-model="form[`${day}_shift_afternoon_start`]"
                        type="time"
                        placeholder="Desde"
                      ></b-input>

                      <b-input
                        v-model="form[`${day}_shift_afternoon_end`]"
                        type="time"
                        placeholder="Hasta"
                      ></b-input>
                    </b-field>
                  </b-field>
                </div>
              </div>
            </div>

            <!--turno previo -->

            <hr />
            <b-field>
              <b-dropdown
                v-model="form.users"
                multiple
                :scrollable="true"
                :max-height="200"
                aria-role="list"
              >
                <template #trigger>
                  <b-button
                    type="is-primary"
                    icon-pack="fas"
                    icon-right="angle-down"
                  >
                    Responsables ({{ form.users.length }})
                  </b-button>
                </template>
                <b-dropdown-item
                  v-for="(agent, index) in agents.data"
                  :key="index"
                  :value="agent.id"
                  aria-role="listitem"
                >
                  <span>{{ agent.name }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
            <hr />
            <div class="buttons">
              <b-button
                native-type="submit"
                type="is-primary"
                :expanded="!$route.params.id"
              >
                Guardar
              </b-button>
              <b-button
                v-if="$route.params.id"
                @click="destroy"
                type="is-danger"
              >
                Eliminar
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Loader from "./Loader.vue";
import {
  alertSuccess,
  alertFailed,
  alertInfo,
  provinces,
  cities,
} from "@/helpers";
export default {
  name: "DelegationForm",
  components: {
    Loader,
  },
  data() {
    return {
      form: {
        id: "",
        name: "",
        type: null,
        email: "",
        province: false,
        city: false,
        address: "",
        postal_code: "",
        phone: "",
        whatsapp: "",
        users: [],
        submit: this.$route.params.id ? this.update : this.create,
        horaryNormal: false,
        teleAssistance: false,
        shift: false,
        daysOfWeek: [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ],
      },
      types: {
        data: [],
      },
      provinces: {
        data: [],
      },
      cities: {
        data: [],
        disabled: true,
      },
      agents: {
        data: [],
      },
      loading: false,
    };
  },

  created() {



  this.form.daysOfWeek.forEach(day => {
   
 
    this.$set(this.form, `${day}_horary_morning_start`, null);
    this.$set(this.form, `${day}_horary_morning_end`, null);
    this.$set(this.form, `${day}_horary_afternoon_start`, null);
    this.$set(this.form, `${day}_horary_afternoon_end`, null);


    this.$set(this.form, `${day}_tele_assistance_morning_start`, null);
    this.$set(this.form, `${day}_tele_assistance_morning_end`, null);
    this.$set(this.form, `${day}_tele_assistance_afternoon_start`, null);
    this.$set(this.form, `${day}_tele_assistance_afternoon_end`, null);


    this.$set(this.form, `${day}_shift_morning_start`, null);
    this.$set(this.form, `${day}_shift_morning_end`, null);
    this.$set(this.form, `${day}_shift_afternoon_start`, null);
    this.$set(this.form, `${day}_shift_afternoon_end`, null);

    

  });
  },


  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    translateDay(day) {
      const daysTranslations = {
        monday: "Lunes",
        tuesday: "Martes",
        wednesday: "Miércoles",
        thursday: "Jueves",
        friday: "Viernes",
        saturday: "Sábado",
        sunday: "Domingo",
      };
      return daysTranslations[day.toLowerCase()] || day;
    },


  
    async fetchDelegations() {
      try {
        const id = this.$route.params.id;
        const query = await this.$repositories.delegations.show(id);
        const item = query.data.delegation;
        console.log(item)
        this.form.id = item.id;
        this.form.name = item.name;
        this.form.type = item.type;
        this.form.email = item.email;
        this.form.province = item.province;
        this.form.city = item.city;
        this.form.address = item.address;
        this.form.postal_code = item.postal_code;
        this.form.phone = item.phone;
        this.form.whatsapp = item.whatsapp;

        // Recorre los días de la semana
        this.form.daysOfWeek.forEach(day => {
          // Asignaciones para horarios normales
          this.$set(this.form, `${day}_horary_morning_start`, item[`${day}_horary_morning_start`]);
          this.$set(this.form, `${day}_horary_morning_end`, item[`${day}_horary_morning_end`]);
          this.$set(this.form, `${day}_horary_afternoon_start`, item[`${day}_horary_afternoon_start`]);
          this.$set(this.form, `${day}_horary_afternoon_end`, item[`${day}_horary_afternoon_end`]);

          // Asignaciones para teleasistencia
          this.$set(this.form, `${day}_tele_assistance_morning_start`, item[`${day}_tele_assistance_morning_start`]);
          this.$set(this.form, `${day}_tele_assistance_morning_end`, item[`${day}_tele_assistance_morning_end`]);
          this.$set(this.form, `${day}_tele_assistance_afternoon_start`, item[`${day}_tele_assistance_afternoon_start`]);
          this.$set(this.form, `${day}_tele_assistance_afternoon_end`, item[`${day}_tele_assistance_afternoon_end`]);

          // Asignaciones para turno previo
          this.$set(this.form, `${day}_shift_morning_start`, item[`${day}_shift_morning_start`]);
          this.$set(this.form, `${day}_shift_morning_end`, item[`${day}_shift_morning_end`]);
          this.$set(this.form, `${day}_shift_afternoon_start`, item[`${day}_shift_afternoon_start`]);
          this.$set(this.form, `${day}_shift_afternoon_end`, item[`${day}_shift_afternoon_end`]);

          const hasHoraryData =
              this.form[`${day}_horary_morning_start`] != null ||
              this.form[`${day}_horary_morning_end`] != null ||
              this.form[`${day}_horary_afternoon_start`] != null ||
              this.form[`${day}_horary_afternoon_end`] != null;

          const hasTeleAssistanceData =
              this.form[`${day}_tele_assistance_morning_start`] != null ||
              this.form[`${day}_tele_assistance_morning_end`] != null ||
              this.form[`${day}_tele_assistance_afternoon_start`] != null ||
              this.form[`${day}_tele_assistance_afternoon_end`] != null;

          const hasShiftData =
              this.form[`${day}_shift_morning_start`] != null ||
              this.form[`${day}_shift_morning_end`] != null ||
              this.form[`${day}_shift_afternoon_start`] != null ||
              this.form[`${day}_shift_afternoon_end`] != null;

          // Setear las variables correspondientes a true si hay datos
          if (hasHoraryData) {
              this.form.horaryNormal = true;
          }

          if (hasTeleAssistanceData) {
              this.form.teleAssistance = true;
          }

          if (hasShiftData) {
              this.form.shift = true;
          }


          const hasAfternoonHorary =
            this.form[`${day}_horary_afternoon_start`] != null ||
            this.form[`${day}_horary_afternoon_end`] != null ;

          if (hasAfternoonHorary) {
               this.$set(this.form, `${day}_horaryTypeEnabled`,true);
          }

          const hasAfternoonTeleAssistance =
           this.form[`${day}_tele_assistance_afternoon_start`] != null ||
           this.form[`${day}_tele_assistance_afternoon_end`] != null ;

          if (hasAfternoonTeleAssistance) {
               this.$set(this.form, `${day}_teleAssistanceType`,true);
          }

          const hasAfternoonShift =
           this.form[`${day}_shift_afternoon_start`] != null ||
           this.form[`${day}_shift_afternoon_end`] != null ;

          if (hasAfternoonShift) {
               this.$set(this.form, `${day}_shiftType`,true);
          }
        

        
          
        });

        if (item.user !== null) {
          const users = item.user;
          users.forEach((user) => {
            this.form.users.push(user.id);
          });
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async fetchDelegationsTypes() {
      try {
        const query = await this.$repositories.delegations.types();
        this.types.data = query.data.types;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAgents() {
      try {
        const query = await this.$repositories.agents.departament("all");
        query.data.users.forEach((item) => {
          const items = {
            id: item.id,
            name: `${item.name} ${item.lastname}`,
          };
          this.agents.data.push(items);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async create() {
      try {
        this.loading = true;
        const data = new FormData();
        data.append("name", this.form.name);
        data.append("type", this.form.type);
        data.append("email", this.form.email);
        data.append("province", this.form.province);
        data.append("city", this.form.city);
        data.append("address", this.form.address);
        data.append("postal_code", this.form.postal_code);
    
        data.append("phone", this.form.phone);
        data.append("whatsapp", this.form.whatsapp);
        data.append("users", this.form.users);

         this.form.daysOfWeek.forEach(day => {

      const hasMorningHorary = this.form[`${day}_horary_morning_start`] !== null && this.form[`${day}_horary_morning_start`] !== undefined;
      const hasAfternoonHorary = this.form[`${day}_horary_afternoon_start`] !== null && this.form[`${day}_horary_afternoon_start`] !== undefined;

      if (hasMorningHorary || hasAfternoonHorary) {
        if (hasMorningHorary) {
          data.append(`${day}_horary_morning_start`, this.form[`${day}_horary_morning_start`]);
          data.append(`${day}_horary_morning_end`, this.form[`${day}_horary_morning_end`]);
        }

        if (hasAfternoonHorary) {
          data.append(`${day}_horary_afternoon_start`, this.form[`${day}_horary_afternoon_start`]);
          data.append(`${day}_horary_afternoon_end`, this.form[`${day}_horary_afternoon_end`]);
        }
      }


      const hasMorningTeleAssistance = this.form[`${day}_tele_assistance_morning_start`] !== null && this.form[`${day}_tele_assistance_morning_start`] !== undefined;
      const hasAfternoonTeleAssistance = this.form[`${day}_tele_assistance_afternoon_start`] !== null && this.form[`${day}_tele_assistance_afternoon_start`] !== undefined;

      if (hasMorningTeleAssistance || hasAfternoonTeleAssistance) {
        if (hasMorningTeleAssistance) {
          data.append(`${day}_tele_assistance_morning_start`, this.form[`${day}_tele_assistance_morning_start`]);
          data.append(`${day}_tele_assistance_morning_end`, this.form[`${day}_tele_assistance_morning_end`]);
        }

        if (hasAfternoonTeleAssistance) {
          data.append(`${day}_tele_assistance_afternoon_start`, this.form[`${day}_tele_assistance_afternoon_start`]);
          data.append(`${day}_tele_assistance_afternoon_end`, this.form[`${day}_tele_assistance_afternoon_end`]);
        }
      }
      
      const hasMorningShift = this.form[`${day}_shift_morning_start`] !== null && this.form[`${day}_tele_assistance_afternoon_start`] !== undefined;
      const hasAfternoonShift = this.form[`${day}_shift_afternoon_start`] !== null && this.form[`${day}_tele_assistance_afternoon_start`] !== undefined;

      if (hasMorningShift || hasAfternoonShift) {
        if (hasMorningShift) {
          data.append(`${day}_shift_morning_start`, this.form[`${day}_shift_morning_start`]);
          data.append(`${day}_shift_morning_end`, this.form[`${day}_shift_morning_end`]);
        }

        if (hasAfternoonShift) {
          data.append(`${day}_shift_afternoon_start`, this.form[`${day}_shift_afternoon_start`]);
          data.append(`${day}_shift_afternoon_end`, this.form[`${day}_shift_afternoon_end`]);
        }
      }
     
  
    });
        await this.$repositories.delegations.create(data);

        this.$router.push("/delegaciones");
        alertSuccess("Registro exitoso!");
        this.loading = false;
      } catch (error) {
        this.loading = false;
        alertFailed("Ha ocurrido un error!");
      }
    },



    async update() {

      let missingSchedule = false;
      try {
      
        this.loading = true;
        const id = this.form.id;
        const data = new FormData();
        data.append("_method", "put");
        data.append("name", this.form.name);
        data.append("type", this.form.type);
        data.append("email", this.form.email);
        data.append("province", this.form.province);
        data.append("city", this.form.city);
        data.append("address", this.form.address);
        data.append("postal_code", this.form.postal_code);
        data.append("phone", this.form.phone);
        data.append("whatsapp", this.form.whatsapp);
        data.append("users", this.form.users);

    
      this.form.daysOfWeek.forEach(day => {


      const hasMorningHorary = this.form[`${day}_horary_morning_start`] !== null && this.form[`${day}_horary_morning_start`] !== undefined;
      const hasAfternoonHorary = this.form[`${day}_horary_afternoon_start`] !== null && this.form[`${day}_horary_afternoon_start`] !== undefined;

      if (hasMorningHorary || hasAfternoonHorary) {
        if (hasMorningHorary) {
          data.append(`${day}_horary_morning_start`, this.form[`${day}_horary_morning_start`]);
          data.append(`${day}_horary_morning_end`, this.form[`${day}_horary_morning_end`]);
        }

        if (hasAfternoonHorary) {
          data.append(`${day}_horary_afternoon_start`, this.form[`${day}_horary_afternoon_start`]);
          data.append(`${day}_horary_afternoon_end`, this.form[`${day}_horary_afternoon_end`]);
        }
      }


      const hasMorningTeleAssistance = this.form[`${day}_tele_assistance_morning_start`] !== null && this.form[`${day}_tele_assistance_morning_start`] !== undefined;
      const hasAfternoonTeleAssistance = this.form[`${day}_tele_assistance_afternoon_start`] !== null && this.form[`${day}_tele_assistance_afternoon_start`] !== undefined;

      if (hasMorningTeleAssistance || hasAfternoonTeleAssistance) {
        if (hasMorningTeleAssistance) {
          data.append(`${day}_tele_assistance_morning_start`, this.form[`${day}_tele_assistance_morning_start`]);
          data.append(`${day}_tele_assistance_morning_end`, this.form[`${day}_tele_assistance_morning_end`]);
        }

        if (hasAfternoonTeleAssistance) {
          data.append(`${day}_tele_assistance_afternoon_start`, this.form[`${day}_tele_assistance_afternoon_start`]);
          data.append(`${day}_tele_assistance_afternoon_end`, this.form[`${day}_tele_assistance_afternoon_end`]);
        }
      }
      
      const hasMorningShift = this.form[`${day}_shift_morning_start`] !== null && this.form[`${day}_tele_assistance_afternoon_start`] !== undefined;
      const hasAfternoonShift = this.form[`${day}_shift_afternoon_start`] !== null && this.form[`${day}_tele_assistance_afternoon_start`] !== undefined;

      if (hasMorningShift || hasAfternoonShift) {
        if (hasMorningShift) {
          data.append(`${day}_shift_morning_start`, this.form[`${day}_shift_morning_start`]);
          data.append(`${day}_shift_morning_end`, this.form[`${day}_shift_morning_end`]);
        }

        if (hasAfternoonShift) {
          data.append(`${day}_shift_afternoon_start`, this.form[`${day}_shift_afternoon_start`]);
          data.append(`${day}_shift_afternoon_end`, this.form[`${day}_shift_afternoon_end`]);
        }
      }



        const morningStart = this.form[`${day}_horary_morning_start`];
        const morningEnd = this.form[`${day}_horary_morning_end`];
        const afternoonStart = this.form[`${day}_horary_afternoon_start`];
        const afternoonEnd = this.form[`${day}_horary_afternoon_end`];

        const teleMorningStart = this.form[`${day}_tele_assistance_morning_start`];
        const teleMorningEnd = this.form[`${day}_tele_assistance_morning_end`];
        const teleAfternoonStart = this.form[`${day}_tele_assistance_afternoon_start`];
        const teleAfternoonEnd = this.form[`${day}_tele_assistance_afternoon_end`];

        const shiftMorningStart = this.form[`${day}_shift_morning_start`];
        const shiftMorningEnd = this.form[`${day}_shift_morning_end`];
        const shiftAfternoonStart = this.form[`${day}_shift_afternoon_start`];
        const shiftAfternoonEnd = this.form[`${day}_shift_afternoon_end`];

        const isMorningIncomplete = (morningStart !== null && morningStart !== '') !== (morningEnd !== null && morningEnd !== '');
        const isAfternoonIncomplete = (afternoonStart !== null && afternoonStart !== '') !== (afternoonEnd !== null && afternoonEnd !== '');

        const isTeleMorningIncomplete = (teleMorningStart !== null && teleMorningStart !== '') !== (teleMorningEnd !== null && teleMorningEnd !== '');
        const isTeleAfternoonIncomplete = (teleAfternoonStart !== null && teleAfternoonStart !== '') !== (teleAfternoonEnd !== null && teleAfternoonEnd !== '');

        const isShiftMorningIncomplete = (shiftMorningStart !== null && shiftMorningStart !== '') !== (shiftMorningEnd !== null && shiftMorningEnd !== '');
        const isShiftAfternoonIncomplete = (shiftAfternoonStart !== null && shiftAfternoonStart !== '') !== (shiftAfternoonEnd !== null && shiftAfternoonEnd !== '');

        if (isMorningIncomplete || isAfternoonIncomplete || isTeleMorningIncomplete || isTeleAfternoonIncomplete || isShiftMorningIncomplete || isShiftAfternoonIncomplete) {
          missingSchedule = true;
        }
     
  
         });

     
        if (missingSchedule) {
          this.loading = false;
          alertFailed("Falta completar un horario");
          return;
        }

        await this.$repositories.delegations.update(id, data);
        alertSuccess("Registro existoso!");
        this.loading = false;
      } catch (error) {
        this.loading = false;
        alertFailed("Ha ocurrido un error!");
      }
    },


    async destroy() {
      try {
        this.loading = true;
        const id = this.form.id;
        await this.$repositories.delegations.delete(id);
        alertInfo("Se ha eliminado correctamente!");
        this.$router.push("/delegaciones");
      } catch (error) {
        this.loading = false;
        alertFailed("Ha ocurrido un error!");
      }
    },
    actionForm() {
      this.$route.params.id ? this.update : this.create;
    },
    getProvinces() {
      this.provinces.data = provinces();
    },
    getCities() {
      this.cities.data = cities(this.form.province);
      this.cities.disabled = false;
    },
  },
  async created() {
    await this.getProvinces();
    await this.fetchAgents();
    await this.fetchDelegationsTypes();
    if (this.$route.params.id) {
      this.loading = true;
      await this.fetchDelegations();
      await this.getCities();
    }
  },
  watch: {
    "$route.params.id": async function () {
      if (this.$route.params.id) await this.fetchDepartament();
    },
  },
};
</script>

<style lang="scss">
#delegation-form {
  height: 100vh;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-y: auto;
  form {
    input,
    select,
    textarea {
      border: none;
    }
    input,
    select,
    textarea {
      &.is-danger {
        border: 1px solid $danger;
      }
    }
    select[disabled] {
      border: 1px solid #e5e5e5;
    }
  }
  hr {
    background-color: #e8e8e8 !important;
  }
}
</style>