<template>
    <div id="agents-table">
        <div class="card">
            <div class="card-content">
                <b-field>
                    <Search @value="getValue"/>
                </b-field>
                <b-table    
                    hoverable
                    paginated
                    pagination-simple
                    backend-pagination
                    @page-change="onPageChange"
                    :scrollable="true"
                    :sticky-header="true"
                    :total="departaments.total"
                    :per-page="departaments.perPage"
                    height="350"
                    detailed	
                    custom-detail-row
                    :opened-detailed="['Ventas']"
                    detail-key="id"
                    :loading="departaments.loading"
                    :data="departaments.isEmpty ? [] : departaments.data">

                    <b-table-column field="name" label="Nombre" v-slot="props">
                        <template v-if="departaments.showDetailIcon">
                            {{ props.row.name }}
                        </template>
                        <template v-else>
                            <a @click="toggle(props.row)">
                                {{ props.row.name }}
                            </a>
                        </template>
                    </b-table-column>

                    <b-table-column field="description" label="Descripción" v-slot="props">
                        {{ props.row.description }}
                    </b-table-column>

                    <b-table-column label="" centered v-slot="props" :width="40" >
                        <b-button
                            tag="router-link"
                            :to="`/departamentos/editar/${props.row.id}`" 
                            icon-pack="fas"
                            icon-left="pencil-alt"
                            class="mr-1">
                        </b-button>   

                        
                                             
                    </b-table-column>

                     <b-table-column label=""  centered v-slot="props" v-if="this.$store.getters['getSession']['is_admin']" :width="40" >
                        <b-button 
                            tag="router-link"
                            :to="`/departamentos/permissions/${props.row.id}/${encodeURIComponent(props.row.name)}`"
                             icon-pack="fas"
                            icon-left="cog"
                            class="mr-1"
                            > 
                        </b-button>            
                    </b-table-column>


                    
                    
                    <template #empty>
                        <div class="has-text-centered">No se encontraron resultados</div>
                    </template>

                    <template slot="detail" slot-scope="props" >
                        <tr v-for="item in props.row.items" :key="item.name">
                            <td v-if="showDetailIcon"></td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.description }}</td>
                            <td v-if="this.$hasPermission('departamentos.editar')">
                                <b-button
                                    tag="router-link"
                                    :to="`/departamentos/editar/${item.id}`" 
                                    icon-pack="fas"
                                    icon-left="pencil-alt">
                                </b-button> 
                            </td>
                        </tr>
                    </template>
                </b-table>
            </div>
        </div>
    </div>   
</template>

<script>
    import Search from './Search.vue';
    export default {
        name:'DepartamentsTable',
        data() {
            return {
                departaments:{
                    data: [],
                    total: 0,
                    order: 'desc',
                    page: 1,
                    perPage: 5,
                    loading: false,
                    isEmpty: false,
                    search:'',
                    showDetailIcon: true
                }
            }
        },
        components:{
            Search
        },
methods: {
    async fetchDepartaments(){
        try {
            this.departaments.loading = true;
            const query = await this.$repositories.departaments.index(this.departaments.page, this.departaments.search);
            this.departaments.data = [];
            this.departaments.total = query.data.departaments.total; 
            if(query.data.departaments.data.length > 0) {
                query.data.departaments.data.forEach(item => {
                    const ITEMS = {
                        id: item.id,
                        name: item.name,
                        description: item.description,
                        item: (item.is_marketer) ? [] : '' // Inicializa item como un array vacío si es marketer, de lo contrario como una cadena vacía
                    };                     
                    this.departaments.data.push(ITEMS);
                });
            }
            this.departaments.loading = false;
        } catch (error) {
            this.departaments.loading = false;
            console.log(error);
        }
    },
    onPageChange(page) {
        this.departaments.page = page
        this.fetchDepartaments()
    },
    toggle(row) {
        this.$refs.table.toggleDetails(row)
    },
    getValue(value){
        this.departaments.search = value;
        this.departaments.loading = true;
        this.fetchDepartaments();
    },
},

        created(){
            this.fetchDepartaments();
        }
    }
</script>