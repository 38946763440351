<template>
  <div id="providers-page" class="page-container">
    <div class="tabs">
      <ul>
       
        <li :class="{ 'is-active': activeTab === 'tab2' }">
          <a @click="changeTab('tab2')">Facturación</a>
        </li>
         <li :class="{ 'is-active': activeTab === 'tab1' }">
          <a @click="changeTab('tab1')">Listado de Prestadores</a>
        </li>
      </ul>
    </div>

    <div class="columns">
      <div class="column">
        <component :is="activeComponent"/>
      </div>
    </div>
  </div>
</template>

<script>
import ProviderTable from '@/components/Providers-table.vue';
import LendingTable from '@/components/Lending-table.vue'; // Importa el otro componente

export default {
  name: 'ProvidersPage',
  components: {
    ProviderTable,
    LendingTable, // Agrega el componente importado
  },
  data() {
    return {
      activeTab: 'tab2', // Establece la pestaña activa por defecto
      search: '',
    };
  },
  computed: {
    activeComponent() {
      // Retorna el componente correspondiente según la pestaña activa
      return this.activeTab === 'tab1' ? 'ProviderTable' : 'LendingTable';
    },
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style scoped>
.page-container {
  margin-left: 2%;   /* Aplica un margen izquierdo del 5% */
}
</style>





