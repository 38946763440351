<template>
  <div id="departament-form">
    <Loader :active="loading"></Loader>
    <div class="columns">
      <div class="column is-6">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <div>
                <h1 class="title has-text-primary">
                  {{
                    $route.params.id
                      ? "Editar Departamento"
                      : "Nuevo Departamento"
                  }}
                </h1>
                <nav class="breadcrumb" aria-label="breadcrumbs">
                  <ul>
                    <li>
                      <router-link :to="'/departamentos'"
                        >Departamentos</router-link
                      >
                    </li>
                    <li class="is-active">
                      <a href="#" aria-current="page">{{
                        $route.params.id
                          ? "Editar Departamento"
                          : "Nuevo Departamento"
                      }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-6">
        <div class="form-content">
          <form @submit.prevent="submit">
            <b-field label="Nombre*">
              <b-input v-model="name" placeholder="Nombre" required></b-input>
            </b-field>
            <b-field label="Descripción">
              <b-input v-model="description" maxlength="200" type="textarea">
              </b-input>
            </b-field>
              <b-field label="Gerencia*">
              <div class="select is-fullwidth">
                <select v-model="selectedGerency">
                  <option value="" disabled>Selecciona una gerencia</option>
                   <option v-for="gerency in gerencies" :value="gerency.id" :key="gerency.id">{{ gerency.name }}</option>
                
                </select>
              </div>
            </b-field>
            <b-field>
              <b-checkbox v-model="marketer" :value="false" type="is-success">
                Este departamento es una comercializadora
                <b-tooltip type="is-dark" position="is-right" multilined>
                  <b-icon pack="fas" icon="question-circle" size="is-small">
                  </b-icon>
                  <template v-slot:content>
                    Las comercializadoras pertenecen al área de <b>Ventas</b>.
                  </template>
                </b-tooltip>
              </b-checkbox>
            </b-field>
          
            <hr />
            <div class="buttons">
              <div v-if="this.$hasPermission('departamentos.eliminar')">
              <b-button
                v-if="$route.params.id && $route.params.id != 1"
                @click="destroy"
                type="is-secoundary"
              >
                Eliminar
              </b-button>
              </div>
              <b-button
                native-type="submit"
                type="is-primary"
                :expanded="!$route.params.id"
              >
                Guardar
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader.vue";
import { alertSuccess, alertFailed, alertInfo } from "@/helpers";
export default {
  name: "AgentForm",
  components: {
    Loader,
  },
  data() {
    return {
      id: "",
      name: "",
      description: "",
      marketer: false,
      submit: this.$route.params.id ? this.update : this.create,
      loading: false,
      selectedGerency: "", // Para almacenar la gerencia seleccionada
      gerencies: [], // Para almacenar la lista de gerencias
    };
  },
  methods: {
    async fetchDepartament() {
      try {
        this.loading = true;
        const id = this.$route.params.id;
        const query = await this.$repositories.departaments.show(id);
        const item = query.data.departament;
        console.log(item)
        this.id = item.id;
        this.name = item.name;
        this.description = item.description;
        this.marketer = Boolean(item.is_marketer);
        this.selectedGerency = item.id_gerencie.toString();
        this.loading = false;

      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async fetchGerencies() {
      try {
        this.loading = true;
        const id = this.$route.params.id;
        const query = await this.$repositories.gerencies.index();
         this.gerencies = query.data.gerencies; 
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async create() {
      try {
        console.log("action", "create");
        this.loading = true;
        const data = new FormData();
        data.append("name", this.name);
        data.append("description", this.description);
        data.append("is_marketer", this.marketer);
        data.append("id_gerencie", this.selectedGerency);
        await this.$repositories.departaments.create(data);

        this.$router.push("/departamentos");
        alertSuccess("Registro exitoso!");
        this.loading = false;
      } catch (error) {
        this.loading = false;
        alertFailed("Ha ocurrido un error!");
      }
    },
    async update() {
      try {
        this.loading = true;
        const id = this.id;
        const data = new FormData();
        data.append("_method", "put");
        data.append("name", this.name);
        data.append("description", this.description);
        data.append("is_marketer", this.marketer);
        data.append("id_gerencie", this.selectedGerency);
      
        await this.$repositories.departaments.update(id, data);
        alertSuccess("Registro existoso!");
        this.loading = false;
      } catch (error) {
        this.loading = false;
        alertFailed("Ha ocurrido un error!");
      }
    },
    async destroy() {
      try {
        this.loading = true;
        const id = this.id;
        await this.$repositories.departaments.delete(id);
        alertInfo("Se ha eliminado correctamente!");
        this.$router.push("/departamentos");
      } catch (error) {
        this.loading = false;
        alertFailed("Ha ocurrido un error!");
      }
    },
    actionForm() {
      this.$route.params.id ? this.update : this.create;
    },
  },
  created() {
    this.fetchGerencies();
    if (this.$route.params.id) this.fetchDepartament();
  },
  watch: {
    "$route.params.id": async function () {
      if (this.$route.params.id) await this.fetchDepartament();
    },
  },
};
</script>

<style lang="scss">
#departament-form {
  height: 100vh;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-y: auto;
  form {
    input,
    select,
    textarea {
      border: none;
    }
    input,
    select,
    textarea {
      &.is-danger {
        border: 1px solid $danger;
      }
    }
    select[disabled] {
      border: 1px solid #e5e5e5;
    }
  }
  hr {
    background-color: #e8e8e8 !important;
  }
}
</style>
