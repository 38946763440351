var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"tickets"}},[_c('Loader',{attrs:{"active":_vm.loading,"full":false}}),_c('section',[_c('b-notification',{attrs:{"aria-close-label":"Close notification"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_vm._v(" ⚠️ PTSSS! ¡Atención! A partir de ahora ya no será más obligatorio indicar el email cuando se genere un nuevo contacto, ya sea en el módulo ventas como en el módulo consultas. A partir de ahora, el campo obligatorio será el CELULAR. ☝️ Recordá siempre solicitarlo. ")])],1),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('h1',{staticClass:"title has-text-primary"},[_vm._v(" Bienvenido, "+_vm._s(this.$store.getters["getSession"]["name"])+" ")])])])])]),_c('div',{staticClass:"columns"},[(!this.$store.getters['getSession']['departament_marketer'])?_c('div',{staticClass:"column",staticStyle:{"overflow-y":"auto","max-height":"400px","max-width":"450px"}},[_c('h2',{staticClass:"title is-4"},[_vm._v("ÚLTIMAS CONSULTAS ASIGNADAS")]),(_vm.Ticket.length > 0)?_c('div',[_vm._l((_vm.Ticket),function(ticket){return _c('div',{key:ticket.id},[_c('TicketCard',{attrs:{"id":ticket.id,"link":`/consultas/id/${ticket.id}`,"title":ticket.contact.fullname,"description":ticket.departament.name,"status":ticket.status}})],1)}),_c('router-link',{staticClass:"has-text-primary",staticStyle:{"display":"block","text-align":"center","font-size":"1.5rem"},attrs:{"to":"/consultas"}},[_vm._v(" Ver Todas ")])],2):_c('div',[_c('h5',[_vm._v("No hay consultas asignadas")])])]):_vm._e(),(
        [1, 2, 6].includes(
          this.$store.getters['getSession']['departament_id']
        ) || this.$store.getters['getSession']['departament_marketer']
      )?_c('div',{staticClass:"column",staticStyle:{"overflow-y":"auto","max-height":"400px","max-width":"450px"}},[_c('h2',{staticClass:"title is-4"},[_vm._v("ÚLTIMAS VENTAS ASIGNADAS")]),(_vm.Sales.length > 0)?_c('div',[_vm._l((_vm.Sales),function(sale){return _c('div',{key:sale.id},[_c('SaleCard',{attrs:{"sale":{
              id: sale.id,
              color: sale.color,
              status: sale.status,
              contact_fullname: sale.contact.fullname,
              ticket_id: sale.ticket_id,
              province: sale.contact.province,
              phone: sale.contact.celphone,
            },"link":`/ventas/id/${sale.id}`}})],1)}),_c('router-link',{staticClass:"has-text-primary",staticStyle:{"display":"block","text-align":"center","font-size":"1.5rem"},attrs:{"to":"/ventas"}},[_vm._v(" Ver Todas ")])],2):_c('div',[_c('h5',[_vm._v("No hay ventas asignadas")])])]):_vm._e(),(
        [6, 2, 16].includes(
          this.$store.getters['getSession']['departament_id']
        )
      )?_c('div',{staticClass:"column",staticStyle:{"overflow-y":"auto","max-height":"400px","max-width":"450px"}},[_c('h2',{staticClass:"title is-4"},[_vm._v("ÚLTIMAS PRESTACIONES ASIGNADAS")]),_vm._l((_vm.Invoices),function(invoice){return _c('div',{key:invoice.id},[_c('InvoiceCard',{attrs:{"id":invoice.id,"link":`/prestadores`,"title":invoice.provider.comercial_name,"description":invoice.provider.province,"status":invoice.state,"phone":invoice.provider.phone}})],1)}),_c('router-link',{staticClass:"has-text-primary",staticStyle:{"display":"block","text-align":"center","font-size":"1.5rem"},attrs:{"to":"/prestadores"}},[_vm._v(" Ver Todas ")])],2):_vm._e()]),_c('br'),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-fullwidth"},[_c('h2',{staticClass:"title is-4"},[_vm._v("ACCESOS DIRECTOS")]),_c('div',{staticClass:"button-container"},[_c('a',{staticClass:"button",attrs:{"href":"https://prestadores.osfatun.com.ar/","target":"_blank"}},[_vm._v("CARTILLA DE PRESTADORES")]),_c('a',{staticClass:"button",attrs:{"href":"https://cotizador.osfatun.com.ar/cuotas-y-minimos","target":"_blank"}},[_vm._v("CUOTAS Y MÍNIMOS")]),_c('a',{staticClass:"button",attrs:{"href":"https://www.osfatun.com.ar/datos-y-unidades","target":"_blank"}},[_vm._v("DATOS Y UNIDADES")]),_c('a',{staticClass:"button",attrs:{"href":"https://www.osfatun.com.ar/vademecum","target":"_blank"}},[_vm._v("VADEMÉCUM")])])])])
}]

export { render, staticRenderFns }