<template>
    <router-link :to="link">
        <div class="card" :class="`border-${color}`">
            <div class="card-content">
                <!--
                <button 
                    class="delete"
                    @click.prevent="confirmCustomDelete(id)">
                </button>
                -->
                <div class="level is-mobile">
                    <div class="level-left">
                        <div class="level-item">
                            <div>
                                <h5 class="title is-5 is-spaced has-text-primary is-uppercase">{{ title }}</h5>
                                <h5 class="subtitle is-5">{{ description }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item has-text-centered">
                            <div v-if="is_urgent">
                                <b-button
                                    type="is-danger"
                                    icon-pack="fas"
                                    icon-left="exclamation-circle">
                                </b-button>
                                <small 
                                    class="is-block is-uppercase has-text-weight-bold has-text-danger">
                                    Urgente
                                </small>
                            </div>
                            <div class="ml-5">
                                <h4 class="title is-4 has-text-weight-bold has-text-dark is-uppercase">#{{ ticketId(id) }}</h4>
                                <b-tag 
                                    class="is-uppercase has-text-weight-bold"
                                    :type="`is-${color}`">
                                    {{ status }}
                                </b-tag>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="card-footer">
                <div class="card-footer-item">
                    <div class="tags has-addons">
                        <span class="tag is-uppercase has-text-weight-bold" :type="origin.type">{{ origin.name }}</span>
                        <span class="tag" :type="origin.type">
                            <b-icon
                                :pack="origin.pack"
                                :icon="origin.icon"
                                size="is-small">
                            </b-icon>
                        </span>
                    </div>
                </div>
            </footer>
        </div>
    </router-link>
</template>

<script>    
    import {fillString} from "@/helpers";
    export default {
        name:'TicketCard',
        props:{
            id:{
                type: Number,
                default: 1
            },
            link:{
                type: String,
                default: '/consultas/1248'
            },
            title:{
                type: String,
                default: 'Nombre Afiliado'
            },
            description:{
                type: String,
                default: 'Descripción'
            },
            status:{
                type: String,
                default: 'No atendida'
            },
            priority:{
                type: String,
                default: 'high'
            },
            origin:{
                type: Object,
                default:{
                    type : 'is-light',
                    pack : 'fas',
                    icon : 'globe',
                    name : 'Web'
                },
            },
            is_urgent:{
                type: Boolean,
                default: false
            },
            color:{
                type: String,
                default: 'danger'
            }
        },
        methods:{
            ticketId(value){
                return fillString(value, 4);
            },
            deleteTicket(id){
                console.log(id)
            },
            confirmCustomDelete(id) {
                this.$buefy.dialog.confirm({
                    message: '<b>Atención</b>. Estas seguro que deseas borrar?',
                    cancelText: 'Cancelar',
                    confirmText: 'Ok',
                    hasIcon: true,
                    iconPack: 'fas',
                    icon: 'exclamation-circle',
                    onConfirm: () => this.deleteTicket(id)
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    a{
        &:hover{
            .card{
                button{
                    &.delete{
                        opacity: 1;
                    }
                }
            }
        }
        .card{
            button{
                border-radius: 50%!important;
                &.delete{
                    opacity: 0;
                    position: absolute;
                    top: -.5rem;
                    right: -.5rem;
                    transition: .2;
                }
            }
        }
        .card-footer-item{
            justify-content: left;
        }
        .border-dark{
            border: 1px solid $dark;
        }
        .border-success{
            border: 1px solid $success;
        }
        .border-warning{
            border: 1px solid $warning;
        }
        .border-danger{
            border: 1px solid $danger;
        } 
    }
        
</style>
