<template>
  <div class="my-div" style="max-height: 400px; overflow-y: auto">
    <Loader :active="loading" :full="false"></Loader>
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <b-field>
              <b-select placeholder="Seleccionar" v-model="zone" required @change.native="getData">
                <option value="1">Zona A</option>
                <option value="2">Zona B</option>
                <option value="4">Zona C</option>
                <option value="3">Zona D</option>
                <option value="5">Zona E</option>
              
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-select
                placeholder="Seleccionar"
                v-model="tipo_afiliado"
                required
                @change.native="getData"
              >
                <option value="3">Categoría A</option>
                <option value="3">Categoría B</option>
                <option value="3">Categoría C</option>
                <option value="4">Categoría D</option>
                <option value="5">Categoría E</option>
                <option value="6">Categoría F</option>
                <option value="7">Categoría G</option>
                <option value="8">Categoría H</option>
                <option value="9">Categoría I</option>
                <option value="10">Categoría J</option>
                <option value="11">Categoría K</option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
        <b-field>
            <b-select placeholder="Seleccionar" v-model="selectedMonth" required @change.native="getData">
                <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}</option>
            </b-select>
        </b-field>
    </div>
    
   
    <div class="column">
        <b-field>
            <b-select placeholder="Seleccionar" v-model="selectedYear" required @change.native="getData">
                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
            </b-select>
        </b-field>
    </div>
 

    <div class="column  is-one-quarter" >  <b-input id="fileInput" type="file" /></div>
    <div class="column"><b-button @click="importExcelData">Importar</b-button></div>
    <div class="column"> <b-button @click="createExcelFile">Descargar Modelo</b-button></div>
   
        </div>
<hr class="custom-hr">
        <div v-for="(item, index) in data" :key="index">
          <div class="columns">
            <div class="column">
              <h3 class="title is-5" id="label">
                {{ item.family_group_label }}
              </h3>
            </div>
            <div class="column">
              <b-field label="Básico">
                <b-input v-model="item.basico" type="number"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Integral">
                <b-input v-model="item.integral" type="number"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Premium">
                <b-input v-model="item.premium" type="number"></b-input>
              </b-field>
            </div>
             <div class="column">
              <b-field label="UNI 1000">
                <b-input v-model="item.uni1000" type="number"></b-input>
              </b-field>
            </div>
              <div class="column">
              <b-field label="UNI 2000">
                <b-input v-model="item.uni2000" type="number"></b-input>
              </b-field>
            </div>
              <div class="column">
              <b-field label="UNI 3000">
                <b-input v-model="item.uni3000" type="number"></b-input>
              </b-field>
            </div>
              <div class="column">
              <b-field label="UNI 4000">
                <b-input v-model="item.uni4000" type="number"></b-input>
              </b-field>
            </div>
          </div>
        </div>
        <b-button @click="submitForm" v-if="this.$hasPermission('cuotasyminimos.editar')">Enviar</b-button>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { alertSuccess, alertFailed, alertInfo } from "@/helpers";
import Loader from "@/components/Loader.vue";
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs/dist/exceljs';
export default {
   components: {
    Loader
  },
  data() {
    return {
      loading: false,
      zone: 1,
      tipo_afiliado: 3,
      selectedMonth: null,
      selectedYear: null,
        months: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
            'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ],
      years: [2024, 2025, 2026],
       data: [

          {
          family_group_label: "Individual Junior (18 a 30 años)",
          family_group: "individual_junior",
          basico: 0,
          integral: 0,
          premium: 0,
          uni1000: 0,
          uni2000: 0,
          uni3000: 0,
          uni4000: 0,
        },
       
        {
          family_group_label: "Individual Joven (31 a 45 años)",
          family_group: "individual_joven",
          basico: 0,
          integral: 0,
          premium: 0,
          uni1000: 0,
          uni2000: 0,
          uni3000: 0,
          uni4000: 0,
        },
       
         {
          family_group_label: "Individual Adulto (46 a 64 años)",
          family_group: "individual_adulto",
          basico: 0,
          integral: 0,
          premium: 0,
          uni1000: 0,
          uni2000: 0,
          uni3000: 0,
          uni4000: 0,
        },

        {
          family_group_label: "Cónyuge Junior (18 a 30 años)",
          family_group: "matrimonio_junior",
          basico: 0,
          integral: 0,
          premium: 0,
          uni1000: 0,
          uni2000: 0,
          uni3000: 0,
          uni4000: 0,
        },
       
          {
          family_group_label: "Cónyuge Joven (31 a 45 años)",
          family_group: "matrimonio_joven",
          basico: 0,
          integral: 0,
          premium: 0,
          uni1000: 0,
          uni2000: 0,
          uni3000: 0,
          uni4000: 0,
        },
          

         {
          family_group_label: "Cónyuge Adulto (46 a 64 años)",
          family_group: "matrimonio_adulto",
          basico: 0,
          integral: 0,
          premium: 0,
          uni1000: 0,
          uni2000: 0,
          uni3000: 0,
          uni4000: 0,
        },
        
        {
          family_group_label: "Primer hijo dentro del GFP",
          family_group: "hijo_adicional",
          basico: 0,
          integral: 0,
          premium: 0,
          uni1000: 0,
          uni2000: 0,
          uni3000: 0,
          uni4000: 0,
        },
        {
          family_group_label: "Segundo hijo dentro del GFP",
          family_group: "dos_hijos_adicional",
          basico: 0,
          integral: 0,
          premium: 0,
          uni1000: 0,
          uni2000: 0,
          uni3000: 0,
          uni4000: 0,
        },
        {
          family_group_label: "A partir del tercer hijo dentro del GFP",
          family_group: "mas_tres_hijos",
          basico: 0,
          integral: 0,
          premium: 0,
          uni1000: 0,
          uni2000: 0,
          uni3000: 0,
          uni4000: 0,
        },
        
       
       
        {
          family_group_label: "Por cada mayor de 65 años",
          family_group: "mayor65",
          basico: 0,
          integral: 0,
          premium: 0,
          uni1000: 0,
          uni2000: 0,
          uni3000: 0,
          uni4000: 0,
        },
      ],
    };
  },
  methods: {

     async createExcelFile() {
  try {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Datos');

    // Agrega encabezados
    sheet.addRow(['Grupo Familiar',  'Básico', 'Integral', 'Premium', 'UNI 1000', 'UNI 2000', 'UNI 3000', 'UNI 4000']);

    // Agrega datos
    this.data.forEach(row => {
      sheet.addRow([row.family_group_label,  row.basico, row.integral, row.premium, row.uni1000, row.uni2000, row.uni3000, row.uni4000]);
    });

    // Guarda el archivo
    const buffer = await workbook.xlsx.writeBuffer();

    // Crea un blob con los datos
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Crea un objeto URL para el blob
    const url = window.URL.createObjectURL(blob);

    // Crea un enlace y simula un clic para descargar el archivo
    const link = document.createElement('a');
    link.href = url;
    link.download = 'datos.xlsx';
    link.click();

    // Libera el objeto URL
    window.URL.revokeObjectURL(url);
    } catch (error) {
    console.error('Error al crear el archivo Excel:', error);
     }
   },

   importExcelData() {
      const fileInput = document.getElementById('fileInput');
      const file = fileInput.files[0];

      if (!file) {
        alertFailed("Por favor seleccione un archivo primero.");
        return;
      }

      this.isLoading = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          console.log("Workbook leído:", workbook);

          const sheetName = workbook.SheetNames[0];
          const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
          console.log("Hoja leída:", sheet);

          sheet.slice(1).forEach(row => {
            if (row.length < 8) {
              alertFailed("Fila incompleta en el archivo Excel:", row);
              return;
            }

            const familyGroup = row[0];
            const values = row.slice(1, 8);

            const item = this.data.find(d => d.family_group_label === familyGroup);

            if (item) {
              item.basico = values[0] || 0;
              item.integral = values[1] || 0;
              item.premium = values[2] || 0;
              item.uni1000 = values[3] || 0;
              item.uni2000 = values[4] || 0;
              item.uni3000 = values[5] || 0;
              item.uni4000 = values[6] || 0;
            } else {
           alertFailed(`Grupo familiar no encontrado: ${familyGroup}`);
            }
          });

          console.log('Datos actualizados:', this.data);
          alertSuccess('Datos Cargados, haga clic en enviar para que queden registrados')
        } catch (error) {
          console.error('Error al leer el archivo:', error);
          alertFailed("Error al leer el archivo.");
        } finally {
          this.isLoading = false;
        }
      };
      reader.onerror = (e) => {
        console.error('Error al cargar el archivo:', e);
        alertFailed("Error al cargar el archivo.");
        this.isLoading = false;
      };
      reader.readAsArrayBuffer(file);
    },

    getData() {
      this.loading = true;
      axios
        .get(process.env.VUE_APP_ROOT_API+"/api/v1/getplan", {
          params: {
            tipo_afiliado: this.tipo_afiliado,
            zone_id: this.zone,
            month: this.selectedMonth,
            year: this.selectedYear
          },
        })
        .then((data) => {
           console.log(data.data)
           this.loading = false;

          this.data['0']['basico'] = data.data['4'] ? data.data['4']['basico'] : 0;
          this.data['0']['integral'] = data.data['4'] ? data.data['4']['integral'] : 0;
          this.data['0']['premium'] = data.data['4'] ? data.data['4']['premium'] : 0;
          this.data['0']['uni3000'] = data.data['4'] ? data.data['4']['uni3000'] : 0;
          this.data['0']['uni4000'] = data.data['4'] ? data.data['4']['uni4000'] : 0;
          this.data['0']['uni2000'] = data.data['4'] ? data.data['4']['uni2000'] : 0;
          this.data['0']['uni1000'] = data.data['4'] ? data.data['4']['uni1000'] : 0;

          // Individual joven
          this.data['1']['basico'] = data.data['3'] ? data.data['3']['basico'] : 0;
          this.data['1']['integral'] = data.data['3'] ? data.data['3']['integral'] : 0;
          this.data['1']['premium'] = data.data['3'] ? data.data['3']['premium'] : 0;
          this.data['1']['uni2000'] = data.data['3'] ? data.data['3']['uni2000'] : 0;
          this.data['1']['uni3000'] = data.data['3'] ? data.data['3']['uni3000'] : 0;
          this.data['1']['uni4000'] = data.data['3'] ? data.data['3']['uni4000'] : 0;
          this.data['1']['uni1000'] = data.data['3'] ? data.data['3']['uni1000'] : 0;

          // Individual Adulto
          this.data['2']['basico'] = data.data['2'] ? data.data['2']['basico'] : 0;
          this.data['2']['integral'] = data.data['2'] ? data.data['2']['integral'] : 0;
          this.data['2']['premium'] = data.data['2'] ? data.data['2']['premium'] : 0;
          this.data['2']['uni1000'] = data.data['2'] ? data.data['2']['uni1000'] : 0;
          this.data['2']['uni2000'] = data.data['2'] ? data.data['2']['uni2000'] : 0;
          this.data['2']['uni3000'] = data.data['2'] ? data.data['2']['uni3000'] : 0;
          this.data['2']['uni4000'] = data.data['2'] ? data.data['2']['uni4000'] : 0;

          // Matrimonio junior
          this.data['3']['basico'] = data.data['8'] ? data.data['8']['basico'] : 0;
          this.data['3']['integral'] = data.data['8'] ? data.data['8']['integral'] : 0;
          this.data['3']['premium'] = data.data['8'] ? data.data['8']['premium'] : 0;
          this.data['3']['uni2000'] = data.data['8'] ? data.data['8']['uni2000'] : 0;
          this.data['3']['uni3000'] = data.data['8'] ? data.data['8']['uni3000'] : 0;
          this.data['3']['uni4000'] = data.data['8'] ? data.data['8']['uni4000'] : 0;
          this.data['3']['uni1000'] = data.data['8'] ? data.data['8']['uni1000'] : 0;

          // Matrimonio joven
          this.data['4']['basico'] = data.data['7'] ? data.data['7']['basico'] : 0;
          this.data['4']['integral'] = data.data['7'] ? data.data['7']['integral'] : 0;
          this.data['4']['premium'] = data.data['7'] ? data.data['7']['premium'] : 0;
          this.data['4']['uni2000'] = data.data['7'] ? data.data['7']['uni2000'] : 0;
          this.data['4']['uni3000'] = data.data['7'] ? data.data['7']['uni3000'] : 0;
          this.data['4']['uni4000'] = data.data['7'] ? data.data['7']['uni4000'] : 0;
          this.data['4']['uni1000'] = data.data['7'] ? data.data['7']['uni1000'] : 0;

          // Matrimonio adulto
          this.data['5']['basico'] = data.data['6'] ? data.data['6']['basico'] : 0;
          this.data['5']['integral'] = data.data['6'] ? data.data['6']['integral'] : 0;
          this.data['5']['premium'] = data.data['6'] ? data.data['6']['premium'] : 0;
          this.data['5']['uni2000'] = data.data['6'] ? data.data['6']['uni2000'] : 0;
          this.data['5']['uni3000'] = data.data['6'] ? data.data['6']['uni3000'] : 0;
          this.data['5']['uni4000'] = data.data['6'] ? data.data['6']['uni4000'] : 0;
          this.data['5']['uni1000'] = data.data['6'] ? data.data['6']['uni1000'] : 0;

          // Un hijo adicional
          this.data['6']['basico'] = data.data['1'] ? data.data['1']['basico'] : 0;
          this.data['6']['integral'] = data.data['1'] ? data.data['1']['integral'] : 0;
          this.data['6']['premium'] = data.data['1'] ? data.data['1']['premium'] : 0;
          this.data['6']['uni2000'] = data.data['1'] ? data.data['1']['uni2000'] : 0;
          this.data['6']['uni3000'] = data.data['1'] ? data.data['1']['uni3000'] : 0;
          this.data['6']['uni4000'] = data.data['1'] ? data.data['1']['uni4000'] : 0;
          this.data['6']['uni1000'] = data.data['1'] ? data.data['1']['uni1000'] : 0;

          // Dos hijos adicionales
          this.data['7']['basico'] = data.data['0'] ? data.data['0']['basico'] : 0;
          this.data['7']['integral'] = data.data['0'] ? data.data['0']['integral'] : 0;
          this.data['7']['premium'] = data.data['0'] ? data.data['0']['premium'] : 0;
          this.data['7']['uni2000'] = data.data['0'] ? data.data['0']['uni2000'] : 0;
          this.data['7']['uni3000'] = data.data['0'] ? data.data['0']['uni3000'] : 0;
          this.data['7']['uni4000'] = data.data['0'] ? data.data['0']['uni4000'] : 0;
          this.data['7']['uni1000'] = data.data['0'] ? data.data['0']['uni1000'] : 0;

          // Más de tres hijos adicionales
          this.data['8']['basico'] = data.data['5'] ? data.data['5']['basico'] : 0;
          this.data['8']['integral'] = data.data['5'] ? data.data['5']['integral'] : 0;
          this.data['8']['premium'] = data.data['5'] ? data.data['5']['premium'] : 0;
          this.data['8']['uni2000'] = data.data['5'] ? data.data['5']['uni2000'] : 0;
          this.data['8']['uni3000'] = data.data['5'] ? data.data['5']['uni3000'] : 0;
          this.data['8']['uni4000'] = data.data['5'] ? data.data['5']['uni4000'] : 0;
          this.data['8']['uni1000'] = data.data['5'] ? data.data['5']['uni1000'] : 0;

          // Mayor de 65
          this.data['9']['basico'] = data.data['9'] ? data.data['9']['basico'] : 0;
          this.data['9']['integral'] = data.data['9'] ? data.data['9']['integral'] : 0;
          this.data['9']['premium'] = data.data['9'] ? data.data['9']['premium'] : 0;
          this.data['9']['uni2000'] = data.data['9'] ? data.data['9']['uni2000'] : 0;
          this.data['9']['uni3000'] = data.data['9'] ? data.data['9']['uni3000'] : 0;
          this.data['9']['uni4000'] = data.data['9'] ? data.data['9']['uni4000'] : 0;
          this.data['9']['uni1000'] = data.data['9'] ? data.data['9']['uni1000'] : 0;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    submitForm() {
      this.loading = true;
      const dataToSend = this.data.map((item) => {
        return {
          family_group: item.family_group,
          basico: item.basico,
          integral: item.integral,
          premium: item.premium,
          uni1000: item.uni1000,
          uni2000: item.uni2000,
          uni3000: item.uni3000,
          uni4000: item.uni4000,
        };
      });
      const data = {
        zone_id: this.zone,
        tipo_afiliado: this.tipo_afiliado,
        month: this.selectedMonth,
        year: this.selectedYear,
        data: dataToSend,
      };
      axios
        .post(process.env.VUE_APP_ROOT_API+"/api/v1/plan/create", data)
        .then((response) => {
          console.log(response.data);
          this.loading = false;
          alertSuccess("Registro existoso!");
          // Aquí puedes redirigir al usuario a una página de confirmación o hacer otra acción
        })
        .catch((error) => {
          alertFailed("Algo salio mal!");
          console.error(error);
          // Aquí puedes mostrar un mensaje de error al usuario
        });
    },
  },

  mounted() {
    const currentDate = new Date();
    this.selectedYear = currentDate.getFullYear();
    this.selectedMonth = currentDate.getMonth() + 1;
    this.getData();
  },
};
</script>

<style>
#label {
  padding-top: 2rem;
}
.custom-hr {
    border: none;
    background-color: #00758f; /* Celeste */
    height: 2px;
}
</style>
