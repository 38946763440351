<template>
  <qr-code :text="value" :size="size" error-level="L"></qr-code>
</template>
<script>
// import QrcodeVue from 'qrcode.vue'
export default {
  name: "qr",
  props: {
    value: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 90,
    },
  },
};
</script>
