<template>
    <div id="new-ticket-page">
        <TicketForm/>
    </div>
</template>

<script>
    import TicketForm from '@/components/Ticket-form.vue';
    export default {
        name:'NewTicket',
        components:{
            TicketForm
        }
    }
</script>

