

<template>
    <router-link :to="link">
        <div class="card custom-border">
            <div class="card-content">
           
                <div class="level is-mobile">
                    <div class="level-left">
                        <div class="level-item">
                            <div>
                                   <h5 class="title is-6 is-spaced has-text-primary is-uppercase" style="max-width: 260px; overflow: hidden; text-overflow: ellipsis;">
                                    {{ title}}
                                </h5>
                             <h5 class="subtitle is-6" style="max-width: 260px; overflow: hidden; text-overflow: ellipsis;">{{ description}}│ {{phone}}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item has-text-centered">
                    
                           <div class="ml-5">
                                <h4 class="title is-5 has-text-weight-bold has-text-dark is-uppercase">#{{ id }}</h4>
                                <b-tag style="background-color: gray; color: white"
                                    class="is-uppercase has-text-weight-bold"
                                    >
                                    {{ status }}
                                </b-tag>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           

        
        </div>
    </router-link>
</template>


<script>    
    import {fillString} from "@/helpers";
    export default {
        name:'InvoiceCard',
         props:{
            id:{
                type: Number,
                default: 1
            },
            link:{
                type: String,
                default: '/prestadores'
            },
            title:{
                type: String,
                default: 'Nombre Afiliado'
            },
            description:{
                type: String,
                default: 'Descripción'
            },
            status:{
                type: String,
                default: 'No atendida'
            },

             phone:{
                type: String,
                default: 'No atendida'
            },
          
       
        },
        methods:{
            saleId(value){
                return fillString(value, 4);
            }
        }
    }
</script>

<style lang="scss" scoped>
    a{
        &:hover{
            .card{
                button{
                    &.delete{
                        opacity: 1;
                    }
                }
            }
        }
        .card{
            button{
                border-radius: 50%!important;
                &.delete{
                    opacity: 0;
                    position: absolute;
                    top: -.5rem;
                    right: -.5rem;
                    transition: .2;
                }
            }
   
        }
     
    }
    .custom-border {
    border: 2px solid gray; 
    margin-bottom: 15px;
}
        
</style>