import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth: false,
    session: {},
    toggle: false,
    notifications: {
      active: false,
      unread: false,
      read: false
    },
    timeline:{
      active: false
    },
    newSale: false,
    editSale: false,
  },
  mutations: {
    setAuth: (state, payload) => {
      state.auth = payload;
    },
    setSession: (state, payload) => {
      state.session = payload;
    },
    setToggle: (state, payload) => {
      state.toggle = payload;
    },
    setPlaces: (state, payload) => {
      state.places = payload;
    },
    setNewSale: (state, payload) => {
      state.newSale = payload;
    },
    setEditSale: (state, payload) => {
      state.editSale = payload;
    },
    setNotifications: (state, payload) => {
      state.notifications.active = payload;
    },
    setUnreadNotifications: (state, payload) => {
      state.notifications.unread = payload;
    },
    setReadNotifications: (state, payload) => {
      state.notifications.read = payload;
    },
    setTimeLine: (state, payload) => {
      state.timeline.active = payload;
    },
    setPdf: (state, payload) => {
      state.pdf.form = payload;
    }
  },
  getters: {
    getAuth: (state) => {
      return state.auth;
    },
    getSession: (state) => {
      return state.session;
    },
    getToogle: (state) => {
      return state.toggle;
    },
    getPlaces: (state) => {
      return state.places;
    },
    getNewSale: (state) => {
      return state.newSale;
    },
    getEditSale: (state) => {
      return state.editSale;
    },
    getNotifications: (state) => {
      return state.notifications.active;
    },
    getUnreadNotifications: (state) => {
      return state.notifications.unread
    },
    getReadNotifications: (state) => {
      return state.notifications.read
    },
    getTimeLine: (state) => {
      return state.timeline.active;
    },
    getPdf: (state) => {
      return state.pdf.form;
    }
  },
  actions: {
    setToggle({ commit }, payload) {
      commit('setToggle', payload);
    },
    setNewSale({ commit }, payload){
      commit('setNewSale', payload);
    },
    setEditSale({ commit }, payload){
      commit('setEditSale', payload);
    },
    setNotifications({ commit }, payload){
      commit('setNotifications', payload);
    },
    setTimeLine({ commit }, payload){
      commit('setTimeLine', payload);
    }
  },
  modules: {},
  plugins: [createPersistedState()]
});
