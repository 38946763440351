<template>
    <div id="contact-form">
        <Loader :active="loading"></Loader>
        <div class="columns">
            <div class="column is-6">
                <div class="level is-mobile">
                    <div class="level-left">
                        <div class="level-item">
                            <div>
                                <h1 class="title has-text-primary">Editar Contacto</h1>
                                <nav class="breadcrumb" aria-label="breadcrumbs">
                                    <ul>
                                        <li>
                                            <router-link :to="'/contactos'">Contactos</router-link>
                                        </li>
                                        <li class="is-active">
                                            <a href="#" aria-current="page">Editar Contacto</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column is-6">   
                <b-tabs>
                    <b-tab-item label="Información">
                        <div class="form-content">
                            <form @submit.prevent="update">
                                <b-field
                                    label="Procedencia">
                                    <b-input 
                                        v-model="origin"
                                        placeholder="Procedencia"
                                        disabled
                                    ></b-input>
                                </b-field>
                                <b-field
                                    label="Nombre y Apellido*">
                                    <b-input 
                                        v-model="fullname"
                                        placeholder="Nombre"
                                        required
                                    ></b-input>
                                </b-field>
                                <b-field
                                    label="Email*">
                                    <b-input 
                                        v-model="email"
                                        type="email"
                                        placeholder="Email"
                                        required
                                    ></b-input>
                                </b-field>
                                <b-field
                                    label="Teléfono*">
                                    <b-input 
                                        v-model="phone"
                                        type="tel"
                                        placeholder="Teléfono"
                                        required
                                    ></b-input>
                                </b-field>
                                <b-field
                                    label="Provincia*">
                                    <b-select 
                                        @input="getCities()"
                                        v-model="province"
                                        placeholder="Seleccionar"
                                        expanded
                                        required>
                                        <option
                                            v-for="(province, index) in provinces.data"
                                            :value="province"
                                            :key="index">
                                            {{ province }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field
                                    label="Ciudad*">
                                    <b-select 
                                        v-model="city"
                                        placeholder="Seleccionar"
                                        expanded
                                        required
                                        :disabled="cities.disabled"
                                        :loading="cities.loading">
                                        <option
                                            v-for="(city, index) in cities.data"
                                            :value="city"
                                            :key="index">
                                            {{ city }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field
                                    label="Dirección*">
                                    <b-input 
                                        v-model="address"
                                        placeholder="Dirección"
                                        required
                                    ></b-input>
                                </b-field>
                                <b-field
                                    label="Cuil*">
                                    <b-input 
                                        v-model="cuil"
                                        placeholder="Cuil"
                                        maxlength="11"
                                        required
                                    ></b-input>
                                </b-field>
                                <div v-if="origin == 'sorteo'">
                                    <b-field
                                        :label="`Cuenta de ${(social_account == 'Instagram') ? 'Instagram' : 'Facebook'}`">
                                        <b-input 
                                            placeholder=""
                                            v-model="username"
                                            type="text"
                                            icon-pack="fab"
                                             disabled
                                           >
                                        </b-input>
                                    </b-field>

                                       <b-field
                                    label="Cobertura Medica">
                                    <b-input 
                                        v-model="coverage"
                                        placeholder="Covertura "
                                      disabled
                                    ></b-input>
                                     </b-field>


                                   <!-- <label class="label">Acompañantes</label>
                                    <div class="columns"
                                        v-for="(contact, index) in extra_contacts"
                                        :key="index">
                                        <div class="column">
                                            <b-field
                                                label="">
                                                <b-input 
                                                    v-model="contact.fullname"
                                                    placeholder="Nombre y Apellido"
                                                    required
                                                ></b-input>
                                            </b-field>
                                        </div>
                                        <div class="column">
                                            <b-field
                                                label="">
                                                <b-input 
                                                    v-model="contact.email"
                                                    placeholder="Email"
                                                    required
                                                ></b-input>
                                            </b-field>
                                        </div>
                                    </div>-->
                                </div> 
                                <hr>
                                <div class="buttons"> 
                                    <!--
                                    <b-button
                                        @click="destroy"
                                        type="is-ghost">
                                        Eliminar
                                    </b-button>
                                    -->
                                    <b-button
                                        native-type="submit" 
                                        type="is-primary" 
                                        expanded>
                                        Guardar
                                    </b-button>
                                </div>
                            </form>
                        </div>
                    </b-tab-item>
                    <b-tab-item label="Consultas" v-if="this.$hasPermission('contactos.verconsultas')">
                        <ul class="menu-list">
                            <li class="is-flex is-align-items-center is-justify-content-space-between"  
                                v-for="(item, index) in tickets.data" :key="index">
                                <p>
                                    <strong>#{{ item.id }}</strong>
                                </p>
                                <p>
                                    {{ item.departament }}
                                </p>
                                <p>
                                    {{ item.date }}
                                </p>
                                <b-button
                                    type="is-primary"
                                    tag="router-link"
                                    :to="`/consultas/id/${item.id}`" 
                                    size="is-small"
                                    icon-pack="fas"
                                    icon-left="external-link-alt">
                                </b-button>
                            </li>
                        </ul>                            
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from './Loader.vue';
    import {alertSuccess, alertFailed, alertInfo, provinces, cities} from "@/helpers";
    export default {
        name:'AgentForm',
        components:{
            Loader,
        },
        data(){
            return{
                id:'',
                origin: '',
                fullname: '',
                email: '',
                phone: '',
                city: '',
                province: '',
                address: '',
                message: '',
                cuil: '',
                account: '',
                username: '',
                coverage: '',
                social_account: '',
                extra_contacts: [],
                loading: false,
                provinces:{
                    disabled: false,
                    data: []
                },
                cities:{
                    disabled: false,
                    laoding: false,
                    data: []
                },
                tickets:{
                    data:[]
                }
            }
        },
        methods:{
            async fetchContact(){
                try {
                    this.loading = true;
                    const id = this.$route.params.id;
                    const query = await this.$repositories.contacts.show(id);
                    const item = query.data.contact;
                    console.log(item)
                    this.id = item.id;
                    this.origin = item.origin,
                    this.fullname = item.fullname;
                    this.email = item.email;
                    this.phone = item.phone;
                    this.province = item.province;
                    this.city = item.city;
                    this.address = item.address;
                    this.cuil = item.cuil;
                    this.account = item.account;
                    this.social_account = item.social_account;

                    this.username = item.username;
                    this.coverage = item.coverage;
                    
                    this.extra_contacts = (item.extra_contacts) ? JSON.parse(item.extra_contacts) : '';
                    this.loading = false;
                    this.getProvinces();
                    this.getCities();

                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            },
            async fetchTickets(){
                try {
                    const query = await this.$repositories.contacts.tickets(this.id);
                    query.data.tickets.forEach(item => {
                        const ITEMS = {
                            id: item.id,
                            departament: item.departament,
                            date: item.date
                        }
                        this.tickets.data.push(ITEMS);
                    });
                } catch (error) {
                    console.log(error);
                }
            },
            async update(){
                try {
                    this.loading = true;
                    const id = this.id;
                    const data = new FormData();
                    data.append('_method', 'put');
                    data.append('fullname', this.fullname);
                    data.append('email', this.email);                  
                    data.append('phone', this.phone); 
                    data.append('province', this.province);
                    data.append('city', this.city);                 
                    data.append('address', this.address);                  
                    data.append('cuil', this.cuil);
                    data.append('social_account', this.social_account);  
                    data.append('extra_contacts', JSON.stringify(this.extra_contacts));                               
                    await this.$repositories.contacts.update(id, data);
                    alertSuccess('Registro existoso!');
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    alertFailed('Ha ocurrido un error!');
                }
            },
            async destroy(){
                try {
                    this.loading = true;
                    const id = this.id;
                    await this.$repositories.departaments.delete(id);
                    alertInfo('Se ha eliminado correctamente!');
                    this.$router.push('/departamentos');
                } catch (error) {
                    this.loading = false;
                    alertFailed('Ha ocurrido un error!');
                }
            },
            actionForm(){
                (this.$route.params.id) ? this.update : this.create;
            },
            getProvinces(){
                this.provinces.data = provinces();
            },
            getCities(){
                this.cities.data = cities(this.province);
                this.cities.disabled = false;
            },
        },
        async created(){
            await this.fetchContact();
            this.fetchTickets();
        }
    }
</script>

<style lang="scss">
    #contact-form{
        height: 100vh;
        padding-top: 1.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        overflow-y: auto;
        form{
            input, select, textarea{
                border: none;
                &[disabled]{
                    border: 1px solid #b5b5b5;
                }
            }
            input, select, textarea{
                &.is-danger{
                    border: 1px solid $danger;
                }
            }
        }
        .menu-list{
            li{
                padding: 1rem 0;
                border-bottom: 1px solid $grey-lighter;
                p{
                    margin-bottom: 0;
                }
                a{
                    padding-bottom: calc(0.5em - 1px)!important;
                    padding-left: 1em!important;
                    padding-right: 1em!important;
                    padding-top: calc(0.5em - 1px)!important;
                }
                &:first-child{
                    padding-top: 0;
                }
            }
        }
        hr{
            background-color: #e8e8e8!important;
        }
    }
</style>