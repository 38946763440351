export default (axios, baseURL) => {
    return{
    
        update(data){
            return axios.post(`${baseURL}/api/roles/updatepermissions`,
                data
            );
        },

        permissionsByDepartament(id){
            return axios.get(`${baseURL}/api/roles/getPermissionsByDepartament/${id}`);
        },
       
    }
}