<template>
    <div id="tickets-page">
        <div class="columns">
            <div class="column">
                <Home/>
            </div>
           
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import Home from '@/components/Home.vue';

    export default {
        name: 'TicketsPage',
        components:{
            Home,
         
        },
        methods:{
            ...mapActions(['setToggle'])
        },
        watch:{
            mapToggle(){
                if(this.toggle == false)
                    this.$route.go(-1);
            }
        },
        computed:{
            ...mapState(['toggle'])
        }
        
    }
</script>

<style lang="scss" scoped>
    #tickets-page{
        .columns{
            .column{
                &:last-child{
                    position: relative;
                    background-color: $white;
                    border-left: 6px solid $primary;
                    transition: all 400ms ease;
                    &:hover button{
                        opacity: 1!important;
                    }
                }
                button{
                    border-radius: 50%!important;
                    position: absolute;
                    left: -18px;
                    top: 40px;
                    opacity: 0;
                }
            }
        }
    }
</style>
