
export default (axios, baseURL) => {
    return {
        index(search, province, plan){
            return axios.get(`${baseURL}/api/v1/prestador/index?search=${search}&province=${province}&plan=${plan}`);
        },

        invoiceList(departament,search, desde, hasta, state){
            return axios.get(`${baseURL}/api/v1/prestador/invoiceList?departament=${departament}&search=${search}&desde=${desde}&hasta=${hasta}&state=${state}`);
        },

        invoiceListHome(){
            return axios.get(`${baseURL}/api/v1/prestador/invoiceListHome`);
        },

        specialities(){
            return axios.get(`${baseURL}/api/v1/prestador/specialities`);
        },

        plans(){
            return axios.get(`${baseURL}/api/v1/prestador/plans`);
        },
        update(id, data){
            return axios.post(`${baseURL}/api/v1/prestador/update/${id}`,
                data
            );
        },

        changeState(id, data){
            return axios.post(`${baseURL}/api/v1/prestador/change_state/${id}`,
                data
            );
        },
        create(){
            return axios.post(`${baseURL}/api/v1/prestador/create`);
        },
      
    }
}
