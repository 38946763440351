<template>
  <div id="tickets">
    <Loader :active="loading" :full="false"></Loader>

         <section>
          
            <b-notification
              v-model="isActive"
              aria-close-label="Close notification"
            >
              ⚠️ PTSSS! ¡Atención! A partir de ahora ya no será más obligatorio indicar el email cuando se genere un nuevo contacto, ya sea en el módulo ventas como en el módulo consultas. A partir de ahora, el campo obligatorio será el CELULAR. ☝️ Recordá siempre solicitarlo.
            </b-notification>
          </section>
    <div class="columns">
      <div class="column">
        <div class="level is-mobile">
  
          <div class="level-left">
            <h1 class="title has-text-primary">
              Bienvenido, {{ this.$store.getters["getSession"]["name"] }}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <!-- Columna para tickets  -->

      <div
        class="column"
        style="overflow-y: auto; max-height: 400px; max-width: 450px"
        v-if="!this.$store.getters['getSession']['departament_marketer']"
      >
        <h2 class="title is-4">ÚLTIMAS CONSULTAS ASIGNADAS</h2>

        <div v-if="Ticket.length > 0">
          <div v-for="ticket in Ticket" :key="ticket.id">
            <TicketCard
              :id="ticket.id"
              :link="`/consultas/id/${ticket.id}`"
              :title="ticket.contact.fullname"
              :description="ticket.departament.name"
              :status="ticket.status"
            />
          </div>
          <router-link
            to="/consultas"
            class="has-text-primary"
            style="display: block; text-align: center; font-size: 1.5rem"
          >
            Ver Todas
          </router-link>
        </div>
        <div v-else><h5>No hay consultas asignadas</h5></div>
      </div>

      <!-- Columna para ventas -->
      <div
        class="column"
        style="overflow-y: auto; max-height: 400px; max-width: 450px"
        v-if="
          [1, 2, 6].includes(
            this.$store.getters['getSession']['departament_id']
          ) || this.$store.getters['getSession']['departament_marketer']
        "
      >
        <h2 class="title is-4">ÚLTIMAS VENTAS ASIGNADAS</h2>
        <div v-if="Sales.length > 0">
          <div v-for="sale in Sales" :key="sale.id">
            <SaleCard
              :sale="{
                id: sale.id,
                color: sale.color,
                status: sale.status,
                contact_fullname: sale.contact.fullname,
                ticket_id: sale.ticket_id,
                province: sale.contact.province,
                phone: sale.contact.celphone,
              }"
              :link="`/ventas/id/${sale.id}`"
            />
          </div>
          <router-link
            to="/ventas"
            class="has-text-primary"
            style="display: block; text-align: center; font-size: 1.5rem"
          >
            Ver Todas
          </router-link>
        </div>
        <div v-else><h5>No hay ventas asignadas</h5></div>
      </div>

      <!-- Columna para prestaciones -->
      <div
        class="column"
        style="overflow-y: auto; max-height: 400px; max-width: 450px"
        v-if="
          [6, 2, 16].includes(
            this.$store.getters['getSession']['departament_id']
          )
        "
      >
        <h2 class="title is-4">ÚLTIMAS PRESTACIONES ASIGNADAS</h2>
        <div v-for="invoice in Invoices" :key="invoice.id">
          <InvoiceCard
            :id="invoice.id"
            :link="`/prestadores`"
            :title="invoice.provider.comercial_name"
            :description="invoice.provider.province"
            :status="invoice.state"
            :phone="invoice.provider.phone"
          />
        </div>
        <router-link
          to="/prestadores"
          class="has-text-primary"
          style="display: block; text-align: center; font-size: 1.5rem"
        >
          Ver Todas
        </router-link>
      </div>
    </div>
    <br />
    <div class="columns">
      <div class="column is-fullwidth">
        <h2 class="title is-4">ACCESOS DIRECTOS</h2>
        <div class="button-container">
          <a
            href="https://prestadores.osfatun.com.ar/"
            class="button"
            target="_blank"
            >CARTILLA DE PRESTADORES</a
          >
          <a
            href="https://cotizador.osfatun.com.ar/cuotas-y-minimos"
            class="button"
            target="_blank"
            >CUOTAS Y MÍNIMOS</a
          >
          <a
            href="https://www.osfatun.com.ar/datos-y-unidades"
            class="button"
            target="_blank"
            >DATOS Y UNIDADES</a
          >
          <a
            href="https://www.osfatun.com.ar/vademecum"
            class="button"
            target="_blank"
            >VADEMÉCUM</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "./Loader.vue";
import Search from "./Search.vue";
import TicketCard from "./Ticket-card-home.vue";
import SaleCard from "./Sale-card-home.vue";
import InvoiceCard from "./Invoice-card-home.vue";

const COLOR = {
  low: "success",
  medium: "warning",
  high: "danger",
};
const STATUS = {
  "not attended": "No atendido",
  open: "Abierto",
  closed: "Cerrado",
  "in process": "En proceso",
};
const ORIGIN = {
  whatsapp: {
    type: "is-success",
    pack: "fab",
    icon: "whatsapp",
    name: "Whatsapp",
  },
  messenger: {
    type: "is-info",
    pack: "fab",
    icon: "facebook-messenger",
    name: "Messenger",
  },
  instagram: {
    type: "is-light",
    pack: "fab",
    icon: "instagram",
    name: "Instagram",
  },
  web: {
    type: "is-light",
    pack: "fas",
    icon: "globe",
    name: "Web",
  },
  crm: {
    type: "is-light",
    pack: "fas",
    icon: "globe",
    name: "Crm",
  },
  email: {
    type: "is-light",
    pack: "far",
    icon: "envelope",
    name: "Email",
  },
  delegacion: {
    type: "is-light",
    pack: "fas",
    icon: "globe",
    name: "Delegación",
  },
  "call center": {
    type: "is-light",
    pack: "fas",
    icon: "globe",
    name: "Call Center",
  },
  referido: {
    type: "is-light",
    pack: "fas",
    icon: "globe",
    name: "Referido",
  },
};
export default {
  name: "Tickets",
  components: {
    Loader,
    Search,
    TicketCard,
    SaleCard,
    InvoiceCard,
  },
  data() {
    return {
      loading: false,
      is_marketer: localStorage.getItem("name"),
      isActive: true,
      Ticket: [],
      Sales: [],
      Invoices: [],
      filter: {
        search: "",
        activeTab: "Todos",
        departament: "",
      },
      tickets: {
        data: [],
        total: 0,
        order: "desc",
        page: 1,
        lastPage: "",
        perPage: 10,
      },
    };
  },
  methods: {
    async fetchTickets() {
  this.loading = true;
  try {
    const query = await this.$repositories.tickets.indexHome();

    if (query.data.ticket.length > 0) {
      this.Ticket = query.data.ticket;
      this.Ticket.forEach((ticket) => {
        ticket.status = STATUS[ticket.status.status];
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    this.loading = false;
  }
},

async fetchSales() {
  this.loading = true;
  try {
    const query = await this.$repositories.sales.indexhome();
    this.Sales = query.data.sales;
    this.Sales.forEach((sale) => {
      sale.status = STATUS[sale.status];
    });
  } catch (error) {
    console.log(error);
  } finally {
    this.loading = false;
  }
},

async fetchInvoices() {
  this.loading = true;
  try {
    const query = await this.$repositories.providers.invoiceListHome();
    this.Invoices = query.data;
  } catch (error) {
    console.log(error);
  } finally {
    this.loading = false;
  }
},

  },
  computed: {
    ...mapState(["toggle"]),
  },
  created() {
    this.fetchInvoices();
    this.fetchSales();
    this.fetchTickets();
    this.$EventBus.$on("updatedTicket", async () => {
      this.tickets.data = [];
      await this.fetchTickets();
    });
  },
  mounted() {},
  destroy() {
    this.$EventBus.$off("updatedTicket");
  },
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  //justify-content: space-between;
}

.button {
  color: #00758f; /* Color de texto celeste */
  border: 1px solid #ffc107; /* Borde amarillo */
  padding: 5px 10px; /* Espaciado interno para los botones */
  margin: 0 5px; /* Margen entre los botones */
  text-decoration: none; /* Eliminar subrayado enlaces */
}

.button-link:hover {
  background-color: #ffc107; /* Color de fondo amarillo al pasar el mouse */
  color: #00758f; /* Color de texto celeste al pasar el mouse */
}
#tickets {
  position: relative;
  height: 100vh;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  center {
    text-transform: uppercase;
    font-weight: 500;
    color: $grey-light;
  }
  .columns:last-child {
    height: calc(100vh - 240px);
    overflow-y: auto;
  }
  .customSelect {
    .select,
    select {
      width: 100%;
      display: block;
    }
  }
  .column h2.title.is-4 {
    color: grey; /* Color gris claro */
    font-size: 20px;
  }
}
</style>
