export default (axios, baseURL) => {
    return{
        //
        index(page, search){
            return axios.get(`${baseURL}/api/delegations?search=${search}&page=${page}`)
        },
        list(){
            return axios.get(`${baseURL}/api/delegations`)
        },
        show(id){
            return axios.get(`${baseURL}/api/delegations/${id}`)
        },
        types(){
            return axios.get(`${baseURL}/api/delegations/types`)
        },
        create(data){
            return axios.post(`${baseURL}/api/delegations`, 
                data
            );
        },
        update(id, data){
            return axios.post(`${baseURL}/api/delegations/${id}`,
                data
            );
        },
        delete(id){
            return axios.delete(`${baseURL}/api/delegations/${id}`);
        },
    }
}